import React from 'react';
import {MainSection} from "./atoms";
import {MainLink} from "../../ui/atoms";
import {
    AwardSvg,
    BookmarkSvg,
    Edit2Svg,
    GridSvg,
    InboxSvg,
    OrganizationSvg,
    PeopleSvg,
    SectorsSvg
} from "../../media";
import {useStore} from "effector-react";
import {$appSettings} from "../../models/app-model";

export const Main = () => {
    const {$menuVisible} = useStore($appSettings)
    return (
          <MainSection visible={$menuVisible}>
              <MainLink to='/users'> <PeopleSvg/> Пользователи</MainLink>
              <MainLink to='/organization'> <OrganizationSvg/> Организации</MainLink>
              <MainLink to='/sections'> <SectorsSvg/> Направления</MainLink>
              <MainLink to='/categories'><GridSvg/>Категории</MainLink>
              <MainLink exact to='/logs'><Edit2Svg/>Журнал действии</MainLink>
              <MainLink to='/positions'><AwardSvg/> Должности</MainLink>
              <MainLink to='/request'><InboxSvg/> Запросы</MainLink>
              <MainLink to='/tariff'><BookmarkSvg/> Тарифы</MainLink>
          </MainSection>
    )
};
