import styled from "styled-components";
import { Button } from "antd";

export const CustomList = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #222b45;
  height: 50px;
  border-bottom: 1px solid #edf1f7;
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  padding: 0 18px 0 14px;
  transition: 0.3s;
  border-left: 4px solid transparent;
  &:hover,
  &:focus,
  &.active {
    background: #3366ff14;
    color: #3366ff;
    border-left-color: #3366ff;
  }
`;

export const CustomBtn = styled(Button)`
  width: 50%;
  height: 50px !important;
  line-height: 24px !important;
  border: none !important;
  box-shadow: none !important;
  outline: none;
  &:hover,
  &:focus,
  &.active {
    box-shadow: none !important;
    border: none !important;
  }
`;

export const MainContainer = styled.div`
  border: 1px solid #EDF1F7;
  border-radius: 5px;
  background: white;
  min-height: 100%;
`;

export const ButtonContainer = styled.div`
  padding: 10px;
  border-bottom: 1px solid lightblue;
`;
