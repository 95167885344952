import {createEvent} from "effector";
import {
    fetchSearchSectors,
    fetchSubSectorsList,
    fetchSearchUser,
    getOrgSectorsList,
    getOrgUsersList,
    fetchSectorInfo
} from "./effects";


export const orgSectorsMounted = createEvent();
export const orgUsersMounted = createEvent();
export const getSearchUsers = createEvent();
export const getSearchSector = createEvent();
export const subSectorListMount = createEvent();
export const sectorInfoMounted = createEvent();


orgSectorsMounted.watch(getOrgSectorsList);
orgUsersMounted.watch(getOrgUsersList);
getSearchUsers.watch(fetchSearchUser);
getSearchSector.watch(fetchSearchSectors);
subSectorListMount.watch(fetchSubSectorsList);
sectorInfoMounted.watch(fetchSectorInfo);
