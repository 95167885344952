import React from 'react'
import { AntTable, Container } from "../../../ui/atoms";
import { Link } from "react-router-dom";
import { PopoverItem } from "../../sectors/molecules";

export const StationList = () => {

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Диаметр станции',
            dataIndex: 'length',
            key: 'length',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Точка начало',
            dataIndex: 'qwerty',
            key: 'qwerty',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Точка конца',
            dataIndex: 'startPoint',
            key: 'startPoint',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: id => (<PopoverItem updateList={updateList} id={id} />)
        }
    ];

    const updateList = () => {

    }
    return (
        <Container>
            <AntTable
                columns={columns}
            />
        </Container>
    )
}