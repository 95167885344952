import React, {useEffect, useState} from 'react';
import {GridItem, Button, ButtonCenter, GridBasic, FormSection, Container} from "../../../ui/atoms";
import {InputSystem} from "../../../ui/molecules";
import {createOrganisms, getOrganizationInfo} from "../api";
import {Notification} from "../../../components/notification";
import {SelectSystem} from "../../../ui/molecules/select";
import {getSearchUsers, $isDataPending} from "../../oragnization-info/model";
import {useStore} from "effector-react";
import {withRouter} from "react-router-dom";
import {Checkbox, notification} from "antd";


const OrganizationFormView = (props) => {
    const {
        history,
        match
    } = props;
    const isDataPending = useStore($isDataPending);

    //status
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [searchUser, setSearchUser] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [extNumber, setExtNumber] = useState(false)


    //data
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneBoss, setPhoneBoss] = useState('');
    const [region, setRegion] = useState('');
    const [address, setAddress] = useState('');
    const [rju, setRju] = useState('');
    const [persone, setPersone] = useState('');


    useEffect(() => {
        if (searchUser && searchUser.length > 3) {
            const params = {
                "search.value": searchUser
            };
            getSearchUsers(params);
        }
    }, [searchUser]);

    useEffect(() => {
        if (match.params.id) {
            getOrganizationInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        setName(data.name);
                        setRegion(data.city);
                        setAddress(data.address);
                        setPersone(data.superior);
                        setRju(data.railRoad);
                        setPhone(data.receptionPhone);
                        setPhoneBoss(data.superiorPhone);

                        if (data.receptionPhone[0] !== "+") {
                            setExtNumber(true)
                        }
                    }
                })
                .catch(error => {
                    console.error(error.response)
                });
        }
    }, [match]);

    const createSector = () => {
        const data = {
            address: address,
            city: region,
            name: name,
            railRoad: rju,
            superior: persone,
            receptionPhone: phone,
            superiorPhone: phoneBoss,
            id: match.params.id
        };
        setLoading(true)
        createOrganisms(data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    setName('');
                    setAddress('');
                    setRegion('');
                    setRju('');
                    setPhone('');
                    setPhoneBoss('');
                    setPersone('');

                    notification['success']({
                        message: 'Успешно!',
                        description: `Организация создана.`,
                    });

                    if (match.params.id) {
                        history.push('/organization')
                    }
                }
            })
            .catch(error => {
                setLoading(false)
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка при создании, попробуйте позже.',
                });
            })
    };

    const changePhoneType = (e) => {
        setExtNumber(!extNumber)
    }

    return (
        <Container>
            <FormSection>
                <GridBasic perColumn={2} columnGap={24} rowGap={40}>
                    <GridItem gridColumn='1/3'>
                        <InputSystem
                            value={name}
                            change={setName}
                            type='text'
                            placeholder='Введите название организация'
                            label='Название'
                        />
                    </GridItem>
                    <GridItem span='50%'>
                        <InputSystem
                            value={phone}
                            change={setPhone}
                            type={extNumber ? 'number' : 'phone'}
                            placeholder={extNumber ? '00000' : '+998 (--) --- -- --'}
                            label='номер телефона приемной'
                        />
                        <div style={{marginTop: 10}}>
                            <Checkbox checked={extNumber} onChange={changePhoneType}>Особый номер</Checkbox>
                        </div>

                    </GridItem>
                    <GridItem span='50%'>
                        <InputSystem
                            value={phoneBoss}
                            change={setPhoneBoss}
                            type='phone'
                            placeholder='+998 (--) --- -- --'
                            label='номер телефона начальника'
                        />
                    </GridItem>
                    <GridItem span='50%'>
                        <InputSystem
                            value={rju}
                            change={setRju}
                            type='text'
                            placeholder='Введите название РЖУ'
                            label='РЖУ'
                        />
                    </GridItem>
                    <GridItem span='50%'>
                        <SelectSystem
                            showSearch={true}
                            change={setPersone}
                            type='text'
                            placeholder='Напишите имя или фамилию'
                            label='Ответственное лицо'
                            onSearch={setSearchUser}
                            disabled={loading}
                            value={persone}
                            options={isDataPending.$searchUsers.data}
                            loading={isDataPending.$searchUsers.loading}
                        />
                    </GridItem>
                    <GridItem span='50%'>
                        <InputSystem
                            value={region}
                            change={setRegion}
                            type='text'
                            placeholder='Выберите город'
                            label='Город'
                        />
                    </GridItem>
                    <GridItem span='50%'>
                        <InputSystem
                            value={address}
                            change={setAddress}
                            type='text'
                            placeholder='Введите адресс организации'
                            label='Адрес'
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button onClick={() => history.push('/organization')} type='link'>Отмена</Button>
                    <Button
                        disabled={
                            !name
                            || !phone
                            || !phoneBoss
                            || !region
                            || !rju
                            || !persone
                        }
                        onClick={() => createSector()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>

        </Container>)
};

export const OrganizationForm = withRouter(OrganizationFormView);
