import styled from 'styled-components';
import Popover from "react-tiny-popover";

export const PopConfirmSection = styled.div`
  display: inline-flex;
  position: relative;
  button{
    background: none;
    border: none;
    outline: none;
  }
`;


export const PopConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #fff;
  box-shadow: 0 8px 16px rgba(8, 35, 48, 0.2);
  border-radius: 8px;
  svg{
    margin-right: 5px;
  }
  span{
    display: flex;
    height: 48px;
    align-items: center;
    padding: 14px;
    min-width: 200px;
    border-radius: 8px;
    transition: .3s;
    
    &.delete {
    color: red;
        &:hover, &:focus{
          cursor: pointer;
          background: red;
          color: #ffffff;
        }
    }
    
    &:nth-child(3){
        color: red;
        &:hover, &:focus{
          cursor: pointer;
          background: red;
          color: #ffffff;
        }  
    }
    &:hover, &:focus{
      cursor: pointer;
      //background: #3366FF;
      color: #3366FF;
    }
  }
`;
