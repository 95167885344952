import { combine, createStore } from "effector";
import {
    fetchSearchUser,
    getOrgSectorsList,
    fetchSearchSectors,
    fetchSubSectorsList,
    getOrgUsersList, fetchSectorInfo,
} from "./effects";
import {lengthFormatter, numberFormat} from "../../../helpers";

export const $orgSectorsList = createStore({ loading: false, data: [], error: false })
    .on(getOrgSectorsList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(getOrgSectorsList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.map(item => ({
                    key: item.id,
                    id: item.sector.id,
                    name: item.sector.name,
                    length: item.sector.length,
                    point: item.sector.startPoint,
                })),
                filtered: response.result.data.map(item => ({
                    label: item.sector.name,
                    value: item.sector.id
                })),
                error: false
            };
        }
    });

export const $orgUsersList = createStore({ loading: false, data: [], error: false })
    .on(getOrgUsersList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(getOrgUsersList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.map(item => ({
                    key: item.id,
                    id: item.id,
                    organizationId: item.organizationId,
                    name: `${item.user.name} ${item.user.sname}`,
                    sector: item.permissions.length? item.permissions.map(item => item.sector ? item.sector : '') : null,
                    subSector: item.permissions.length ? item.permissions.map(item => item.subSector ? item.subSector.name : '') : null,
                    allowedStart: item.permissions.length ? item.permissions.map(item => item.allowedStart ? lengthFormatter(item.allowedStart) : '') : null,
                    allowedEnd: item.permissions.length ? item.permissions.map(item => item.allowedEnd ? lengthFormatter(item.allowedEnd) : '') : null
                })),
                error: false
            };
        }
    });


export const $searchUsers = createStore({ loading: false, data: [], error: false })
    .on(fetchSearchUser.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSearchUser.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.data.map(item => ({
                    value: `${item.username}`,
                    label: item.name + ' ' + item.sname
                })),
                error: false
            };
        }
    });

export const $searchSectorsList = createStore({ loading: false, data: [], error: false })
    .on(fetchSearchSectors.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSearchSectors.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.data.map(item => ({ value: `${item.id}`, label: item.name })),
                error: false
            };
        }
    });

export const $subSectorsList = createStore({ loading: false, data: [], error: false })
    .on(fetchSubSectorsList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSubSectorsList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.data.map(item => ({ value: item.id, label: item.name })),
                error: false
            };
        }
    });

export const $sectorInfo = createStore({ loading: false, data: {}, range: null, error: false })
    .on(fetchSectorInfo.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchSectorInfo.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                error: response.error.response,
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                range: {
                    startPoint: `${numberFormat(response.result.data.startPoint * 1000)} м`,
                    endPoint: `${numberFormat(response.result.data.startPoint * 1000 + response.result.data.length)} м`
                },
                error: false
            }
        }
    })


export const $isDataPending = combine({
    $orgSectorsList,
    $searchUsers,
    $searchSectorsList,
    $subSectorsList,
    $orgUsersList,
    $sectorInfo
});
