import React, { useEffect, useState } from 'react';
import { AntTable, Container } from "../../../ui/atoms";
import { useStore } from "effector-react";
import { $isDataPending, subCategoriesPageMounted } from "../model";
import { Link, withRouter } from "react-router-dom";
import { PopoverItem } from "../molecules";

const CategoriesSubListView = (props) => {
    const {
        match
    } = props;
    const isDataPending = useStore($isDataPending);
    const [data, setData] = useState([]);
    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);

    function changePagination(page) {
        setPage(page);
        setStart(((page - 1) * pageSize) - (page === 1 ? 0 : 1))
    }
    function changeShowSize(current, size) {
        setPageSize(size)
    }

    function typeCategories(type) {
        if (type === 'TEXT') {
            return 'Текст'
        } else if (type === 'LONG') {
            return 'Цифры'
        } else if (type === 'DOUBLE') {
            return 'Дробные'
        } else if (type === 'LIST') {
            return 'Список'
        } else if (type === 'DATE') {
            return 'Дата'
        } else if (type === 'FILE') {
            return 'Файл'
        } else {
            return 'Не установлен'
        }
    }


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: id => (<Link to={`/categories/${match.params.id}/${id}/edit`}>{id}</Link>)
        },
        {
            title: 'Название',
            dataIndex: 'name',
            render: props => (<Link to={`/categories/${match.params.id}/${props.id}/edit`}>{props.name}</Link>)
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            render: props => (<Link to={`/categories/${match.params.id}/${props.id}/edit`}>{typeCategories(props.name)}</Link>)
        },
        {
            title: 'Описание',
            dataIndex: 'comment',
            render: (props, data) => (<Link to={`/categories/${match.params.id}/${props.id}/edit`}>{props.name}</Link>)
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: id => (<PopoverItem updateList={updateList} id={id} />)
        }
    ];



    useEffect(() => {
        if (isDataPending.$subCategories) {
            const data = [];
            for (let i = 0; i < isDataPending.$subCategories.length; i++) {
                data.push({
                    id: isDataPending.$subCategories[i].id,
                    type: { name: isDataPending.$subCategories[i].type, id: isDataPending.$subCategories[i].id },
                    comment: { name: isDataPending.$subCategories[i].description, id: isDataPending.$subCategories[i].id },
                    key: isDataPending.$subCategories[i].id,
                    popover: isDataPending.$subCategories[i].id,
                    name: { name: isDataPending.$subCategories[i].name, id: isDataPending.$subCategories[i].id }
                })
            }
            setData(data);
        }

    }, [isDataPending]);

    useEffect(() => {
        const params = {
            length: pageSize,
            start: start
        };
        subCategoriesPageMounted(match.params.id, params);
    }, [match.params.id, start, pageSize]);

    const updateList = () => {
        const params = {
            length: pageSize,
            start: start
        };
        subCategoriesPageMounted(match.params.id, params);
    }

    return (
        <Container>
            <AntTable
                columns={columns}
                dataSource={data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: isDataPending.$subCategories.recordsTotal,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true
                    }
                }
            />
        </Container>
    )
};

export const CategoriesSubList = withRouter(CategoriesSubListView);
