import {httpGet, httpPost} from "../api";

export const getSignedUserInfo = () => httpGet({
    url: '/user/'
});

export const updateMe = (data) => httpPost({
    url: '/user/me', data
})

export const updatePassword = (data) => httpPost({
    url: '/user/password', data
})

export const getAllOrgUserList = (params) => httpGet({
    url: '/user/list', params
});
