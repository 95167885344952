import React from 'react'
import { LogFilterLink } from "../atoms";
import { BriefCaseSvg, GridSvg, LayerSvg, PeopleSvg, SectorsSvg } from "../../../media";
import { LogsConstant } from "../helper";
import { GridColumn } from '../../../ui/atoms/index'

export const LogsFilter = () => {

    return (
        <GridColumn column={5} gap={25}>
            <LogFilterLink
                to='/logs'
                isActive={(match, location) => location.search === ''}
            >
                <LayerSvg /> Все</LogFilterLink>
            <LogFilterLink
                to={{
                    pathname: '/logs',
                    search: LogsConstant.ORGANIZATION
                }}
                isActive={(match, location) => location.search.slice(1) === LogsConstant.ORGANIZATION}
            >
                <BriefCaseSvg /> организации
              </LogFilterLink>
            <LogFilterLink
                to={{
                    pathname: '/logs',
                    search: LogsConstant.SECTOR
                }}
                isActive={(match, location) => location.search.slice(1) === LogsConstant.SECTOR}
            >
                <SectorsSvg /> направлении
              </LogFilterLink>
            <LogFilterLink
                to={{
                    pathname: '/logs',
                    search: LogsConstant.CATEGORY
                }}
                isActive={(match, location) => location.search.slice(1) === LogsConstant.CATEGORY}
            >
                <GridSvg /> категории
              </LogFilterLink>
            <LogFilterLink
                to={{
                    pathname: '/logs',
                    search: LogsConstant.USER
                }}
                isActive={(match, location) => location.search.slice(1) === LogsConstant.USER}
            >
                <PeopleSvg /> пользователи
              </LogFilterLink>
        </GridColumn>
    )
}
