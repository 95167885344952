import {httpGet, httpPut} from "../../api";


export const createApi = (id, data) => httpPut({
    url: `/attribute/${id}`, data
});
export const getSubCategory = (id, data) => httpGet({
    url: `/attribute/${id}`
});
export const getListCategoriesApi = (id) => httpGet({
    url: `/list/list/${id}`
});
