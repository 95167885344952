import {combine, createStore} from 'effector';
import {fetchUsers, fetchUserInfo} from './effects';
import Cookies from 'js-cookie';
import {
    userListSettingsChange,
    userPageSizeSettingsChange
} from "./events";

let storage_name = 'settings';
let storage = Cookies.get(storage_name);

export const $users = createStore({loading: false, data: [], error: false})
      .on(fetchUsers.pending, (params, pending) => {
          return {
              ...params,
              loading: pending
          };
      })
      .on(fetchUsers.finally, (params, response) => {
          if (response.error) {
              return {
                  ...params,
                  data: [],
                  error: response.error.response
              };
          } else {
              return {
                  ...params,
                  data: response.result.data,
                  error: false
              };
          }
      });

export const $userInfo = createStore({loading: false, data: [], error: false})
      .on(fetchUserInfo.pending, (params, pending) => {
          return {
              ...params,
              loading: pending
          };
      })
      .on(fetchUserInfo.finally, (params, response) => {
          if (response.error) {
              return {
                  ...params,
                  data: [],
                  error: response.error.response
              };
          } else {
              return {
                  ...params,
                  data: response.result.data,
                  error: false
              };
          }
      });

let storageData = storage !== undefined && JSON.parse(storage)
let userSettings = storageData.user !== undefined ? {...storageData} : {...storageData, user: {}}

export const $userSettings = createStore(storage !== undefined ? {...storageData, ...userSettings} : {user: {}})
      .on(userListSettingsChange, (params, payload) => {
          const {user} = params
          const newSettings = {...user, ...payload}
          return {
              user: newSettings
          }
      })
      .on(userPageSizeSettingsChange, (params, payload) => {
          const {user} = params
          const newSettings = {...user, ...payload}
          return {
              ...params,
              user: newSettings
          }
      })


$userSettings.watch((state) => {
    Cookies.set(storage_name, JSON.stringify(state))
})

export const $isDataPending = combine({
    $users,
    $userInfo,
    $userSettings
});
