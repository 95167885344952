import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {withRouter} from "react-router-dom";
import {GridItem, Button, ButtonCenter, FormSection, GridBasic, Container, ErrorText} from "../../../ui/atoms";
import {SelectSystem} from "../../../ui/molecules/select";
import {HintText, AddVariant, VariantTitle} from "../../categories-list-add/atoms";
import {InputSystem} from "../../../ui/molecules";
import {addUserForOrg, getListSubSector, getSectorInfo, getOrgUser, updateUserForOrg} from "../api";
import {notification} from "antd";
import {
    $isDataPending,
    getSearchUsers,
    orgSectorsMounted,
} from "../model";
import {useStore} from "effector-react";
import {numberFormat} from "../../../helpers";
import {PlusSvg} from "../../../media";


const AddUserOrganizationView = (props) => {
    const {
        match,
        history
    } = props;
    const isDataPending = useStore($isDataPending);


    //status
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [subSectorLoading, setSubSectorLoading] = useState(false)
    const [sectorPointLoading, setSectorPointLoading] = useState(false)
    const [subSectorPointLoading, setSubSectorPointLoading] = useState(false)
    const [errorLoading, setErrorLoading] = useState(false)


    //data
    const [name, setName] = useState(undefined);

    //data search
    const [searchUser, setSearchUser] = useState(undefined);
    const [variants, setVariants] = useState([
        {
            sector: undefined,
            subSector: undefined,
            start: '',
            end: '',
            errors: {
                sector: undefined,
                start: undefined,
                end: undefined
            },
            subSectorData: [],
            sectorInfo: undefined,
            subSectorInfo: undefined,
            helper: undefined,
            value: 0,
        },
    ]);

    const getList = useCallback((value, index, type, list) => {
        const $data = variants
        if ($data[index][type] !== value) {
            setSubSectorLoading(true)
            const data = {
                id: value,
                params: {
                    length: 40,
                    start: 0
                }
            }
            getListSubSector(data)
                .then(res => {

                    if (res.status === 200) {
                        const result = res.data.data
                        $data[index][list] = result
                        setVariants($data)
                        setSubSectorLoading(false)
                    }
                }).catch(err => {
                setSubSectorLoading(false)
                console.log(err.response);
            })
        }

    }, [variants])

    useEffect(() => {
        if (match.params.permissionId === undefined) {
            setLoading(false)
        }
    }, [match.params.permissionId])

    const getLength = (data, start) => {
        if (start) {
            return data.startPoint * 1000 + data.countdownPoint
        } else {
            return data.startPoint * 1000 + data.countdownPoint + data.length
        }
    }

    const getInfo = useCallback((value, index, type, helper) => {
        const $data = variants

        if ($data[index][type] !== value) {
            setSectorPointLoading(true)
            setSubSectorPointLoading(true)
            getSectorInfo(value)
                .then(res => {
                    const result = res.data
                    $data[index]['start'] = ''
                    $data[index]['end'] = ''
                    $data[index][`${type}Info`] = result
                    $data[index][helper] = result
                    setVariants($data)
                    setSectorPointLoading(false)
                    setSubSectorPointLoading(false)
                }).catch(err => {
                console.log(err.response);
            })
        }


    }, [variants])

    const validate = useCallback(() => {
        const requiredText = 'Поле обязательно для заполнения'
        let allow = true
        const $data = variants
        let interval = null
        setErrorLoading(true)
        for (let i = 0; i < $data.length; i++) {
            if ($data[i]['sector'] === undefined) {
                $data[i]["errors"]["sector"] = requiredText
                allow = false
            } else {
                if ($data[i]["errors"]["sector"] !== undefined) {
                    $data[i]["errors"]["sector"] = undefined
                    allow = true
                }
            }

            if ($data[i]["start"].toString().length === 0) {
                $data[i]["errors"]["start"] = requiredText
                allow = false
            } else {
                if ($data[i]["errors"]["start"] !== undefined) {
                    $data[i]["errors"]["start"] = undefined
                    allow = true
                }
            }

            if ($data[i]['end'].toString().length === 0) {
                $data[i]["errors"]["end"] = requiredText
                allow = false
            } else {
                if ($data[i]["errors"]["end"] !== undefined) {
                    $data[i]["errors"]["end"] = undefined
                    allow = true
                }
            }

            if ($data[i]["helper"] !== undefined) {
                if ($data[i]['start'].length > 0 && (Number($data[i]['start']) < getLength($data[i]["helper"], true)
                    || Number($data[i]['start']) > getLength($data[i]["helper"], false))) {
                    $data[i]["errors"]['start'] = `Введенное значение не должно быть ниже ${numberFormat(getLength($data[i]["helper"], true))} м и выше ${numberFormat(getLength($data[i]["helper"]))} м`
                    allow = false
                }
                if ($data[i]['end'].length > 0 && (Number($data[i]['end']) < getLength($data[i]["helper"], true)
                    || Number($data[i]['end']) > getLength($data[i]["helper"], false))) {
                    $data[i]["errors"]['end'] = `Введенное значение не должно быть ниже ${numberFormat(getLength($data[i]["helper"], true))} м и выше ${numberFormat(getLength($data[i]["helper"]))} м`
                    allow = false
                }
            }


            if (interval) {
                clearInterval(interval)
                interval = null
            }

            if ($data.length === i + 1) {
                setVariants($data)
                interval = setTimeout(() => {
                    setErrorLoading(false)
                    clearTimeout(interval)
                }, 100)
            }
        }

        return allow
    }, [variants])


    useEffect(() => {
        if (match.params.permissionId !== undefined) {
            const id = match.params.permissionId
            if (!edit && edit !== undefined) {
                getOrgUser(id)
                    .then(res => {
                        const data = res.data
                        setName(data.user.username)
                        const newData = data.permissions.map((item, idx) => {
                            return {
                                sector: item.sector !== null ? item.sector.id : undefined,
                                subSector: item.subSector !== null ? item.subSector.id : undefined,
                                start: item.allowedStart !== null ? item.allowedStart : '',
                                end: item.allowedEnd !== null ? item.allowedEnd : '',
                                errors: {
                                    sector: undefined,
                                    start: undefined,
                                    end: undefined
                                },
                                subSectorData: [],
                                sectorInfo: undefined,
                                subSectorInfo: undefined,
                                helper: undefined,
                                value: idx
                            }
                        })
                        setVariants(newData)
                        setEdit(undefined)
                    })
                    .catch(err => {
                        setSubSectorLoading(false)
                        console.log(err)
                    })
            }
        }
    }, [match.params.permissionId, edit])

    useEffect(() => {
        if (match.params.permissionId !== undefined) {
            if (edit === undefined) {
                const $data = variants
                setLoading(true)
                setSubSectorLoading(true)
                setSectorPointLoading(true)
                // eslint-disable-next-line array-callback-return
                variants.map((item, index, arr) => {
                    if (item.sector !== undefined) {
                        const data = {
                            id: item.sector,
                            params: {
                                length: 40,
                                start: 0
                            }
                        }
                        getListSubSector(data)
                            .then(res => {
                                $data[index]['subSectorData'] = res.data.data

                                if (arr.length === index + 1) {
                                    setLoading(false)
                                    setVariants($data)
                                    setSubSectorLoading(false)
                                }
                            }).catch(err => {
                            setLoading(false)
                            setSubSectorLoading(false)
                            console.log(err.response)
                        })

                        getSectorInfo(item.sector)
                            .then(res => {
                                $data[index]['sectorInfo'] = res.data
                                if (item.subSector === undefined) {
                                    $data[index]['helper'] = res.data
                                }
                                setVariants($data)
                                setTimeout(() => {
                                    setSectorPointLoading(false)
                                }, 300)
                            }).catch(err => {
                            console.log(err);
                            setSectorPointLoading(false)
                        })
                    }

                    if (item.subSector !== undefined) {
                        getSectorInfo(item.subSector)
                            .then(res => {
                                $data[index]['subSectorInfo'] = res.data
                                $data[index]['helper'] = res.data

                                if (arr.length === index + 1) {
                                    setVariants($data)
                                    setSubSectorLoading(false)
                                }
                            }).catch(err => {
                            console.log(err);
                            setSubSectorLoading(false)
                        })
                    } else {
                        setSubSectorLoading(false)
                    }

                })
                setEdit(true)
            }
        }

    }, [edit, match.params.permissionId, variants])


    useEffect(() => {
        if (searchUser && searchUser.length > 3) {
            const params = {
                "search.value": searchUser
            };
            getSearchUsers(params);
        }
    }, [searchUser]);


    useEffect(() => {
        orgSectorsMounted(match.params.id)
    }, [match.params.id])

    console.log(variants);

    const addChange = () => {
        console.log(validate());
        if (validate()) {
            const data = variants.map((item) => ({
                allowedEnd: Number(item.end),
                allowedStart: Number(item.start),
                sector: item.sectorInfo,
                subSector: item.subSectorInfo
            }))

            const payload = {
                id: Number(match.params.id),
                organizationId: Number(match.params.id),
                permissions: data,
                user: {
                    username: name
                },
            }
            setLoading(true);
            setSectorPointLoading(true)
            setSubSectorLoading(true)
            if (match.params.permissionId) {
                payload['id'] = Number(match.params.permissionId)
            }

            console.log(payload);
            addUserForOrg(payload)
                .then(response => {
                    if (response.status === 200) {
                        setName(undefined)
                        setVariants([{
                            sector: undefined,
                            subSector: undefined,
                            start: undefined,
                            end: undefined,
                            errors: {
                                sector: undefined,
                                start: undefined,
                                end: undefined
                            },
                            subSectorData: [],
                            sectorInfo: undefined,
                            subSectorInfo: undefined,
                            helper: undefined,
                            value: 0,
                        }])

                        notification['success']({
                            message: 'Успешно',
                            description: 'Специалист прикреплен'
                        });
                        setLoading(false);
                        setSectorPointLoading(false)
                        setSubSectorLoading(false)
                        if (match.params.permissionId) {
                            history.push(`/organization/${match.params.id}/user`)
                        }
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setSectorPointLoading(false)
                    setSubSectorLoading(false)
                    setSubSectorLoading(false)
                    console.error(error.response);
                    notification['error']({
                        message: 'Ошибка',
                        description: 'Ошибка, попробуйте позже'
                    });
                })
        }
    };

    const ChangeVariant = useCallback((value, index, type) => {
        let $data = variants;
        if (type === 'sector') {
            $data[index]['subSector'] = undefined
            getList(value, index, type, 'subSectorData')
        }

        if (type === 'subSector' || type === 'sector') {
            getInfo(value, index, type, 'helper')
            $data[index][type] = value;
        }


        $data[index][type] = value;
        setVariants($data)
    }, [variants, getInfo, getList])


    const addItemVariant = () => {

        const data = {
            sector: undefined,
            subSector: undefined,
            start: '',
            end: '',
            errors: {
                sector: undefined,
                start: undefined,
                end: undefined
            },
            subSectorData: [],
            sectorInfo: undefined,
            subSectorInfo: undefined,
            helper: undefined,
            value: variants.length
        };
        setVariants([...variants, data]);
    };


    return (
        <Container>
            <FormSection>
                <GridBasic perColumn={2} rowGap={40} columnGap={24}>
                    <GridItem gridColumn='1/3'>
                        <SelectSystem
                            showSearch={true}
                            change={setName}
                            type='text'
                            placeholder='Введите фамилию или имя'
                            label='Пользователь'
                            onSearch={setSearchUser}
                            disabled={loading}
                            value={name}
                            options={isDataPending.$searchUsers.data}
                            loading={isDataPending.$searchUsers.loading}
                        />
                    </GridItem>
                    <GridItem gridColumn='1/3'>
                        <AddVariant>
                            Добавить ещё
                            <Button noChildren={true} onClick={() => addItemVariant()}><PlusSvg/></Button>
                        </AddVariant>
                    </GridItem>
                    {!loading && variants.map((variant, i) => (
                        <Fragment key={i}>
                            <GridItem gridColumn='1/3'>
                                <VariantTitle>Административное деление № {i + 1}</VariantTitle>
                            </GridItem>
                            <GridItem>
                                <SelectSystem
                                    change={(e) => ChangeVariant(e, i, 'sector')}
                                    type='text'
                                    placeholder='Выберите направление'
                                    label='Направление'
                                    disabled={isDataPending.$searchSectorsList.loading}
                                    value={variant.sector}
                                    options={isDataPending.$orgSectorsList.filtered}
                                    loading={isDataPending.$searchSectorsList.loading}
                                />
                                {!errorLoading && variant.errors && variant.errors.sector !== undefined &&
                                <ErrorText>{variant.errors.sector}</ErrorText>}
                            </GridItem>
                            <GridItem>
                                <SelectSystem
                                    change={(e) => ChangeVariant(e, i, 'subSector')}
                                    type='text'
                                    placeholder='Выберите перегон'
                                    label='Перегон'
                                    value={variant.subSector}
                                    options={variant.subSectorData.map(item => ({
                                        value: item.id,
                                        label: item.name
                                    }))}
                                    loading={subSectorLoading}
                                />
                            </GridItem>
                            <GridItem>
                                <InputSystem
                                    value={variant.start}
                                    change={(e) => ChangeVariant(e, i, 'start')}
                                    type='permissionRange'
                                    placeholder='Разрешенный старт'
                                    label='Начало границы'
                                    disabled={sectorPointLoading || subSectorPointLoading}
                                />
                                {
                                    !sectorPointLoading && !subSectorPointLoading && variant.helper !== undefined &&
                                    <HintText>{`точка старта: ${numberFormat(variant.helper.startPoint * 1000 + variant.helper.countdownPoint)} м`}</HintText>
                                }
                                {!errorLoading && variant.errors && variant.errors.start !== undefined && (
                                    <ErrorText>{variant.errors.start}</ErrorText>)}
                            </GridItem>
                            <GridItem>
                                <InputSystem
                                    disabled={sectorPointLoading || subSectorPointLoading}
                                    value={variant.end}
                                    change={(e) => ChangeVariant(e, i, 'end')}
                                    type='permissionRange'
                                    placeholder='Разрешенный конец'
                                    label='Конец границы'
                                />
                                {
                                    !sectorPointLoading && !subSectorPointLoading && variant.helper !== undefined &&
                                    <HintText>{`точка конца: ${numberFormat(variant.helper.startPoint * 1000 + variant.helper.countdownPoint + variant.helper.length)} м`}</HintText>
                                }
                                {!errorLoading && variant.errors && variant.errors.end !== undefined &&
                                <ErrorText>{variant.errors.end}</ErrorText>}
                            </GridItem>
                        </Fragment>
                    ))}
                </GridBasic>
                <ButtonCenter>
                    <Button type='link'>Отмена</Button>
                    <Button
                        loading={loading}
                        disabled={
                            !name
                        }
                        onClick={() => addChange()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>
        </Container>
    )
};


export const AddUserOrganization = withRouter(AddUserOrganizationView);
