import React from 'react';
import {CategoriesSection} from "../../categories/atoms";
import {BodyHead} from "../../../components/body-head";
import {AddSectorOrganization} from "../organisms";
import {withRouter} from "react-router-dom";



const OrganizationSectorAddView = (props) => {
    const {
        match
    } = props;

    return (
        <CategoriesSection>
            <BodyHead back={`organization/${match.params.id}/sector`} title='Добавить участок для организации' />
            <AddSectorOrganization/>
        </CategoriesSection>
    )
};

export const OrganizationSectorAdd = withRouter(OrganizationSectorAddView);
