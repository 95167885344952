import React from "react";
import { SectorsAddSection } from "../atoms";
import { BodyHead } from "../../../components/body-head";
import { SectorsFormBlock } from "../templates";


export const SectorsAdd = () => {
    return (
        <SectorsAddSection>
            <BodyHead back='sections' title='Добавить направление' />
            <SectorsFormBlock />
        </SectorsAddSection>
    )
};