import styled from "styled-components";
import {getColor} from "../../helpers";
import {DatePicker} from "antd";

export const DatePickerInput = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  & > div{
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  
  .ant-picker {
    padding: 0 !important;
    border: 0 !important;
    box-shadow: unset !important;
  }
  
 .ant-picker input{
    border-radius: 8px;
    background: #FAFAFB;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #222B45;
    transition: .3s;
    padding: 15px ${props => props.icon ? '30px' : '10px'} 15px 10px;
    outline: none;
    width: 100%;
    border: 1px solid ${props => getColor(props.status)};
    height: 56px;
    &::placeholder{
      color: #8F9BB3;
    }
    &:hover{
      background: #EDF1F7;
      border-color: ${props => getColor(props.status)};
    }
    &:focus{
      border: 1px solid #3366FF !important;
    }
    &:disabled{
      mix-blend-mode: normal;
      opacity: 0.48;
    }
  }
  label{
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #8992A3;
    margin-bottom: 8px;
  }
`;

export const DatePickerSystem = styled(DatePicker)`
  &.ant-picker{
    height: 48px;
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    input{
        border-radius: 8px;
        background: #FAFAFB;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #222B45;
        transition: .3s;
        padding: 15px ${props => props.icon ? '30px' : '10px'} 15px 10px;
        outline: none;
        width: 100%;
        border: 1px solid ${props => getColor(props.status)};
        &::placeholder{
          color: #8F9BB3;
        }
        &:hover{
          background: #EDF1F7;
          border-color: ${props => getColor(props.status)};
        }
        &:focus{
          border: 1px solid #3366FF;
        }
        &:disabled{
          mix-blend-mode: normal;
          opacity: 0.48;
        }
    }
  }
  .ant{
    &-picker{
      &-suffix{
        display: none;
      }
    }
  }
  
`;