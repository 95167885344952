import styled from "styled-components";
import {Menu} from "antd";

export const DropdownMenu = styled(Menu)`
    
`

export const DropdownMenuItem = styled(Menu.Item)`
    font-size: 14px !important;
    font-weight: 600 !important;
    color: ${({theme}) =>  theme.main.semanticColor.basic[800]}
    
    &:hover {
        background: none !important;
        color: ${({theme}) =>  theme.main.semanticColor.primary[500]}
    }
`