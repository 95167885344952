import styled from "styled-components";

export const AvatarUploadLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  background: #FAFAFB;
  border: 1px solid #E4E9F2;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  svg{
    width: 48px;
    height: 48px;
    &:nth-child(2){
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 32px;
      height: 32px;
    }
  }
`;