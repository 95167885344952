import styled from 'styled-components';
import {Radio} from "antd";


export const RadioUI = styled(Radio)`
  &.ant{
    &-radio{
      &-check{
        
      }
      &-wrapper{
        .ant{
          &-radio{
            &-inner{
              width: 16px;
              height: 16px;
              background: ${props => props.status && props.status === 'success' ? 'rgb(0, 224, 150, 0.16)' : props.status === 'warning' ? 'rgba(255, 170, 0, 0.16)' : props.status === 'error' ? 'rgba(255, 17, 110, 0.16)' : 'rgba(143, 155, 179, 0.16)'};
              border: 1px solid ${props => props.status && props.status === 'success' ? 'rgb(0, 224, 150)' : props.status === 'warning' ? 'rgba(255, 170, 0)' : props.status === 'error' ? 'rgba(255, 17, 110)' : 'rgba(143, 155, 179)'};
              &:after{
                width: 12px;
                height: 12px;
                top: 1px;
                left: 1px;
                background: ${props => props.status && props.status === 'success' ? 'rgb(0, 224, 150)' : props.status === 'warning' ? 'rgba(255, 170, 0)' : props.status === 'error' ? 'rgba(255, 17, 110)' : 'rgba(143, 155, 179)'};
              }
            }
            &:hover{
              .ant{
                &-radio{
                  &-inner{
                    box-shadow: 0 0 0 6px rgba(143, 155, 179, 0.08);
                    background: ${props => props.status && props.status === 'success' ? 'rgb(0, 224, 150, 0.16)' : props.status === 'warning' ? 'rgba(255, 170, 0, 0.16)' : props.status === 'error' ? 'rgba(255, 17, 110, 0.16)' : 'rgba(143, 155, 179, 0.16)'};
                    border: 1px solid ${props => props.status && props.status === 'success' ? 'rgb(0, 224, 150)' : props.status === 'warning' ? 'rgba(255, 170, 0)' : props.status === 'error' ? 'rgba(255, 17, 110)' : 'rgba(143, 155, 179)'};
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;