import React, {useState} from 'react';
import Popover from "react-tiny-popover";
import {PopConfirmContent} from "../../pop-confirm/atoms";
import {LogoutSvg} from "../../../media";
import {withRouter} from "react-router-dom";
import {MenuList, ProfileMenuItem} from "../atoms";
import {USER_PROFILE} from "../../../constants/user";


const ProfilePopoverView = (props) => {
    const {
        children,
        logout,
        userProfileMenu,
        logoutSystem,
        setModalView,
        setStatusModal
    } = props;


    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const handleClick = (id, modalView) => {
        if (id === USER_PROFILE.LOGOUT) {
            logoutSystem()
        } else {
            setIsPopoverOpen(!isPopoverOpen)
            setModalView(modalView)
            setStatusModal(true)
        }
    }

    return (
        <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            position={'bottom'} // preferred position
            content={(
                <PopConfirmContent>
                    <MenuList selectable={false}>
                        {logout
                            ? <ProfileMenuItem
                                key='0'
                                onClick={() => handleClick(USER_PROFILE.LOGOUT)}
                            >
                                <LogoutSvg/>
                                выход
                            </ProfileMenuItem>
                            : userProfileMenu.map(item => (
                                <ProfileMenuItem
                                    key={item.id}
                                    onClick={() => handleClick(item.id, item.modalView)}
                                >
                                    {item.icon}
                                    {item.label}
                                </ProfileMenuItem>
                            ))
                        }
                    </MenuList>
                </PopConfirmContent>
            )}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                {children}
            </div>
        </Popover>
    )
};

export const ProfilePopover = withRouter(ProfilePopoverView);
