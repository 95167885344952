import styled from 'styled-components';
import {Button} from "../../../ui/atoms";

export const UploadFileBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${Button}{
    position: relative;
    z-index: 1;
    height: unset;
    border-radius: 8px 0 0 8px;
    padding: 0 15px;
  }
  
  label{
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #8992A3;
    margin-bottom: 8px;
  }
`;

export const UploadFileWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1; 
  padding: 8px 16px;
  border: 1px solid #E4E9F2;
  border-radius: 0 8px 8px 0;
  border-left: 0;
  background: #FAFAFB;
  font-weight: 600;
  width: calc(100% - 115px);
  
  strong{
    color: #222B45;
    font-weight: 600;
  }
  
  span:last-child{
    color: #8992A3;
  }
    
  div {
    flex-grow: 1;
    display: flex;
    height: 30px;
    align-items: center;
  }
  
  @media (max-width: 1440px) {
    padding: 5px 16px;
  }
  
  a {
    display: inline-block;
    line-height: 1;
  }
`
