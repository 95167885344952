import React, { Fragment, useState } from 'react'
import { TariffCalculateItem, TariffCalculateWrapper } from "../atoms";
import { withTheme } from "styled-components";
import { ModalTariff } from "../../../ui/molecules";
import { Button } from '../../../ui/atoms';

export const TariffCalculateView = (props) => {
    const [statusModal, setStatusModal] = useState(false)
    const { tariffFields } = props
    return (
        <Fragment>
            <ModalTariff
                title='Выберите карту'
                statusModal={statusModal}
                setStatusModal={setStatusModal}
            />
            <TariffCalculateWrapper>
                {tariffFields.map((item, idx) => {
                    return (
                        <TariffCalculateItem key={idx}>
                            <span>{item.label}:</span>
                            <span>{item.value}</span>
                        </TariffCalculateItem>
                    )
                })}
                <TariffCalculateItem>
                    <span>К оплате:</span>
                    <span>18 374 000 UZS</span>
                </TariffCalculateItem>
            </TariffCalculateWrapper>
            <div>
                <Button onClick={() => setStatusModal(true)}>
                    далее
                </Button>
            </div>

        </Fragment>
    )
}

export const TariffCalculate = withTheme(TariffCalculateView)
