import React, { useEffect, useState, Fragment } from 'react';
import { CategoriesSection } from "../positions/atoms";
import { BodyHead } from "../../components/body-head";
import { Button, GridColumn } from "../../ui/atoms";
import { PlusSvg } from "../../media";
import { withRouter } from "react-router-dom";
import { OrganizationList } from "./templates";

const OrganizationView = (props) => {
    const { history } = props;
    const [search, setSearch] = useState(null);

    useEffect(() => {
        const paramsString = history.location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('search')) {
            setSearch(searchParams.get('search'))
        } else {
            setSearch('')
        }

    }, [history.location.search])

    return (
        <Fragment>
            <BodyHead search={search} searchQuery={{ pathname: '/organization' }} setSearch={setSearch}
                title='Организации'>
                <GridColumn column={1} gap={0}>
                    <Button onClick={() => history.push('/organization/add')}><PlusSvg />Добавить</Button>
                </GridColumn>
            </BodyHead>
            <OrganizationList search={search} />
        </Fragment>
    )
};

export const Organization = withRouter(OrganizationView);
