import styled from "styled-components";
import {HeaderRight, UserName} from "../../../components/header/atoms";

export const DashboardContainer = styled.div`
  max-width: 100%;
  margin-top: 80px;
  padding: 0 14px;
  background: #fafafb;
  
  @media (max-width: 1440px) {
    margin-top: 50px;
    padding: 0 6px;
  }
`

export const DashboardHeadSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  background: #fafafb;
`;

export const JournalSection = styled.div`
  background: #ffffff;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 42px 24px 24px;
  margin-bottom: 78px;
  ${HeaderRight}{
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;
    ${UserName}{
      margin-left: 16px;
      align-items: flex-start;
    }
    & > div{
      flex-direction: row-reverse;
      display: flex;
    }
    span{
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #8F9BB3;
    }
  }
`;

export const JournalLabels = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #8F9BB3;
  margin-bottom: 16px;
  div{
    color: #222B45;
    margin-top: 10px;
  }
`;

export const SectorsBlockTitle = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #3366FF;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  width: 70px;
  svg{
    position: absolute;
    bottom: -15px;
    left: 0;
    color: #8F9BB3;
    transition: .3s;
    transform: ${props => !props.visible ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;

export const SectorsBlock = styled.div`
  display: flex;
  height: 60px;
  border: 1px solid #EDF1F7;
  border-radius: 8px;
  background: #ffffff;
  align-items: center;
  padding: 8px 24px;
  position: relative;
  margin-top: ${props => !props.visible ? '-60px' : '0'};
  transition: .3s;
`;


