import {NavLink} from 'react-router-dom';
import styled from 'styled-components';


export const LinkDashboard = styled(NavLink)`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  color: #8F9BB3;
  font-weight: bold;
  align-items: center;
  display: inline-flex;
  transition: .3s;
  height: 100%;
  svg{
    width: 24px;
    height: 24px;
  }
  &:hover, &:focus, &.active{
    color: #222B45;
    svg{
      color: #3366FF;
    }
  }
`;