import {httpGet, httpPost, httpPut} from "../../api";


export const getAttributeInf = (id) => httpGet({
    url: `/attribute/${id}`
});

export const getContextInfo = () => httpGet({
    url: '/context/'
});

export const getOrganizationList = (params) => httpGet({
    url: '/organization/list', params
});

export const getOrganizationInfo = (id) => httpGet({
    url: `/organization/${id}`
});

export const getOrganizationSectorList = (id) => httpGet({
    url: `/sector/organization/${id}`
});

export const getSubSectorList = ({id, params}) => httpGet({
    url: `/sector/list/${id}`, params
});

export const getSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});

export const getCategoriesList = (params) => httpGet({
    url: '/category/list', params
});

///////////////////////////////////////////////////

export const getAttributeCategory = (id) => httpGet({
    url: `/attribute/list/${id}`
});

export const getAttributeInfo = (id, sectorId, fromM, toM, params) => httpGet({
    url: `/entry/attribute/list/${id}/${sectorId}/${fromM}/${toM}`, params
});

export const createEntryAttributeController = (id, sectorId, data) => httpPut({
    url: `/entry/attribute/${id}/${sectorId}`, data
});

export const getConstraintsInfo = (id) => httpGet({
    url: `/list/${id}`
});

export const getEntrySector = (params) => httpGet({
    url: `/entry/sector/list/${params.sectorId}/${params.from}/${params.to}`, params
});

export const getLogList = (params) => httpGet({
    url: '/log/list', params
});


export const getSectorsList = (params) => httpGet({
    url: '/organization/list', params
});

export const getDashboardSectorList = (id) => httpGet({
    url: `/sector/organization/${id}`
});

export const getSubSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});


export const getSectorList = (params) => httpGet({
    url: `/sector/list`, params
});

export const getUserLIst = (params) => httpGet({
    url: '/user/list', params
});

export const getPoints = (data) => httpGet({
    url: `/map/${data.sw.long}/${data.sw.lat}/${data.ne.long}/${data.ne.lat}`
});

export const getOrganizationUserList = (id, params) => httpGet({
    url: `/permission/${id}`, params
});

export const uploadPhotoJournal = (id, data) => httpPost({
    url: `/log/upload/${id}`, data
});

export const uploadCommentJournal = (id, data) => httpPost({
    url: `/log/comment/${id}`, data
});
