import React, { useEffect, useState } from "react";
import { SectorsForm } from "../atoms/section";
import { Button, ButtonCenter, GridBasic, GridItem } from "../../../ui/atoms";
import { InputSystem } from "../../../ui/molecules";
import { withRouter } from "react-router-dom";
import { createPosition, getPositionInfo } from "../api";
import { notification } from "antd";


const SectorsFormBlockView = (props) => {
    const {
        history,
        match
    } = props;

    const [name, setName] = useState(undefined);

    const createSector = () => {

        const data = {
            title: name
        };
        if (match.params.id) {
            data.id = match.params.id
        }
        createPosition(data)
            .then(response => {
                if (response.status === 200) {
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Должность добавлен!'
                    });
                }
            })
            .catch(error => {
                console.error(error.response.data);
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка при удалении, попробуйте позже'
                });
            })
    };

    useEffect(() => {
        if (match.params.id) {
            getPositionInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        setName(data.title);
                    }
                })
                .catch(error => console.log(error.response))
        }
    }, [match.params.id]);

    return (
        <SectorsForm>
            <GridBasic perColumn={1} rowGap={40} columnGap={24}>
                <GridItem>
                    <InputSystem
                        value={name}
                        change={setName}
                        type='text'
                        placeholder='Введите название должности'
                        label='Название'
                    />
                </GridItem>
            </GridBasic>
            <ButtonCenter>
                <Button onClick={() => history.push('/')} type='link'>Отмена</Button>
                <Button
                    disabled={
                        !name
                    }
                    onClick={() => createSector()}>Добавить</Button>
            </ButtonCenter>
        </SectorsForm>
    )
};

export const SectorsFormBlock = withRouter(SectorsFormBlockView);
