import {useCallback, useEffect, useState} from "react";

export const useLogs = (location, arr) => {
    const [data, setData] = useState([]);
    const [type, setType] = useState('');

    const filterData = useCallback((_arr, type) => {
        if (type === '') {
            return _arr
        } else {
            return _arr.filter(item => item.type === type)
        }

    }, []);

    useEffect(() => {
        const paramsString = location.search
        const searchParams = new URLSearchParams(paramsString)
        const param = searchParams.toString().slice(0, searchParams.toString().length - 1)
        setType(param)
        setData(filterData(arr, type))
    }, [location.search, filterData, arr, type]);

    return {data, type}
};
