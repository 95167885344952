import {httpDelete, httpGet, httpPut} from "../../api";


export const getOrgSectors = (id, params) => httpGet({
    url: `/permission/sector/${id}`, params
});
export const getOrgUsers = (id, params) => httpGet({
    url: `/permission/${id}`, params
});
export const getOrganizationInfo = (id) => httpGet({
    url: `/organization/${id}`
});
export const getListSectorsPlainlist  = (params) => httpGet({
    url: '/sector/list', params
});

export const getOrgUser = (id) => httpGet({
    url: `/permission/${id}/user `
})

//add
export const addSectorForOrg = (data) => httpPut({
    url: `/permission/${data.id}/sector/${data.sectorId}`
});
export const addUserForOrg = (data) => httpPut({
    url: `/permission/${data.id}/user`, data
});

export const updateUserForOrg = (data) => httpPut({
    url: `/permission/${data.id}/user`, data
});

//remove
export const removeSectorForOrg = (id) => httpDelete({
    url: `/permission/${id}/sector`
});
export const removeUserForOrg = (id) => httpDelete({
    url: `/permission/${id}/user`
});

//list api
export const usersList = (params) => httpGet({
    url: '/user/list', params
});
export const getListSectors = (params) => httpGet({
    url: '/sector/list', params
});

export const getListSubSector = ({id, params}) => httpGet({
    url: `/sector/list/${id}`, params
});

export const getOrgSectorList = (id) =>  httpGet({
    url: `/sector/organization/${id}`
});

export const getSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});
