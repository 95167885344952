import {httpDelete, httpGet, httpPut} from "../../api";


export const getCategoriesList = (params) => httpGet({
    url: '/category/list', params
});
export const removeCategory = (id) => httpDelete({
    url: `/category/${id}`
});

//add
export const setCreateCategory = (data) => httpPut({
    url: '/category', data
});

export const getCategoryInfo = (id) => httpGet({
    url: `/category/${id}`
});

