import {SectorsAddSection} from "../../sectors-add/atoms";
import {BodyHead} from "../../../components/body-head";
import {SectorSubFormBlock} from "../../sectors-sub-add/templates";
import {withRouter} from "react-router-dom";
import React from "react";


const SectorSubAddView = (props) => {
    const {
        match
    } = props;
    return (
        <SectorsAddSection>
            <BodyHead back={`sections/${match.params.id}/sub`} title='Добавить подучасток' />
            <SectorSubFormBlock/>
        </SectorsAddSection>
    )
};

export const SectorSubAdd = withRouter(SectorSubAddView);
