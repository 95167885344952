import React from 'react'
import {BellSvg} from "../../../media";
import {Dropdown} from "antd";
import {NotificationList} from "../molecules";

export const Notification = () => {
    const handleClick = (e) => {
        e.preventDefault()
    }
    return (
          <Dropdown  overlay={<NotificationList />} trigger={['click']}>
              <a onClick={(e) => handleClick(e)}><BellSvg/></a>
          </Dropdown>
    )
}
