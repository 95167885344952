import styled from 'styled-components';

export const NotificationBlock = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  top: 10px;
  right: 15px;
  transition: .3s;
  background: #fff;
  padding: 10px 15px;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 1px 0 #EDF1F7;
  border: 1px solid #EDF1F7;
  transform: translateX(${props => props.status ? '0' : '100%'});
`;
export const NotificationTitle = styled.div`
  display: flex;
  font-size: 16px;
`;
export const NotificationDescription = styled.div`
  display: flex;
`;
