import {httpDelete, httpGet} from "../../api";


export const getSectors = (params) => httpGet({
    url: '/position/list', params
});

export const removeSection = (id) =>  httpDelete({
    url: `/position/${id}`
})
