import styled from "styled-components";
import {Dropdown} from "antd";
import {FILTER_CONSTANT} from "../hook";

export const RequestSection = styled.div`
    margin: 0 24px;
`

export const TableItem = styled.div`
     padding: 16px;
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: ${(props) => {
    if (props.type === undefined) {
        return props.theme.main.semanticColor.basic[800]
    } else {
        if (props.type === FILTER_CONSTANT.WAITING) {
            return props.theme.main.semanticColor.info[500]
        } else if (props.type === FILTER_CONSTANT.SUCCESS) {
            return props.theme.main.semanticColor.success[500]
        } else if (props.type === FILTER_CONSTANT.REJECT) {
            return props.theme.main.semanticColor.danger[500]
        }
    }
}}
`

export const SortLink = styled(Dropdown)`    
    color: ${({theme}) => theme.main.semanticColor.basic[600]}
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    
    div {
        margin-left: 5px;
        display: flex;
        align-items: center;
        color: ${({theme}) => theme.main.semanticColor.basic[800]}
        
        svg {
            margin: 0 0 0 5px
        }
    }
     svg {
        margin-right: 10px;
    }
    
`
