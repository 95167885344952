import React from 'react';
import {BodyHeadSection, BodyHeadTitle} from "./atoms";
import {ArowLeft, SearchSvg} from "../../media";
import {withRouter} from "react-router-dom";
import {InputSystem} from "../../ui/molecules";
import {useLastLocation} from 'react-router-last-location';

const BodyHeadView = (props) => {
    const {
        title,
        back,
        history,
        search,
        setSearch,
        searchQuery,
        goBack
    } = props;

    const lastLocation = useLastLocation();

    const customGoBack = () => {
        if (goBack === undefined || goBack) {
            if (lastLocation && lastLocation.pathname.indexOf(back) === 1) {
                history.goBack()
            } else {
                history.push(`/${back}`)
            }
        } else {
            history.push(`/${back}`)
        }

    }
    return (
        <BodyHeadSection>
            <BodyHeadTitle onClick={() => back ? customGoBack() : null}>
                {
                    back &&
                    <ArowLeft/>
                }
                {title}
                {
                    search !== undefined &&
                    <InputSystem
                        icon={<SearchSvg/>}
                        placeholder='Поиск...'
                        value={search}
                        change={setSearch}
                        type='search'
                        searchQuery={searchQuery}
                    />
                }
            </BodyHeadTitle>
            {props.children}
        </BodyHeadSection>
    )
};
export const BodyHead = withRouter(BodyHeadView);
