import React, { Fragment } from 'react';
import { BodyHead } from "../../../components/body-head";
import { EditUserForm } from "../organisms";
import { withRouter } from "react-router-dom";

const UsersEditView = (props) => {
    return (
        <Fragment>
            {/*<BodyHead back='users' title={`${match.params.id ? 'Редактировать' : 'Добавить'} пользователя`} />*/}
            <BodyHead back='users' title='Редактировать пользователя' />
            <EditUserForm />
        </Fragment>
    )
};

export const UsersEdit = withRouter(UsersEditView);
