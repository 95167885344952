import axios from "axios";
import Cookies from "js-cookie";
import {openNotificationWithIcon} from "./helpers";

const httpClient = axios.create({
    baseURL: 'http://185.196.214.106/api/2/',
});


httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            if (!(window.location.pathname === '/sign-in')) {
                window.location.replace('/sign-in');
            }
        }
        if (error.response.status === 403) {
            openNotificationWithIcon('warning', 'Предупреждение!', 'У этой учетной записи нет доступа к действию!')
        }
        return Promise.reject(error);
    }
);


httpClient.interceptors.request.use(config => {
    let token = Cookies.get("token");

    if (token) {
        config.headers = Object.assign(config.headers, {
            Authorization: "Bearer " + token
        });
        return config;
    } else {
        return config;
    }
});

export const httpGet = params =>
    httpClient({
        method: "get",
        ...params
    });

export const httpPost = params =>
    httpClient({
        method: "post",
        ...params
    });

export const httpPut = params =>
    httpClient({
        method: "put",
        ...params
    });

export const httpPatch = params =>
    httpClient({
        method: "patch",
        ...params
    });

export const httpDelete = params =>
    httpClient({
        method: "delete",
        ...params
    });
