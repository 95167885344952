import { createEvent } from 'effector';
import { fetchUsers, fetchUserInfo } from './effects';

export const usersPageMounted = createEvent();
export const userInfoMounted = createEvent();
export const userListSettingsChange = createEvent()
export const userPageSizeSettingsChange = createEvent()

usersPageMounted.watch(fetchUsers);
userInfoMounted.watch(fetchUserInfo);

