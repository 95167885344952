import React, {Fragment} from 'react'
import {useUserProfile} from "../../../hooks/user";
import {UserProfileModal} from "../../../ui/molecules";
import {AwardSvg, BriefCaseSvg, EditSvg, LockSvg, LogoutSvg} from "../../../media";
import {ProfilePopover} from "./popover";
import {USER_PROFILE} from "../../../constants/user";
import {ChangeOrganization, ChangePassword, ChangePosition, EditProfile} from "./modal-views";

export const ProfileMenu = ({logout, children}) => {
    const {
        modalView,
        setStatusModal,
        statusModal,
        setModalView,
        logoutSystem
    } = useUserProfile();

    const userProfileMenu = [
        {
            id: USER_PROFILE.EDIT,
            label: 'Редактировать',
            icon: <EditSvg/>,
            modalView: <EditProfile setStatusModal={setStatusModal} />
        },
        {
            id: USER_PROFILE.CHANGE_PASSWORD,
            label: 'Сменить пароль',
            icon: <LockSvg/>,
            modalView: <ChangePassword setStatusModal={setStatusModal} />
        },
        {
            id: USER_PROFILE.CHANGE_ORGANIZATION,
            label: 'Изменить организацию',
            icon: <BriefCaseSvg/>,
            modalView: <ChangeOrganization setStatusModal={setStatusModal} />
        },
        {
            id: USER_PROFILE.CHANGE_POSITION,
            label: 'Изменить должность',
            icon: <AwardSvg/>,
            modalView: <ChangePosition setStatusModal={setStatusModal} />
        },
        {
            id: USER_PROFILE.LOGOUT,
            label: 'Выход',
            icon: <LogoutSvg/>,
            modalView: logoutSystem
        }
    ]

    return (
          <Fragment>
              <UserProfileModal
                    setStatusModal={setStatusModal}
                    statusModal={statusModal}
                    component={modalView}
              />
              <ProfilePopover
                  userProfileMenu={userProfileMenu}
                  logout={logout}
                  children={children}
                  setModalView={setModalView}
                  logoutSystem={logoutSystem}
                  setStatusModal={setStatusModal}
              />
          </Fragment>
    )
}
