import { createEvent } from 'effector';
import { getSubCategories } from './effects';
import {getListCategories} from "../../categories-sub-add/model/effects";

export const subCategoriesPageMounted = createEvent();
export const listCategoriesMounted = createEvent();


listCategoriesMounted.watch(getListCategories);
subCategoriesPageMounted.watch(getSubCategories);

