import React, { useState } from 'react';
import {removeCategory} from "../api";
import Popover from "react-tiny-popover";
// import {PopConfirm} from "../../../components/pop-confirm";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {EditSvg, MoreHorizontalSvg, TrashSvg} from "../../../media";
import {withRouter} from "react-router-dom";
import {notification} from "antd";
import {deleteConfirm} from "../../../ui/molecules";


const PopoverItemView = ( props ) => {
    const {
        id,
        history,
        updateList
    } = props;

    // console.log('dddd', id)

    const [ isPopoverOpen, setIsPopoverOpen ] = useState(false);


    const removeSector = () => {
        removeCategory(id)
            .then(response => {
                if (response.status === 200){
                    updateList();
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Участок удален!'
                    });
                }
            })
            .catch(error => {
                console.error(error.response.data);
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка при удалении, попробуйте позже'
                });
            })
    };

    return (
        <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            position={'bottom'} // preferred position
            content={(
                <PopConfirmContent>
                    <span onClick={() => history.push(`/sections/${id}/edit`)}><EditSvg/> Редактировать</span>
                    <span className='delete'
                        onClick={() => deleteConfirm(removeSector)}
                    >
                        <TrashSvg/> Удалить
                    </span>
                </PopConfirmContent>
            )}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <MoreHorizontalSvg/>
            </div>
        </Popover>
    )
};

export const PopoverItem = withRouter(PopoverItemView);
