import React from 'react';
import {CategoriesSection} from "../categories/atoms";
import {BodyHead} from "../../components/body-head";
import {OrganizationForm} from "./templates";



export const OrganizationAdd = () => {
    return (
        <CategoriesSection>
            <BodyHead back='organization' title='Добавить организацию' />
            <OrganizationForm/>
        </CategoriesSection>
    )
};
