import {theme} from './theme';
import {notification} from "antd";

const color = theme.main;

export const getColor =  (type) => {
    return {
        'default': color.themeColor[400],
        'primary': color.brandColor[500],
        'success': color.semanticColor.success[500]
    }[type];
};

export const getColorType = (type) => {
    return {
        'default': color.semanticColor.info,
        'primary': color.semanticColor.primary,
        'success': color.semanticColor.success,
        'error': color.semanticColor.danger,
        'warning': color.semanticColor.warning
    }[type];
};

export const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};


export function numberFormat(number, decimals, dec_point = '.', thousands_sep = ' ') {  // Format a number with grouped thousands
    let i, j, kw, kd, km, nl, lkd;
    // input sanitation & defaults
    if (isNaN(decimals = Math.abs(decimals))) {
        decimals = 2;
    }
    if (dec_point === undefined) {
        dec_point = ".";
    }
    if (thousands_sep === undefined) {
        thousands_sep = " ";
    }
    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";
    if ((j = i.length) > 3) {
        j = j % 3;
    } else {
        j = 0;
    }
    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    //kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "");
    kd = '0' + (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");
    nl = parseFloat(kd).valueOf();
    if (nl > 0) {
        lkd = nl.toString().substr(1);
    } else {
        lkd = '';
    }

    return km + kw + lkd;
}


export const convertKilo = (e) => {
    const count = e.toString().substring(e.toString().length - 3, e.toString().length).replace(/^0+/, '');
    return count === '' ? 0 : count
};

export const lengthFormatter = (e) => {
    let start = (e - convertKilo(e))/1000,
        end =  convertKilo(e);
    return `${start} км ПК ${end}`
};

export const arrayDiff = (a1, a2, first) => {
    if (first) {
        return a1.filter(x => a2.indexOf(x) === -1);
    } else {
        return a2.filter(x => a1.indexOf(x) === -1);
    }
};

export const stringToArray = (str, splitBy) => {
    let arr = str.split(splitBy);
    return arr.map(item => Number(item))
};


export const RemoveDuplicate = (arr, key) => {
    let dup = [];
    return arr.filter(function (el) {
        // If it is not a duplicate, return true
        if (dup.indexOf(el[key]) === -1) {
            dup.push(el[key]);
            return true;
        }
        return false;
    })
};

export const convertTo = (props) => {
    // const {
    //     valueNumber,
    //     upNumber,
    //     downNumber,
    //     defaultNumber
    // } = props;

    const decimalAdjust = (type, value, exp) => {
        // Если степень не определена, либо равна нулю...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Если значение не является числом, либо степень не является целым числом...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Сдвиг разрядов
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Обратный сдвиг
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    };

    if (!Math.round10) {
        Math.round10 = function (value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }
    // Десятичное округление вниз
    if (!Math.floor10) {
        Math.floor10 = function (value, exp) {
            return decimalAdjust('floor', value, exp);
        };
    }
    // Десятичное округление вверх
    if (!Math.ceil10) {
        Math.ceil10 = function (value, exp) {
            return decimalAdjust('ceil', value, exp);
        };
    }

    return {
        Math
    }

};

export const arrSum = (arr, key = undefined) => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        if (key !== undefined) {
            sum += arr[i][key]
        } else {
            sum += arr[i]
        }
    }
    return sum
};