import React from 'react'
import {SortLink} from "../atoms";
import {ArrowBottomSvg, FunnelSvg} from "../../../media";

export const RequestFilter = ({sort, filterText}) => {
    return (
          <SortLink
                overlay={sort}
                trigger={['click']}
                placement={'bottomRight'}
                overlayStyle={{minWidth: 'unset'}}
          >
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <FunnelSvg/>
                  Сортировка:
                  <div>{filterText} <ArrowBottomSvg/></div>
              </a>
          </SortLink>
    )
}
