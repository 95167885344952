export const theme = {
    main: {
        brandColor: {
            100: '#F2F6FF',
            200: '#D9E4FF',
            300: '#A6C1FF',
            400: '#598BFF',
            500: '#3366FF',
            600: '#274BDB',
            700: '#1A34B8',
            800: '#102694',
            900: '#091C7A'
        },
        themeColor: {
            100: '#FFFFFF',
            200: '#FAFAFB',
            300: '#EDF1F7',
            400: '#E4E9F2',
            500: '#C5CEE0',
            600: '#8F9BB3',
            700: '#2E3A59',
            800: '#222B45',
            900: '#192038',
            1000: '#151A30',
            1100: '#222B45'
        },
        semanticColor: {
            success: {
                100: '#EDFFF3',
                200: '#B3FFD6',
                300: '#8CFAC7',
                400: '#51F0B0',
                500: '#00E096',
                600: '#00B383',
                700: '#008F72',
                800: '#007566',
                900: '#00524C'
            },
            info: {
                100: '#F2F8FF',
                200: '#C7E2FF',
                300: '#94CBFF',
                400: '#42AAFF',
                500: '#0095FF',
                600: '#006FD6',
                700: '#0057C2',
                800: '#0041A8',
                900: '#002885'
            },
            warning: {
                100: '#FFFDF2',
                200: '#FFF1C2',
                300: '#FFE59E',
                400: '#FFC94D',
                500: '#FFAA00',
                600: '#DB8B00',
                700: '#B86E00',
                800: '#945400',
                900: '#703C00'
            },
            danger: {
                100: '#FFF2F2',
                200: '#FFD6D9',
                300: '#FFA8B4',
                400: '#FF708D',
                500: '#FF3D71',
                600: '#DB2C66',
                700: '#B81D5B',
                800: '#94124E',
                900: '#700940'
            },
            primary: {
                100: '#F2F6FF',
                200: '#D9E4FF',
                300: '#A6C1FF',
                400: '#598BFF',
                500: '#3366FF',
                600: '#274BDB',
                700: '#1A34B8',
                800: '#102694',
                900: '#091C7A'
            },
            basic: {
                100: '#FFFFFF',
                200: '#FAFAFB',
                300: '#EDF1F7',
                400: '#E4E9F2',
                500: '#C5CEE0',
                600: '#8F9BB3',
                700: '#2E3A59',
                800: '#222B45',
                900: '#192038',
                1000: '#151A30',
                1100: '#222B45'
            },
        }
    }
};
