import React, {useEffect, useState} from 'react';
import {GetRecource} from "../api";
import ModalImage from "react-modal-image";
import {Spinner} from "../../../ui/molecules";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

export const ImageLoad = ({id, show, imageSize}) => {
    const [imgData, setImgData] = useState(false);

    useEffect(() => {
        if (id !== undefined) {
            GetRecource(id)
                .then(response => {
                        if (response.status === 200) {
                            const data = response.data;
                            const urlCreator = window.URL || window.webkitURL;
                            const imageUrl = urlCreator.createObjectURL(data);
                            setImgData(imageUrl);
                        }
                    }
                );
        }

    }, [id]);

    return imgData ?
        show ?
            <ModalImage
                small={imgData}
                large={imgData}
                alt="Avatar"
            /> :
            <img src={imgData} alt="avatar" style={imageSize ? {width: imageSize, height: imageSize} : {}}/> :
        <Spinner icon={<LoadingOutlined style={{fontSize: 24}} spin/>}/>;
};
