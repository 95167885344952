import {combine, createStore} from "effector";
import {fetchSectorsSub} from "./effects";
import {numberFormat} from '../../../helpers'
import {subSectorPageSizeSettingsChange} from './events'
import Cookies from "js-cookie";

let storage_name = 'settings';
let storage = Cookies.get(storage_name);

export const $sectorsSub = createStore({loading: false, data: [], error: false})
    .on(fetchSectorsSub.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSectorsSub.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: {
                    ...response.result.data,
                    data: response.result.data.data.map(item => ({
                        key: item.id,
                        id: item.id,
                        popover: item.id,
                        name: {name: item.name, id: item.id},
                        length: {name: numberFormat(item.length), id: item.id},
                        qwerty: {name: 'Тест', id: item.id},
                        startPoint: {
                            name: item.startPoint,
                            id: item.id
                        },
                        countdownPoint: {
                            name: item.countdownPoint,
                            id: item.id
                        },
                    }))
                },
                error: false
            };
        }
    });

let storageData = storage !== undefined && JSON.parse(storage)
let subSectorSettings = storageData.subSector !== undefined ? {...storageData} : {...storageData, subSector: {}}

const $subSectorSettings = createStore(storage !== undefined ? {...storageData, ...subSectorSettings} : {subSector: {}})
    .on(subSectorPageSizeSettingsChange, (params, payload) => {
        const {subSector} = params
        const newSettings = {...subSector, ...payload}
        return {
            subSector: newSettings
        }
    })


$subSectorSettings.watch((state) => {
    Cookies.set(storage_name, JSON.stringify(state))
})


export const $isDataPending = combine({
    $sectorsSub,
    $subSectorSettings
});
