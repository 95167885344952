import {BodyHead} from "../../../components/body-head";
import React, { Fragment } from "react";
import {CategoriesForm} from "../organisms";


export const CategoriesAdd = () => {


    return (
        <Fragment>
            <BodyHead back='categories' title='Добавить категорию' />
            <CategoriesForm/>
        </Fragment>
    )
};
