import {httpDelete, httpGet, httpPut} from "../../api";


export const getSectors = (params) => httpGet({
    url: '/sector/list', params
});
export const removeCategory = (id) => httpDelete({
    url: `/sector/${id}`
});

export const createSectordApi = (data) => httpPut({
    url: '/sector', data
});
export const getSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});
export const getSectorsList = (params) => httpGet({
    url: '/sector/list', params
});
export const getSectorListOptions = () => httpGet({
    url: '/sector/plainlist'
});

// export const getParentSector = (id) => httpGet({
//     url: `/sector/list/${id}`
// });
