import React, {useState} from 'react';
import {removeOrganization} from "../api";
import Popover from "react-tiny-popover";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {EditSvg, MoreHorizontalSvg, TrashSvg} from "../../../media";
import {withRouter} from "react-router-dom";
import {notification} from "antd";
import {deleteConfirm} from "../../../ui/molecules";


const PopoverItemView = (props) => {
    const {
        id,
        history,
        updateList
    } = props;

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);


    const deleteOrganization = () => {
        removeOrganization(id)
              .then(response => {
                  if (response.status === 200) {
                      updateList();
                      notification['success']({
                          message: 'Успешно!',
                          description: 'Организация удалена!'
                      });
                  }
              })
              .catch(error => {
                  console.error(error.response.data);
                  notification['error']({
                      message: 'Ошибка!',
                      description: 'Ошибка при удалении, попробуйте позже'
                  });
              })
    };

    return (
          <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                position={'bottom'} // preferred position
                content={(
                      <PopConfirmContent>
                          <span onClick={() => history.push(`/organization/${id}/edit`)}><EditSvg/> Редактировать</span>
                          <span className='delete'
                                onClick={() => deleteConfirm(deleteOrganization)}
                          >
                              <TrashSvg />
                        Удалить
                    </span>
                      </PopConfirmContent>
                )}
          >
              <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  <MoreHorizontalSvg/>
              </div>
          </Popover>
    )
};

export const PopoverItem = withRouter(PopoverItemView);
