import {combine, createStore} from "effector";
import {fetOrganization} from './effects';
import Cookies from "js-cookie";
import {organizationPageSizeChange} from "./event";

let storage_name = 'settings';
let storage = Cookies.get(storage_name);

export const $organization = createStore({loading: false, data: [], error: false})
      .on(fetOrganization.pending, (params, pending) => {
          return {
              ...params,
              loading: pending
          };
      })
      .on(fetOrganization.finally, (params, response) => {
          if (response.error) {
              return {
                  ...params,
                  data: [],
                  error: response.error.response
              };
          } else {
              return {
                  ...params,
                  data: response.result.data.data.map(item => ({
                      key: item.id,
                      id: item.id,
                      name: item.name,
                      phone: item.superiorPhone,
                      superior: item.superior,
                      rju: item.railRoad,
                      city: item.city,
                  })),
                  error: false
              };
          }
      });


let storageData = storage !== undefined && JSON.parse(storage)
let organizationSettings = storageData.organization !== undefined ? {...storageData} : {
    ...storageData,
    organization: {}
}

export const $organizationSettings = createStore(storage !== undefined ? {
    ...storageData,
    ...organizationSettings
} : {organization: {}})
      .on(organizationPageSizeChange, (params, payload) => {
          const {organization} = params
          const newSettings = {...organization, ...payload}
          return {
              ...params,
              organization: newSettings
          }
      })

$organizationSettings.watch((state) => {
    Cookies.set(storage_name, JSON.stringify(state))
})

export const $isDataPending = combine({
    $organization,
    $organizationSettings
});
