import { combine, createStore } from 'effector';
import { getSubCategories } from './effects';
import {getListCategories} from "../../categories-sub-add/model/effects";

export const $subCategories = createStore([])
    .on(getSubCategories.done, (_, response ) => response.result.data);

export const $listCategory = createStore([])
    .on(getListCategories.done, (_, response ) => response.result.data);

export const $isDataPending = combine({
    $subCategories,
    $listCategory
});
