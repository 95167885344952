import React, {useEffect, useState} from 'react';
import {GridItem, Button, ButtonCenter, FormSection, Container, GridBasic} from "../../../ui/atoms";
import {InputSystem} from "../../../ui/molecules";
import {UploadFile} from "../../../components/download/template";
import {createSectordApi} from "../../sectors-add/api";
import {withRouter} from "react-router-dom";
import {notification} from "antd";
import {getSectorInfo} from "../../sectors-sub/api";
import {useLastLocation} from "react-router-last-location";


const SectorSubFormGridItemView = (props) => {
    const {
        match,
        history
    } = props;

    //status
    const [loading, setLoading] = useState(false);

    //data
    const [name, setName] = useState(undefined);
    const [length, setLength] = useState(undefined);
    const [startPoint, setStartPoint] = useState(0);
    const [endPoint, setEndPoint] = useState(0);
    const [uploadFile, setUploadFile] = useState([]);
    const [parentName, setParentName] = useState(undefined);
    const [maxLengthSector, setMaxLengthSector] = useState(false);

    const lastLocation = useLastLocation();

    console.log(lastLocation);

    const createSector = () => {

        const data = {
            length: length,
            name: name,
            startPoint: startPoint,
            countdownPoint: endPoint,
            uploads: uploadFile,
            parent: {id: Number(match.params.id)}
        };

        if (match.params.subId) {
            data.id = Number(match.params.subId)
        }

        setLoading(true);
        createSectordApi(data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    notification['success']({
                        message: 'Успешно',
                        description: 'Перегон создан'
                    });
                    setEndPoint('');
                    setLength('');
                    setName('');
                    setStartPoint('');

                    if (match.params.subId) {
                        if (lastLocation) {
                            history.push({
                                pathname: lastLocation.pathname,
                                search: lastLocation.search
                            })
                        } else {
                            history.push(`/sections/${match.params.id}/sub`)
                        }
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка при создании, попробуйте позже'
                });
            })
    };

    useEffect(() => {
        if (match.params.id) {
            getSectorInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        setParentName(response.data.name);
                    }
                })
                .catch(error => console.error(error.response))
        }
    }, [match.params.id]);

    useEffect(() => {
        if (match.params.subId) {
            getSectorInfo(match.params.subId)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        setUploadFile(data.uploads.map(item => Number(item)))
                        setParentName(data.parent.name);
                        setEndPoint(data.countdownPoint);
                        setLength(data.length);
                        setName(data.name);
                        setStartPoint(data.startPoint);
                        setMaxLengthSector(data.parent.length);
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }

    }, [match.params.subId]);


    return (
        <Container>
            <FormSection>
                <GridBasic perColumn={2} columnGap={24} rowGap={40}>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={name}
                            change={setName}
                            type='text'
                            placeholder='Введите название перегона'
                            label='Название'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={loading}
                            value={length}
                            change={setLength}
                            type='text'
                            placeholder='Длина перегона'
                            label='Длина'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            disabled={true}
                            value={parentName}
                            change={setParentName}
                            type='text'
                            placeholder='введите направление'
                            label='Направление'
                        />
                    </GridItem>
                    <GridItem>
                        <UploadFile
                            disabled={loading}
                            change={setUploadFile}
                            value={uploadFile}
                            type='text'
                            placeholder='Введите широту и долготу'
                            label='Загрузка файла'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            max={maxLengthSector}
                            disabled={loading}
                            change={setStartPoint}
                            value={startPoint}
                            type='sector'
                            placeholder='Введите точку начала стыковки'
                            label='точка начала'
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            max={999}
                            disabled={loading}
                            value={endPoint}
                            change={setEndPoint}
                            type='sector'
                            placeholder='Введите точку отсчета (в метрах)'
                            label='точка отсчета'
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button onClick={() => history.push('/')} type='link'>Отмена</Button>
                    <Button
                        loading={loading}
                        disabled={
                            !name
                            || !length
                        }
                        onClick={() => createSector()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>
        </Container>
    )
};

export const SectorSubFormBlock = withRouter(SectorSubFormGridItemView);
