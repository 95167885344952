import React, { Fragment } from 'react';
import { BodyHead } from "../../components/body-head";
import { withRouter } from "react-router-dom";
import { AddSubCategory } from "./template";

const SubCategoriesAddView = (props) => {
    const {
        match
    } = props;


    return (
        <Fragment>
            <BodyHead back={`categories/${match.params.id}`} title='Добавить подкатегорию' />
            <AddSubCategory />
        </Fragment>
    )
};

export const SubCategoriesEdit = withRouter(SubCategoriesAddView);
