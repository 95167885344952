import React, {Fragment} from 'react';
import {BodyHead} from "../../../components/body-head";
import {StationForm} from "../organisms";
import {withRouter} from 'react-router-dom'

export const StationAddView = (props) => {
    const {match} = props
    return (
        <Fragment>
            <BodyHead title='Добавить Станцию' back={`sections/${match.params.id}/stations`}/>
            <StationForm/>
        </Fragment>
    )
}

export const StationAdd = withRouter(StationAddView)