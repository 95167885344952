import React, {useEffect, useState, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {BodyHeadButtons} from "../atoms";
import {BodyHead} from "../../../components/body-head";
import {Button, GridColumn} from "../../../ui/atoms";
import {GridSvg, ListSvg, PlusSvg} from "../../../media";
import {CategoriesSubList} from "../organisms";
import { Row } from 'antd';
import {getCategoriesInfo} from "../api";
import {FilterSelect} from "../../users/atoms";
import {DropdownView} from "../../../ui/molecules";

const SubCategoriesPageView = (props) => {

    const {
        history,
        match
    } = props;

    const [ categoryName, setCategoryName ] = useState(false);

    useEffect(() => {
        getCategoriesInfo(match.params.id)
            .then(response => {
                if (response.status === 200){
                    setCategoryName(response.data.name)
                }
            })
            .catch(error => {
                console.error(error.response)
            })
    }, [match.params.id]);

    const addMenuData = [
        {
            id: 'addSub',
            label: 'Подкатегорию',
            action: () => history.push(`/categories/${match.params.id}/add`)
        },
        {
            id: 'addList',
            label: 'Список',
            action: () => history.push(`/categories/${match.params.id}/list/add`)
        }
    ]

    return (
        <Fragment>
            <BodyHead back='categories' title={categoryName ? categoryName : 'Подкатегории'}>
                <GridColumn column={2} gap={40}>
                    <FilterSelect column={2} gap={40}>
                        <Button
                            onClick={() => history.push(`/categories/${match.params.id}`)}
                            type='link'
                            active='true'
                        >
                            <GridSvg/>
                            подкатегории
                        </Button>
                        <Button
                            onClick={() => history.push(`/categories/${match.params.id}/list`)}
                            type='link'
                        >
                            <ListSvg/> Список
                        </Button>
                    </FilterSelect>
                    <DropdownView menuData={addMenuData}>
                        <Button>
                            <PlusSvg/>Добавить
                        </Button>
                    </DropdownView>
                </GridColumn>
            </BodyHead>
            <CategoriesSubList/>
        </Fragment>
    )
};

export const SubCategories = withRouter(SubCategoriesPageView);
