import React, {useState} from 'react';
import {SortBlock, SortLink} from "../atoms";
import {Menu} from "antd";
import {ArrowBottomSvg, FunnelSvg} from "../../media";

export const SortSystem = (props) => {
    const {sortData, setValue, value, sortStyle, style, responsive} = props
    const [$value, $setValue] = useState({id: 'a-я', label: 'А-Я'})
    const $sortData = [
        {
            id: 'a-я',
            label: 'А-Я'
        },
        {
            id: 'я-а',
            label: 'Я-А'
        },
    ]

    const changeSort = (id, label) => {

        if (setValue) {
            setValue({...value, id, label})
        } else {
            $setValue({...$value, id, label})
        }

    }

    const sort = (
        <Menu>
            {sortData
                ? sortData.map((item, idx) => {
                    return (
                        <Menu.Item key={idx} onClick={() => changeSort(item.id, item.label)}>
                            {item.label}
                        </Menu.Item>
                    )
                })
                : $sortData.map((item, idx) => {
                    return (
                        <Menu.Item key={idx} onClick={() => changeSort(item.id, item.label)}>
                            {item.label}
                        </Menu.Item>
                    )
                })}
        </Menu>
    )

    return (
        <SortBlock style={style} responsive={responsive}>
            <SortLink
                overlay={sort}
                trigger={['click']}
                placement={'bottomRight'}
                overlayStyle={sortStyle ? sortStyle : {minWidth: 'unset'}}
            >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <FunnelSvg/>
                    <span>
                      Сортировка:
                    </span>
                    <div>{value ? value.label : $value.label} <ArrowBottomSvg/></div>
                </a>
            </SortLink>
        </SortBlock>

    )
}
