import {createEffect} from "effector";
import {
    getListSectors,
    getListSubSector,
    getOrgSectorList,
    getOrgSectors,
    getOrgUsers,
    getSectorInfo,
    usersList
} from "../api";

export const getOrgSectorsList = createEffect({
    handler: getOrgSectors
});

export const getOrgUsersList = createEffect({
    handler: getOrgUsers
});

export const fetchSearchUser = createEffect({
    handler: usersList
});

export const fetchSearchSectors = createEffect({
    handler: getListSectors
});

export const fetchSubSectorsList = createEffect({
    handler: getListSubSector
});

export const fetchSectorInfo = createEffect({
    handler: getSectorInfo
})

