import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col } from 'antd';
import { $isDataPending, userInfoMounted } from "../model";
import { BodyHead } from "../../../components/body-head";
import {
    JournalDate,
    JournalItems,
    MoreButton,
    UserInfoBlock,
    UserInfoChild,
    UserInfoImage,
    UsersSection,
    UserText
} from "../atoms";
import { PopoverItem } from "../molecules";
import { useStore } from "effector-react";
import LogImage from '../../../media/11.jpg';
import Moment from "react-moment";
import { AntTable, Container } from "../../../ui/atoms";
import { Link } from "react-router-dom";
import { ROLES_VALUE } from '../helpers'
import { ImageLoad } from "../../../components/download/template";

const UserInfoView = (props) => {
    const {
        match
    } = props;
    const isDataPending = useStore($isDataPending);

    //data
    const [fileId, setFileId] = useState(undefined);

    const columns = [
        {
            title: 'IP Адресс',
            dataIndex: 'ip',
            key: 'ip',
            // width: 70,
            render: ip => (<Link to='#'>{ip}</Link>)
        },
        {
            title: 'Время входа в систему',
            dataIndex: 'lastVisit',
            key: 'lastVisit',
            // width: 70,
            render: visit => (<Link to='#'><Moment fromNow>{visit}</Moment></Link>)
        },
    ];


    useEffect(() => {
        if (isDataPending.$userInfo.data) {
            setFileId(isDataPending.$userInfo.data.image);
        }
    }, [isDataPending.$userInfo]);

    useEffect(() => {
        userInfoMounted(match.params.id)
    }, [match.params.id]);


    const sessionData = isDataPending.$userInfo.data.sessions && isDataPending.$userInfo.data.sessions.map((item, i) => {
        return { ...item, key: (new Date().getTime() + i) }
    })

    return (
        <Container>
            <Row type='flex' gutter={24}>
                <Col span={14}>
                    <UsersSection>
                        <BodyHead back='users' title='Пользователь' />
                        <UserInfoBlock>
                            <UserInfoChild>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <UserInfoImage>
                                        <div style={{ borderRadius: '50%', overflow: 'hidden' }}>
                                            {fileId !== undefined && <ImageLoad id={fileId} show={true} />}
                                        </div>
                                    </UserInfoImage>
                                    <UserText to='#'>
                                        <div>
                                            <div>
                                                {
                                                    `${isDataPending.$userInfo.data.name} ${isDataPending.$userInfo.data.sname}`
                                                }
                                            </div>
                                            <span>Имя, фамилия</span>
                                        </div>
                                    </UserText>
                                </div>
                                <MoreButton>
                                    <PopoverItem userStatus={isDataPending.$userInfo.data.status}
                                        userInfo={isDataPending.$userInfo.data} />
                                </MoreButton>
                            </UserInfoChild>
                            <UserText to='#'>
                                <div>
                                    <div>
                                        {
                                            isDataPending.$userInfo.data.position && isDataPending.$userInfo.data.position.title
                                        }
                                    </div>
                                    <span>Должность</span>
                                </div>
                            </UserText>
                            <UserText to='#'>
                                <div>
                                    <div>
                                        {
                                            isDataPending.$userInfo.data.gender === '1' ? 'Мужской' : 'Женский'
                                        }
                                    </div>
                                    <span>Пол</span>
                                </div>
                            </UserText>
                            <UserText to='#'>
                                <div>
                                    <div>
                                        {
                                            isDataPending.$userInfo.data.username
                                        }
                                    </div>
                                    <span>Логин</span>
                                </div>
                            </UserText>
                            <UserText to='#'>
                                <div>
                                    <div>
                                        {
                                            isDataPending.$userInfo.data.roles && ROLES_VALUE[isDataPending.$userInfo.data.roles[0]]
                                        }
                                    </div>
                                    <span>Роль</span>
                                </div>
                            </UserText>
                            <UserText to='#'>
                                <div>
                                    <div>
                                        {
                                            isDataPending.$userInfo.data.address
                                        }
                                    </div>
                                    <span>Адрес</span>
                                </div>
                            </UserText>
                            <UserText to='#'>
                                <div>
                                    <div>
                                        <Moment fromNow>{isDataPending.$userInfo.data.lastVisit}</Moment>
                                    </div>
                                    <span>Последняя активность</span>
                                </div>
                            </UserText>
                        </UserInfoBlock>
                        <div style={{ marginTop: 24 }}>
                            <AntTable
                                pagination={false}
                                columns={columns}
                                dataSource={sessionData}
                            />
                        </div>
                    </UsersSection>
                </Col>
                <Col span={10}>
                    <UsersSection>
                        <BodyHead title='Журнал' />
                        <UserInfoBlock>
                            <JournalDate>12 Сентября 2019 в 14:22</JournalDate>
                            <Row>
                                <Col>
                                    <JournalItems>
                                        <span>Категория</span>
                                        <div>Путевые сигналы знаки</div>
                                    </JournalItems>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <JournalItems>
                                        <span>Категория</span>
                                        <div>Путевые сигналы знаки</div>
                                    </JournalItems>
                                </Col>
                            </Row>
                            <Row gutter={16} type='flex'>
                                <Col span={6}>
                                    <JournalItems>
                                        <span>от</span>
                                        <div>0</div>
                                    </JournalItems>
                                </Col>
                                <Col span={6}>
                                    <JournalItems>
                                        <span>до</span>
                                        <div>5</div>
                                    </JournalItems>
                                </Col>
                                <Col span={12}>
                                    <JournalItems>
                                        <span>Значение</span>
                                        <div>вцсцм</div>
                                    </JournalItems>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <JournalItems>
                                        <span>Комментрий</span>
                                        <div>Путевые сигналы знаки</div>
                                    </JournalItems>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <img style={{ marginTop: 16, display: 'flex', width: '100%' }} src={LogImage}
                                        alt='log' />
                                </Col>
                            </Row>
                        </UserInfoBlock>
                    </UsersSection>
                </Col>
            </Row>
        </Container>
    )
};

export const UserInfo = withRouter(UserInfoView);
