import React from "react";
import {Svg} from "../ui/atoms";

export const LogoSvg = () => {
    return (
        <Svg width="200" height="73" viewBox="0 0 200 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M47.6334 63.4651H62.7496L39.5481 1.31488H23.4652L0 63.4651H14.5888L19.071 50.8422H43.1513L47.6334 63.4651ZM31.2869 16.7429L38.7571 38.2193H23.641L31.2869 16.7429Z"
                fill="white"/>
            <path
                d="M124.56 63.4651V30.2423H92.8336V41.9886H111.553C110.762 45.495 106.456 52.1571 96.1732 52.1571C86.0665 52.1571 77.4538 45.4073 77.4538 32.4338C77.4538 18.5837 87.3847 12.9735 95.8217 12.9735C106.192 12.9735 110.323 19.9862 111.289 23.8432L124.648 19.1973C121.923 10.5191 113.662 0 95.8217 0C78.2447 0 63.3922 12.8859 63.3922 32.4338C63.3922 52.0694 77.6295 64.78 94.9428 64.78C103.819 64.78 109.707 61.0983 112.432 57.1536L113.311 63.4651H124.56Z"
                fill="white"/>
            <path
                d="M160.424 64.8676C174.134 64.8676 185.12 56.6277 185.12 41.0244V1.31488H171.234V39.8848C171.234 47.4235 167.016 51.1928 160.424 51.1928C153.833 51.1928 149.615 47.4235 149.615 39.8848V1.31488H135.729V41.0244C135.729 56.6277 146.714 64.8676 160.424 64.8676Z"
                fill="white"/>
            <path
                d="M200 60.3344C200 67.2505 194.379 72.8571 187.445 72.8571C180.511 72.8571 174.89 67.2505 174.89 60.3344C174.89 53.4183 180.511 47.8117 187.445 47.8117C194.379 47.8117 200 53.4183 200 60.3344Z"
                fill="#212938"/>
        </Svg>
    )
};

export const Person = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794 16 7ZM19 20C19 20.552 18.553 21 18 21H6C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12 13C15.859 13 19 16.14 19 20Z"
                  fill="#8F9BB3"/>
            <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="3" width="14" height="18">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794 16 7ZM19 20C19 20.552 18.553 21 18 21H6C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12 13C15.859 13 19 16.14 19 20Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </Svg>

    )
};

export const PasswordSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 14C11.448 14 11 14.448 11 15C11 15.552 11.448 16 12 16C12.552 16 13 15.552 13 15C13 14.448 12.552 14 12 14ZM12 18C10.346 18 9 16.654 9 15C9 13.346 10.346 12 12 12C13.654 12 15 13.346 15 15C15 16.654 13.654 18 12 18ZM10 6.111C10 4.947 10.897 4 12 4C13.103 4 14 4.947 14 6.111V8H10V6.111ZM17 8H16V6.111C16 3.845 14.206 2 12 2C9.794 2 8 3.845 8 6.111V8H7C5.346 8 4 9.346 4 11V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V11C20 9.346 18.654 8 17 8Z"
                  fill="#8F9BB3"/>
            <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="4" y="2" width="16" height="20">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 14C11.448 14 11 14.448 11 15C11 15.552 11.448 16 12 16C12.552 16 13 15.552 13 15C13 14.448 12.552 14 12 14ZM12 18C10.346 18 9 16.654 9 15C9 13.346 10.346 12 12 12C13.654 12 15 13.346 15 15C15 16.654 13.654 18 12 18ZM10 6.111C10 4.947 10.897 4 12 4C13.103 4 14 4.947 14 6.111V8H10V6.111ZM17 8H16V6.111C16 3.845 14.206 2 12 2C9.794 2 8 3.845 8 6.111V8H7C5.346 8 4 9.346 4 11V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V11C20 9.346 18.654 8 17 8Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </Svg>
    )
};

export const BlueLogoSvg = () => {
    return (
        <Svg width="82" height="40" viewBox="0 0 82 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.719 28.9061H28.7074L21.0509 8.43314H15.7435L8 28.9061H12.8143L14.2934 24.748H22.2399L23.719 28.9061ZM18.3247 13.5153L20.7898 20.5899H15.8015L18.3247 13.5153Z"
                fill="#3366FF"/>
            <path
                d="M49.1048 28.9061V17.9622H38.6351V21.8316H44.8125C44.5515 22.9866 43.1304 25.1812 39.7372 25.1812C36.4019 25.1812 33.5597 22.9577 33.5597 18.6841C33.5597 14.1217 36.837 12.2736 39.6211 12.2736C43.0434 12.2736 44.4065 14.5837 44.7255 15.8542L49.1338 14.3238C48.2347 11.4651 45.5085 8 39.6211 8C33.8208 8 28.9194 12.2448 28.9194 18.6841C28.9194 25.1523 33.6178 29.3393 39.3311 29.3393C42.2603 29.3393 44.2034 28.1265 45.1025 26.8271L45.3925 28.9061H49.1048Z"
                fill="#3366FF"/>
            <path
                d="M60.94 29.3682C65.4643 29.3682 69.0896 26.6538 69.0896 21.5139V8.43314H64.5073V21.1385C64.5073 23.6219 63.1152 24.8635 60.94 24.8635C58.7649 24.8635 57.3728 23.6219 57.3728 21.1385V8.43314H52.7905V21.5139C52.7905 26.6538 56.4157 29.3682 60.94 29.3682Z"
                fill="#3366FF"/>
            <path
                d="M74.0001 27.8749C74.0001 30.1531 72.1452 32 69.857 32C67.5688 32 65.7139 30.1531 65.7139 27.8749C65.7139 25.5966 67.5688 23.7497 69.857 23.7497C72.1452 23.7497 74.0001 25.5966 74.0001 27.8749Z"
                fill="#212938"/>
        </Svg>

    )
};

export const MainButtonSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.94824 6H20.0512C20.5732 6 21.0002 6.427 21.0002 6.949V7.051C21.0002 7.573 20.5732 8 20.0512 8H3.94824C3.42624 8 3.00024 7.573 3.00024 7.051V6.949C3.00024 6.427 3.42624 6 3.94824 6ZM20.0512 11H3.94824C3.42624 11 3.00024 11.427 3.00024 11.949V12.051C3.00024 12.573 3.42624 13 3.94824 13H20.0512C20.5732 13 21.0002 12.573 21.0002 12.051V11.949C21.0002 11.427 20.5732 11 20.0512 11ZM20.0512 16H3.94824C3.42624 16 3.00024 16.427 3.00024 16.949V17.051C3.00024 17.573 3.42624 18 3.94824 18H20.0512C20.5732 18 21.0002 17.573 21.0002 17.051V16.949C21.0002 16.427 20.5732 16 20.0512 16Z"
                  fill="#8F9BB3"/>
            <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="6" width="19" height="12">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M3.94824 6H20.0512C20.5732 6 21.0002 6.427 21.0002 6.949V7.051C21.0002 7.573 20.5732 8 20.0512 8H3.94824C3.42624 8 3.00024 7.573 3.00024 7.051V6.949C3.00024 6.427 3.42624 6 3.94824 6ZM20.0512 11H3.94824C3.42624 11 3.00024 11.427 3.00024 11.949V12.051C3.00024 12.573 3.42624 13 3.94824 13H20.0512C20.5732 13 21.0002 12.573 21.0002 12.051V11.949C21.0002 11.427 20.5732 11 20.0512 11ZM20.0512 16H3.94824C3.42624 16 3.00024 16.427 3.00024 16.949V17.051C3.00024 17.573 3.42624 18 3.94824 18H20.0512C20.5732 18 21.0002 17.573 21.0002 17.051V16.949C21.0002 16.427 20.5732 16 20.0512 16Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </Svg>
    )
};

export const SearchSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
                  fill="#8F9BB3"/>
            <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="19" height="18">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </svg>
    )
};

export const BellSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.9998 18.3413C13.9998 19.2403 13.0838 20.0003 11.9998 20.0003C10.9158 20.0003 9.99985 19.2403 9.99985 18.3413V18.0003H13.9998V18.3413ZM20.5208 15.2073L18.7198 13.4043V8.9363C18.7198 5.4553 16.2178 2.4993 12.8988 2.0593C10.9778 1.8043 9.03785 2.3903 7.58285 3.6663C6.11885 4.9483 5.27985 6.7933 5.27985 8.7273L5.27885 13.4043L3.47885 15.2083C3.00985 15.6773 2.87085 16.3773 3.12485 16.9903C3.37985 17.6033 3.97285 18.0003 4.63685 18.0003H7.99985V18.3413C7.99985 20.3593 9.79385 22.0003 11.9998 22.0003C14.2058 22.0003 15.9998 20.3593 15.9998 18.3413V18.0003H19.3618C20.0258 18.0003 20.6188 17.6043 20.8728 16.9903C21.1278 16.3773 20.9888 15.6773 20.5208 15.2073Z"
                  fill="#8F9BB3"/>
            <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="19" height="21">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.9998 18.3413C13.9998 19.2403 13.0838 20.0003 11.9998 20.0003C10.9158 20.0003 9.99985 19.2403 9.99985 18.3413V18.0003H13.9998V18.3413ZM20.5208 15.2073L18.7198 13.4043V8.9363C18.7198 5.4553 16.2178 2.4993 12.8988 2.0593C10.9778 1.8043 9.03785 2.3903 7.58285 3.6663C6.11885 4.9483 5.27985 6.7933 5.27985 8.7273L5.27885 13.4043L3.47885 15.2083C3.00985 15.6773 2.87085 16.3773 3.12485 16.9903C3.37985 17.6033 3.97285 18.0003 4.63685 18.0003H7.99985V18.3413C7.99985 20.3593 9.79385 22.0003 11.9998 22.0003C14.2058 22.0003 15.9998 20.3593 15.9998 18.3413V18.0003H19.3618C20.0258 18.0003 20.6188 17.6043 20.8728 16.9903C21.1278 16.3773 20.9888 15.6773 20.5208 15.2073Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </Svg>

    )
};

export const PlusSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M19 11H13V5C13 4.447 12.552 4 12 4C11.448 4 11 4.447 11 5V11H5C4.448 11 4 11.447 4 12C4 12.553 4.448 13 5 13H11V19C11 19.553 11.448 20 12 20C12.552 20 13 19.553 13 19V13H19C19.552 13 20 12.553 20 12C20 11.447 19.552 11 19 11Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M19 11H13V5C13 4.447 12.552 4 12 4C11.448 4 11 4.447 11 5V11H5C4.448 11 4 11.447 4 12C4 12.553 4.448 13 5 13H11V19C11 19.553 11.448 20 12 20C12.552 20 13 19.553 13 19V13H19C19.552 13 20 12.553 20 12C20 11.447 19.552 11 19 11Z"
                          fill="currentColor"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>
    )
};

export const MoreHorizontalSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="more-horizontal">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M3 12C3 10.896 3.896 10 5 10C6.104 10 7 10.896 7 12C7 13.104 6.104 14 5 14C3.896 14 3 13.104 3 12ZM12 10C10.896 10 10 10.896 10 12C10 13.104 10.896 14 12 14C13.104 14 14 13.104 14 12C14 10.896 13.104 10 12 10ZM19 10C17.896 10 17 10.896 17 12C17 13.104 17.896 14 19 14C20.104 14 21 13.104 21 12C21 10.896 20.104 10 19 10Z"
                      fill="#2E3A59"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="10" width="18" height="4">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M3 12C3 10.896 3.896 10 5 10C6.104 10 7 10.896 7 12C7 13.104 6.104 14 5 14C3.896 14 3 13.104 3 12ZM12 10C10.896 10 10 10.896 10 12C10 13.104 10.896 14 12 14C13.104 14 14 13.104 14 12C14 10.896 13.104 10 12 10ZM19 10C17.896 10 17 10.896 17 12C17 13.104 17.896 14 19 14C20.104 14 21 13.104 21 12C21 10.896 20.104 10 19 10Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>
    )
};

export const ArowLeft = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-left">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M19 11H7.135L10.768 6.64003C11.122 6.21603 11.064 5.58503 10.64 5.23203C10.215 4.87803 9.585 4.93603 9.232 5.36003L4.232 11.36C4.193 11.407 4.173 11.462 4.144 11.514C4.12 11.556 4.091 11.592 4.073 11.638C4.028 11.753 4.001 11.874 4.001 11.996C4.001 11.997 4 11.999 4 12C4 12.001 4.001 12.003 4.001 12.004C4.001 12.126 4.028 12.247 4.073 12.362C4.091 12.408 4.12 12.444 4.144 12.486C4.173 12.538 4.193 12.593 4.232 12.64L9.232 18.64C9.43 18.877 9.714 19 10 19C10.226 19 10.453 18.924 10.64 18.768C11.064 18.415 11.122 17.784 10.768 17.36L7.135 13H19C19.552 13 20 12.552 20 12C20 11.448 19.552 11 19 11Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="4" y="5" width="16" height="14">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M19 11H7.135L10.768 6.64003C11.122 6.21603 11.064 5.58503 10.64 5.23203C10.215 4.87803 9.585 4.93603 9.232 5.36003L4.232 11.36C4.193 11.407 4.173 11.462 4.144 11.514C4.12 11.556 4.091 11.592 4.073 11.638C4.028 11.753 4.001 11.874 4.001 11.996C4.001 11.997 4 11.999 4 12C4 12.001 4.001 12.003 4.001 12.004C4.001 12.126 4.028 12.247 4.073 12.362C4.091 12.408 4.12 12.444 4.144 12.486C4.173 12.538 4.193 12.593 4.232 12.64L9.232 18.64C9.43 18.877 9.714 19 10 19C10.226 19 10.453 18.924 10.64 18.768C11.064 18.415 11.122 17.784 10.768 17.36L7.135 13H19C19.552 13 20 12.552 20 12C20 11.448 19.552 11 19 11Z"
                          fill="currentColor"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>
    )
};

export const PersonSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="person-fill">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794 16 7ZM19 20C19 20.552 18.553 21 18 21H6C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12 13C15.859 13 19 16.14 19 20Z"
                      fill="#2E3A59"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="3" width="14" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794 16 7ZM19 20C19 20.552 18.553 21 18 21H6C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12 13C15.859 13 19 16.14 19 20Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>
    )
};

export const CaptureSvg = () => {
    return (
        <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#3366FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.9999 16.3333C15.4486 16.3333 14.9999 16.782 14.9999 17.3333C14.9999 17.8847 15.4486 18.3333 15.9999 18.3333C16.5513 18.3333 16.9999 17.8847 16.9999 17.3333C16.9999 16.782 16.5513 16.3333 15.9999 16.3333ZM15.9999 19.6667C14.7133 19.6667 13.6666 18.62 13.6666 17.3333C13.6666 16.0467 14.7133 15 15.9999 15C17.2866 15 18.3333 16.0467 18.3333 17.3333C18.3333 18.62 17.2866 19.6667 15.9999 19.6667ZM14.6666 11.6667C14.6666 11.4827 14.8166 11.3333 14.9999 11.3333H16.9999C17.1833 11.3333 17.3333 11.4827 17.3333 11.6667V12.6667H14.6666V11.6667ZM20.6666 12.6667H18.6666V11.6667C18.6666 10.748 17.9193 10 16.9999 10H14.9999C14.0806 10 13.3333 10.748 13.3333 11.6667V12.6667H11.3333C10.2306 12.6667 9.33325 13.564 9.33325 14.6667V20C9.33325 21.1027 10.2306 22 11.3333 22H20.6666C21.7693 22 22.6666 21.1027 22.6666 20V14.6667C22.6666 13.564 21.7693 12.6667 20.6666 12.6667Z"
                  fill="white"/>
            <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="9" y="10" width="14" height="12">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M15.9999 16.3333C15.4486 16.3333 14.9999 16.782 14.9999 17.3333C14.9999 17.8847 15.4486 18.3333 15.9999 18.3333C16.5513 18.3333 16.9999 17.8847 16.9999 17.3333C16.9999 16.782 16.5513 16.3333 15.9999 16.3333ZM15.9999 19.6667C14.7133 19.6667 13.6666 18.62 13.6666 17.3333C13.6666 16.0467 14.7133 15 15.9999 15C17.2866 15 18.3333 16.0467 18.3333 17.3333C18.3333 18.62 17.2866 19.6667 15.9999 19.6667ZM14.6666 11.6667C14.6666 11.4827 14.8166 11.3333 14.9999 11.3333H16.9999C17.1833 11.3333 17.3333 11.4827 17.3333 11.6667V12.6667H14.6666V11.6667ZM20.6666 12.6667H18.6666V11.6667C18.6666 10.748 17.9193 10 16.9999 10H14.9999C14.0806 10 13.3333 10.748 13.3333 11.6667V12.6667H11.3333C10.2306 12.6667 9.33325 13.564 9.33325 14.6667V20C9.33325 21.1027 10.2306 22 11.3333 22H20.6666C21.7693 22 22.6666 21.1027 22.6666 20V14.6667C22.6666 13.564 21.7693 12.6667 20.6666 12.6667Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </Svg>
    )
};

export const InfoSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="info">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.477 17.522 2 12 2Z"
                          fill="currentColor"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>

    )
};

export const ListSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="list">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M3 7C3 6.448 3.448 6 4 6C4.552 6 5 6.448 5 7C5 7.552 4.552 8 4 8C3.448 8 3 7.552 3 7ZM3 12C3 11.448 3.448 11 4 11C4.552 11 5 11.448 5 12C5 12.552 4.552 13 4 13C3.448 13 3 12.552 3 12ZM4 16C3.448 16 3 16.448 3 17C3 17.552 3.448 18 4 18C4.552 18 5 17.552 5 17C5 16.448 4.552 16 4 16ZM7.9355 11H20.0645C20.5795 11 21.0005 11.421 21.0005 11.936V12.064C21.0005 12.579 20.5795 13 20.0645 13H7.9355C7.4205 13 7.0005 12.579 7.0005 12.064V11.936C7.0005 11.421 7.4205 11 7.9355 11ZM20.0645 16H7.9355C7.4205 16 7.0005 16.421 7.0005 16.936V17.064C7.0005 17.579 7.4205 18 7.9355 18H20.0645C20.5795 18 21.0005 17.579 21.0005 17.064V16.936C21.0005 16.421 20.5795 16 20.0645 16ZM7.9355 6H20.0645C20.5795 6 21.0005 6.421 21.0005 6.936V7.064C21.0005 7.579 20.5795 8 20.0645 8H7.9355C7.4205 8 7.0005 7.579 7.0005 7.064V6.936C7.0005 6.421 7.4205 6 7.9355 6Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="6" width="19" height="12">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M3 7C3 6.448 3.448 6 4 6C4.552 6 5 6.448 5 7C5 7.552 4.552 8 4 8C3.448 8 3 7.552 3 7ZM3 12C3 11.448 3.448 11 4 11C4.552 11 5 11.448 5 12C5 12.552 4.552 13 4 13C3.448 13 3 12.552 3 12ZM4 16C3.448 16 3 16.448 3 17C3 17.552 3.448 18 4 18C4.552 18 5 17.552 5 17C5 16.448 4.552 16 4 16ZM7.9355 11H20.0645C20.5795 11 21.0005 11.421 21.0005 11.936V12.064C21.0005 12.579 20.5795 13 20.0645 13H7.9355C7.4205 13 7.0005 12.579 7.0005 12.064V11.936C7.0005 11.421 7.4205 11 7.9355 11ZM20.0645 16H7.9355C7.4205 16 7.0005 16.421 7.0005 16.936V17.064C7.0005 17.579 7.4205 18 7.9355 18H20.0645C20.5795 18 21.0005 17.579 21.0005 17.064V16.936C21.0005 16.421 20.5795 16 20.0645 16ZM7.9355 6H20.0645C20.5795 6 21.0005 6.421 21.0005 6.936V7.064C21.0005 7.579 20.5795 8 20.0645 8H7.9355C7.4205 8 7.0005 7.579 7.0005 7.064V6.936C7.0005 6.421 7.4205 6 7.9355 6Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>
    )
};

export const GridSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="grid">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M15 19V15H19L19.001 19H15ZM19 13H15C13.897 13 13 13.897 13 15V19C13 20.103 13.897 21 15 21H19C20.103 21 21 20.103 21 19V15C21 13.897 20.103 13 19 13ZM5 19V15H9L9.001 19H5ZM9 13H5C3.897 13 3 13.897 3 15V19C3 20.103 3.897 21 5 21H9C10.103 21 11 20.103 11 19V15C11 13.897 10.103 13 9 13ZM15 9V5H19L19.001 9H15ZM19 3H15C13.897 3 13 3.897 13 5V9C13 10.103 13.897 11 15 11H19C20.103 11 21 10.103 21 9V5C21 3.897 20.103 3 19 3ZM5 9V5H9L9.001 9H5ZM9 3H5C3.897 3 3 3.897 3 5V9C3 10.103 3.897 11 5 11H9C10.103 11 11 10.103 11 9V5C11 3.897 10.103 3 9 3Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M15 19V15H19L19.001 19H15ZM19 13H15C13.897 13 13 13.897 13 15V19C13 20.103 13.897 21 15 21H19C20.103 21 21 20.103 21 19V15C21 13.897 20.103 13 19 13ZM5 19V15H9L9.001 19H5ZM9 13H5C3.897 13 3 13.897 3 15V19C3 20.103 3.897 21 5 21H9C10.103 21 11 20.103 11 19V15C11 13.897 10.103 13 9 13ZM15 9V5H19L19.001 9H15ZM19 3H15C13.897 3 13 3.897 13 5V9C13 10.103 13.897 11 15 11H19C20.103 11 21 10.103 21 9V5C21 3.897 20.103 3 19 3ZM5 9V5H9L9.001 9H5ZM9 3H5C3.897 3 3 3.897 3 5V9C3 10.103 3.897 11 5 11H9C10.103 11 11 10.103 11 9V5C11 3.897 10.103 3 9 3Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>

    )
};

export const EditSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="edit">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M16.0187 10.6787L13.3237 7.98367L15.2717 6.03467L17.9657 8.72867L16.0187 10.6787ZM9.07965 17.6247L6.10265 17.8957L6.36665 14.9397L11.9837 9.32267L14.6797 12.0187L9.07965 17.6247ZM19.4037 7.33767L19.4027 7.33667L16.6647 4.59867C15.9237 3.85967 14.6507 3.82467 13.9487 4.52967L4.95265 13.5257C4.62665 13.8507 4.42465 14.2827 4.38265 14.7397L4.00365 18.9097C3.97765 19.2047 4.08265 19.4967 4.29265 19.7067C4.48165 19.8957 4.73665 19.9997 4.99965 19.9997C5.03065 19.9997 5.06065 19.9987 5.09065 19.9957L9.26065 19.6167C9.71865 19.5747 10.1497 19.3737 10.4747 19.0487L19.4717 10.0517C20.1997 9.32167 20.1687 8.10367 19.4037 7.33767Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="17" height="16">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M16.0187 10.6787L13.3237 7.98367L15.2717 6.03467L17.9657 8.72867L16.0187 10.6787ZM9.07965 17.6247L6.10265 17.8957L6.36665 14.9397L11.9837 9.32267L14.6797 12.0187L9.07965 17.6247ZM19.4037 7.33767L19.4027 7.33667L16.6647 4.59867C15.9237 3.85967 14.6507 3.82467 13.9487 4.52967L4.95265 13.5257C4.62665 13.8507 4.42465 14.2827 4.38265 14.7397L4.00365 18.9097C3.97765 19.2047 4.08265 19.4967 4.29265 19.7067C4.48165 19.8957 4.73665 19.9997 4.99965 19.9997C5.03065 19.9997 5.06065 19.9987 5.09065 19.9957L9.26065 19.6167C9.71865 19.5747 10.1497 19.3737 10.4747 19.0487L19.4717 10.0517C20.1997 9.32167 20.1687 8.10367 19.4037 7.33767Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const AttachItalicSvg = () => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="attach-2">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M9.29354 21C7.63754 21 6.06354 20.334 4.86154 19.125C2.47354 16.722 2.37554 12.912 4.64254 10.631L12.0285 3.201C12.7975 2.427 13.8355 2 14.9505 2C16.1435 2 17.2775 2.479 18.1425 3.349C19.8635 5.08 19.9295 7.831 18.2885 9.481L10.8935 16.91C10.4145 17.393 9.76954 17.658 9.07754 17.658C8.34654 17.658 7.65354 17.366 7.12754 16.837C6.07454 15.776 6.04154 14.085 7.05454 13.065L13.8795 6.21C14.2695 5.818 14.9015 5.816 15.2935 6.206C15.6845 6.596 15.6865 7.229 15.2965 7.62L8.47254 14.476C8.23254 14.718 8.26554 15.145 8.54654 15.427C8.69254 15.574 8.88654 15.658 9.07754 15.658C9.18754 15.658 9.34554 15.631 9.47554 15.5L16.8705 8.071C17.7375 7.198 17.6725 5.713 16.7245 4.759C15.8175 3.847 14.2785 3.775 13.4465 4.611L6.06054 12.041C4.56654 13.544 4.66454 16.09 6.28054 17.715C7.10354 18.544 8.17354 19 9.29354 19C10.2945 19 11.2225 18.622 11.9045 17.936L19.2915 10.506C19.6805 10.115 20.3135 10.112 20.7055 10.502C21.0965 10.892 21.0985 11.524 20.7095 11.916L13.3225 19.346C12.2625 20.412 10.8315 21 9.29354 21Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="19">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M9.29354 21C7.63754 21 6.06354 20.334 4.86154 19.125C2.47354 16.722 2.37554 12.912 4.64254 10.631L12.0285 3.201C12.7975 2.427 13.8355 2 14.9505 2C16.1435 2 17.2775 2.479 18.1425 3.349C19.8635 5.08 19.9295 7.831 18.2885 9.481L10.8935 16.91C10.4145 17.393 9.76954 17.658 9.07754 17.658C8.34654 17.658 7.65354 17.366 7.12754 16.837C6.07454 15.776 6.04154 14.085 7.05454 13.065L13.8795 6.21C14.2695 5.818 14.9015 5.816 15.2935 6.206C15.6845 6.596 15.6865 7.229 15.2965 7.62L8.47254 14.476C8.23254 14.718 8.26554 15.145 8.54654 15.427C8.69254 15.574 8.88654 15.658 9.07754 15.658C9.18754 15.658 9.34554 15.631 9.47554 15.5L16.8705 8.071C17.7375 7.198 17.6725 5.713 16.7245 4.759C15.8175 3.847 14.2785 3.775 13.4465 4.611L6.06054 12.041C4.56654 13.544 4.66454 16.09 6.28054 17.715C7.10354 18.544 8.17354 19 9.29354 19C10.2945 19 11.2225 18.622 11.9045 17.936L19.2915 10.506C19.6805 10.115 20.3135 10.112 20.7055 10.502C21.0965 10.892 21.0985 11.524 20.7095 11.916L13.3225 19.346C12.2625 20.412 10.8315 21 9.29354 21Z"
                          fill="currentColor"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </Svg>
    )
};

export const PeopleSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="people">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M22 19C22 19.552 21.553 20 21 20C20.447 20 20 19.552 20 19C20 17.346 18.654 16 17 16C16.317 16 15.668 16.234 15.144 16.649C15.688 17.645 16 18.787 16 20C16 20.552 15.553 21 15 21C14.447 21 14 20.552 14 20C14 17.243 11.757 15 9 15C6.243 15 4 17.243 4 20C4 20.552 3.553 21 3 21C2.447 21 2 20.552 2 20C2 16.14 5.141 13 9 13C10.927 13 12.673 13.783 13.94 15.046C14.809 14.374 15.879 14 17 14C19.757 14 22 16.243 22 19ZM17 9C17.552 9 18 9.449 18 10C18 10.551 17.552 11 17 11C16.448 11 16 10.551 16 10C16 9.449 16.448 9 17 9ZM17 13C18.654 13 20 11.654 20 10C20 8.346 18.654 7 17 7C15.346 7 14 8.346 14 10C14 11.654 15.346 13 17 13ZM9 5C10.103 5 11 5.897 11 7C11 8.103 10.103 9 9 9C7.897 9 7 8.103 7 7C7 5.897 7.897 5 9 5ZM9 11C11.206 11 13 9.206 13 7C13 4.794 11.206 3 9 3C6.794 3 5 4.794 5 7C5 9.206 6.794 11 9 11Z"
                      fill="currentColor"/>
            </g>
        </svg>
    )
};

export const Edit2Svg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="edit-2">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M6.10263 15.8735L9.07963 15.6025L14.6796 9.99746L11.9836 7.30046L6.36663 12.9175L6.10263 15.8735ZM13.3236 5.96146L16.0186 8.65646L17.9656 6.70646L15.2716 4.01246L13.3236 5.96146ZM4.29263 17.6855C4.08263 17.4755 3.97763 17.1835 4.00363 16.8875L4.38263 12.7175C4.42463 12.2605 4.62663 11.8295 4.95263 11.5035L13.9486 2.50746C14.6506 1.80246 15.9236 1.83746 16.6646 2.57646L19.4026 5.31446L19.4036 5.31546C20.1686 6.08146 20.1996 7.29946 19.4716 8.02946L10.4746 17.0265C10.1496 17.3515 9.71863 17.5535 9.26063 17.5955L5.09063 17.9745C5.06063 17.9765 5.03063 17.9775 4.99963 17.9775C4.73663 17.9775 4.48163 17.8735 4.29263 17.6855ZM19.9996 20.9775C19.9996 21.5275 19.5496 21.9775 18.9996 21.9775H4.99963C4.45063 21.9775 3.99963 21.5275 3.99963 20.9775C3.99963 20.4285 4.45063 19.9775 4.99963 19.9775H18.9996C19.5496 19.9775 19.9996 20.4285 19.9996 20.9775Z"
                      fill="currentColor"/>
            </g>
        </svg>

    )
};
export const OrganizationSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 2.5C8 2.224 8.224 2 8.5 2H11.5C11.776 2 12 2.224 12 2.5V4H8V2.5ZM7 16H13V6H7V16ZM18 15C18 15.551 17.551 16 17 16H15V6H17C17.551 6 18 6.449 18 7V15ZM2 7V15C2 15.551 2.449 16 3 16H5V6H3C2.449 6 2 6.449 2 7ZM17 4H14V2.5C14 1.122 12.878 0 11.5 0H8.5C7.122 0 6 1.122 6 2.5V4H3C1.346 4 0 5.346 0 7V15C0 16.654 1.346 18 3 18H17C18.654 18 20 16.654 20 15V7C20 5.346 18.654 4 17 4Z"
                  fill="currentColor"/>
        </svg>
    )
};

export const SectorsSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="shuffle-2">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M18.709 14.2944C18.319 13.9024 17.685 13.9004 17.295 14.2914C16.903 14.6814 16.901 15.3144 17.291 15.7054L17.584 16.0004H16C13.794 16.0004 12 14.2054 12 12.0004C12 9.7944 13.794 8.0004 16 8.0004H17.588L17.295 8.2914C16.903 8.6814 16.901 9.3144 17.291 9.7054C17.486 9.9014 17.743 10.0004 18 10.0004C18.255 10.0004 18.511 9.9024 18.705 9.7084L20.705 7.7174C20.894 7.5304 20.999 7.2764 21 7.0114C21.001 6.7454 20.896 6.4914 20.709 6.3034L18.709 4.2944C18.319 3.9024 17.685 3.9004 17.295 4.2914C16.903 4.6814 16.901 5.3144 17.291 5.7054L17.584 6.0004H16C13.914 6.0004 12.075 7.0704 11 8.6904C9.925 7.0704 8.086 6.0004 6 6.0004H4C3.447 6.0004 3 6.4474 3 7.0004C3 7.5524 3.447 8.0004 4 8.0004H6C8.206 8.0004 10 9.7944 10 12.0004C10 14.2054 8.206 16.0004 6 16.0004H4C3.447 16.0004 3 16.4474 3 17.0004C3 17.5524 3.447 18.0004 4 18.0004H6C8.086 18.0004 9.925 16.9294 11 15.3094C12.075 16.9294 13.914 18.0004 16 18.0004H17.588L17.295 18.2914C16.903 18.6814 16.901 19.3144 17.291 19.7054C17.486 19.9014 17.743 20.0004 18 20.0004C18.255 20.0004 18.511 19.9024 18.705 19.7084L20.705 17.7174C20.894 17.5304 20.999 17.2764 21 17.0114C21.001 16.7454 20.896 16.4914 20.709 16.3034L18.709 14.2944Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M18.709 14.2944C18.319 13.9024 17.685 13.9004 17.295 14.2914C16.903 14.6814 16.901 15.3144 17.291 15.7054L17.584 16.0004H16C13.794 16.0004 12 14.2054 12 12.0004C12 9.7944 13.794 8.0004 16 8.0004H17.588L17.295 8.2914C16.903 8.6814 16.901 9.3144 17.291 9.7054C17.486 9.9014 17.743 10.0004 18 10.0004C18.255 10.0004 18.511 9.9024 18.705 9.7084L20.705 7.7174C20.894 7.5304 20.999 7.2764 21 7.0114C21.001 6.7454 20.896 6.4914 20.709 6.3034L18.709 4.2944C18.319 3.9024 17.685 3.9004 17.295 4.2914C16.903 4.6814 16.901 5.3144 17.291 5.7054L17.584 6.0004H16C13.914 6.0004 12.075 7.0704 11 8.6904C9.925 7.0704 8.086 6.0004 6 6.0004H4C3.447 6.0004 3 6.4474 3 7.0004C3 7.5524 3.447 8.0004 4 8.0004H6C8.206 8.0004 10 9.7944 10 12.0004C10 14.2054 8.206 16.0004 6 16.0004H4C3.447 16.0004 3 16.4474 3 17.0004C3 17.5524 3.447 18.0004 4 18.0004H6C8.086 18.0004 9.925 16.9294 11 15.3094C12.075 16.9294 13.914 18.0004 16 18.0004H17.588L17.295 18.2914C16.903 18.6814 16.901 19.3144 17.291 19.7054C17.486 19.9014 17.743 20.0004 18 20.0004C18.255 20.0004 18.511 19.9024 18.705 19.7084L20.705 17.7174C20.894 17.5304 20.999 17.2764 21 17.0114C21.001 16.7454 20.896 16.4914 20.709 16.3034L18.709 14.2944Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const AwardSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="award">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M12.3057 16.707C11.9947 16.536 11.6187 16.539 11.3157 16.72L7.56772 18.92L9.03572 13.21C9.91072 13.71 10.9217 14 11.9997 14C13.0777 14 14.0887 13.71 14.9647 13.21L16.4537 19.002L12.3057 16.707ZM11.9997 4C14.2057 4 15.9997 5.794 15.9997 8C15.9997 10.206 14.2057 12 11.9997 12C9.79372 12 7.99972 10.206 7.99972 8C7.99972 5.794 9.79372 4 11.9997 4ZM18.9687 20.751L16.6597 11.772C17.4957 10.74 17.9997 9.429 17.9997 8C17.9997 4.691 15.3087 2 11.9997 2C8.69172 2 5.99972 4.691 5.99972 8C5.99972 9.429 6.50372 10.74 7.33972 11.772L5.03172 20.751C4.93072 21.143 5.07572 21.556 5.39872 21.799C5.72072 22.042 6.15872 22.064 6.50572 21.862L11.8357 18.733L17.5157 21.875C17.6667 21.959 17.8337 22 17.9997 22C18.2167 22 18.4327 21.93 18.6127 21.791C18.9277 21.547 19.0687 21.138 18.9687 20.751Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="15" height="20">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M12.3057 16.707C11.9947 16.536 11.6187 16.539 11.3157 16.72L7.56772 18.92L9.03572 13.21C9.91072 13.71 10.9217 14 11.9997 14C13.0777 14 14.0887 13.71 14.9647 13.21L16.4537 19.002L12.3057 16.707ZM11.9997 4C14.2057 4 15.9997 5.794 15.9997 8C15.9997 10.206 14.2057 12 11.9997 12C9.79372 12 7.99972 10.206 7.99972 8C7.99972 5.794 9.79372 4 11.9997 4ZM18.9687 20.751L16.6597 11.772C17.4957 10.74 17.9997 9.429 17.9997 8C17.9997 4.691 15.3087 2 11.9997 2C8.69172 2 5.99972 4.691 5.99972 8C5.99972 9.429 6.50372 10.74 7.33972 11.772L5.03172 20.751C4.93072 21.143 5.07572 21.556 5.39872 21.799C5.72072 22.042 6.15872 22.064 6.50572 21.862L11.8357 18.733L17.5157 21.875C17.6667 21.959 17.8337 22 17.9997 22C18.2167 22 18.4327 21.93 18.6127 21.791C18.9277 21.547 19.0687 21.138 18.9687 20.751Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const TrashSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="trash-2">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16ZM16 16C16 16.55 15.55 17 15 17C14.45 17 14 16.55 14 16V12C14 11.45 14.45 11 15 11C15.55 11 16 11.45 16 12V16ZM18 19C18 19.551 17.552 20 17 20H7C6.448 20 6 19.551 6 19V8H18V19ZM10 4.328C10 4.173 10.214 4 10.5 4H13.5C13.786 4 14 4.173 14 4.328V6H10V4.328ZM21 6H20H16V4.328C16 3.044 14.879 2 13.5 2H10.5C9.121 2 8 3.044 8 4.328V6H4H3C2.45 6 2 6.45 2 7C2 7.55 2.45 8 3 8H4V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V8H21C21.55 8 22 7.55 22 7C22 6.45 21.55 6 21 6Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16ZM16 16C16 16.55 15.55 17 15 17C14.45 17 14 16.55 14 16V12C14 11.45 14.45 11 15 11C15.55 11 16 11.45 16 12V16ZM18 19C18 19.551 17.552 20 17 20H7C6.448 20 6 19.551 6 19V8H18V19ZM10 4.328C10 4.173 10.214 4 10.5 4H13.5C13.786 4 14 4.173 14 4.328V6H10V4.328ZM21 6H20H16V4.328C16 3.044 14.879 2 13.5 2H10.5C9.121 2 8 3.044 8 4.328V6H4H3C2.45 6 2 6.45 2 7C2 7.55 2.45 8 3 8H4V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V8H21C21.55 8 22 7.55 22 7C22 6.45 21.55 6 21 6Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const LockSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="lock">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M12 16C11.448 16 11 15.552 11 15C11 14.448 11.448 14 12 14C12.552 14 13 14.448 13 15C13 15.552 12.552 16 12 16ZM12 12C10.346 12 9 13.346 9 15C9 16.654 10.346 18 12 18C13.654 18 15 16.654 15 15C15 13.346 13.654 12 12 12ZM18 19C18 19.552 17.552 20 17 20H7C6.448 20 6 19.552 6 19V11C6 10.448 6.448 10 7 10H8H10H14H16H17C17.552 10 18 10.448 18 11V19ZM10 6.111C10 4.947 10.897 4 12 4C13.103 4 14 4.947 14 6.111V8H10V6.111ZM17 8H16V6.111C16 3.845 14.206 2 12 2C9.794 2 8 3.845 8 6.111V8H7C5.346 8 4 9.346 4 11V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V11C20 9.346 18.654 8 17 8Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="4" y="2" width="16" height="20">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M12 16C11.448 16 11 15.552 11 15C11 14.448 11.448 14 12 14C12.552 14 13 14.448 13 15C13 15.552 12.552 16 12 16ZM12 12C10.346 12 9 13.346 9 15C9 16.654 10.346 18 12 18C13.654 18 15 16.654 15 15C15 13.346 13.654 12 12 12ZM18 19C18 19.552 17.552 20 17 20H7C6.448 20 6 19.552 6 19V11C6 10.448 6.448 10 7 10H8H10H14H16H17C17.552 10 18 10.448 18 11V19ZM10 6.111C10 4.947 10.897 4 12 4C13.103 4 14 4.947 14 6.111V8H10V6.111ZM17 8H16V6.111C16 3.845 14.206 2 12 2C9.794 2 8 3.845 8 6.111V8H7C5.346 8 4 9.346 4 11V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V11C20 9.346 18.654 8 17 8Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const PersonLineSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Line / person">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M19 20C19 20.552 18.553 21 18 21C17.447 21 17 20.552 17 20C17 17.243 14.757 15 12 15C9.243 15 7 17.243 7 20C7 20.552 6.553 21 6 21C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12 13C15.859 13 19 16.14 19 20ZM12 5C13.103 5 14 5.897 14 7C14 8.103 13.103 9 12 9C10.897 9 10 8.103 10 7C10 5.897 10.897 5 12 5ZM12 11C14.206 11 16 9.206 16 7C16 4.794 14.206 3 12 3C9.794 3 8 4.794 8 7C8 9.206 9.794 11 12 11Z"
                      fill="currentColor"/>
            </g>
        </svg>
    )
};

export const PersonGroupLineSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Line / people">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M22 19C22 19.552 21.553 20 21 20C20.447 20 20 19.552 20 19C20 17.346 18.654 16 17 16C16.317 16 15.668 16.234 15.144 16.649C15.688 17.645 16 18.787 16 20C16 20.552 15.553 21 15 21C14.447 21 14 20.552 14 20C14 17.243 11.757 15 9 15C6.243 15 4 17.243 4 20C4 20.552 3.553 21 3 21C2.447 21 2 20.552 2 20C2 16.14 5.141 13 9 13C10.927 13 12.673 13.783 13.94 15.046C14.809 14.374 15.879 14 17 14C19.757 14 22 16.243 22 19ZM17 9C17.552 9 18 9.449 18 10C18 10.551 17.552 11 17 11C16.448 11 16 10.551 16 10C16 9.449 16.448 9 17 9ZM17 13C18.654 13 20 11.654 20 10C20 8.346 18.654 7 17 7C15.346 7 14 8.346 14 10C14 11.654 15.346 13 17 13ZM9 5C10.103 5 11 5.897 11 7C11 8.103 10.103 9 9 9C7.897 9 7 8.103 7 7C7 5.897 7.897 5 9 5ZM9 11C11.206 11 13 9.206 13 7C13 4.794 11.206 3 9 3C6.794 3 5 4.794 5 7C5 9.206 6.794 11 9 11Z"
                      fill="currentColor"/>
            </g>
        </svg>
    )
};

export const BriefCaseSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Line / briefcase">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M10 5.5C10 5.224 10.224 5 10.5 5H13.5C13.776 5 14 5.224 14 5.5V7H10V5.5ZM9 19H15V9H9V19ZM20 18C20 18.551 19.551 19 19 19H17V9H19C19.551 9 20 9.449 20 10V18ZM4 10V18C4 18.551 4.449 19 5 19H7V9H5C4.449 9 4 9.449 4 10ZM19 7H16V5.5C16 4.122 14.878 3 13.5 3H10.5C9.122 3 8 4.122 8 5.5V7H5C3.346 7 2 8.346 2 10V18C2 19.654 3.346 21 5 21H19C20.654 21 22 19.654 22 18V10C22 8.346 20.654 7 19 7Z"
                      fill="currentColor"/>
            </g>
        </svg>
    )
};

export const TimerSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Line / timer">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M15 12H13V10C13 9.447 12.552 9 12 9C11.448 9 11 9.447 11 10V13C11 13.553 11.448 14 12 14H15C15.552 14 16 13.553 16 13C16 12.447 15.552 12 15 12ZM12 19.75C8.278 19.75 5.25 16.722 5.25 13C5.25 9.278 8.278 6.25 12 6.25C15.722 6.25 18.75 9.278 18.75 13C18.75 16.722 15.722 19.75 12 19.75ZM12.988 4.058C12.99 4.037 13 4.021 13 4V3H14C14.552 3 15 2.553 15 2C15 1.447 14.552 1 14 1H10C9.448 1 9 1.447 9 2C9 2.553 9.448 3 10 3H11V4C11 4.021 11.01 4.037 11.012 4.058C6.513 4.552 3 8.372 3 13C3 17.963 7.038 22 12 22C16.962 22 21 17.963 21 13C21 8.372 17.487 4.552 12.988 4.058Z"
                      fill="currentColor"/>
                <mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="3" y="1" width="18" height="21">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M15 12H13V10C13 9.447 12.552 9 12 9C11.448 9 11 9.447 11 10V13C11 13.553 11.448 14 12 14H15C15.552 14 16 13.553 16 13C16 12.447 15.552 12 15 12ZM12 19.75C8.278 19.75 5.25 16.722 5.25 13C5.25 9.278 8.278 6.25 12 6.25C15.722 6.25 18.75 9.278 18.75 13C18.75 16.722 15.722 19.75 12 19.75ZM12.988 4.058C12.99 4.037 13 4.021 13 4V3H14C14.552 3 15 2.553 15 2C15 1.447 14.552 1 14 1H10C9.448 1 9 1.447 9 2C9 2.553 9.448 3 10 3H11V4C11 4.021 11.01 4.037 11.012 4.058C6.513 4.552 3 8.372 3 13C3 17.963 7.038 22 12 22C16.962 22 21 17.963 21 13C21 8.372 17.487 4.552 12.988 4.058Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const PhoneSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Line / phone">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M6.6 4C5.166 4 4 5.166 4 6.6C4 13.988 10.011 20 17.4 20C18.833 20 20 18.834 20 17.4C20 17.289 19.993 17.18 19.979 17.072L15.359 16.012C15.267 16.188 15.166 16.379 15.074 16.557C14.618 17.427 14.288 18.059 13.446 17.72C10.14 16.56 7.441 13.86 6.266 10.514C5.909 9.729 6.589 9.373 7.451 8.923C7.626 8.831 7.815 8.732 7.988 8.642L6.928 4.021C6.817 4.007 6.709 4 6.6 4ZM17.4 22C8.908 22 2 15.092 2 6.6C2 4.063 4.063 2 6.6 2C6.86 2 7.117 2.022 7.366 2.065C7.609 2.104 7.853 2.166 8.091 2.248C8.416 2.361 8.661 2.634 8.738 2.97L10.107 8.934C10.183 9.262 10.088 9.606 9.854 9.85C9.718 9.992 9.715 9.995 8.475 10.644C9.479 12.818 11.224 14.557 13.354 15.527C14.004 14.286 14.008 14.282 14.15 14.146C14.394 13.912 14.74 13.821 15.066 13.893L21.031 15.262C21.366 15.339 21.639 15.583 21.751 15.908C21.833 16.144 21.895 16.388 21.936 16.639C21.978 16.886 22 17.142 22 17.4C22 19.937 19.936 22 17.4 22Z"
                      fill="currentColor"/>
                <mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M6.6 4C5.166 4 4 5.166 4 6.6C4 13.988 10.011 20 17.4 20C18.833 20 20 18.834 20 17.4C20 17.289 19.993 17.18 19.979 17.072L15.359 16.012C15.267 16.188 15.166 16.379 15.074 16.557C14.618 17.427 14.288 18.059 13.446 17.72C10.14 16.56 7.441 13.86 6.266 10.514C5.909 9.729 6.589 9.373 7.451 8.923C7.626 8.831 7.815 8.732 7.988 8.642L6.928 4.021C6.817 4.007 6.709 4 6.6 4ZM17.4 22C8.908 22 2 15.092 2 6.6C2 4.063 4.063 2 6.6 2C6.86 2 7.117 2.022 7.366 2.065C7.609 2.104 7.853 2.166 8.091 2.248C8.416 2.361 8.661 2.634 8.738 2.97L10.107 8.934C10.183 9.262 10.088 9.606 9.854 9.85C9.718 9.992 9.715 9.995 8.475 10.644C9.479 12.818 11.224 14.557 13.354 15.527C14.004 14.286 14.008 14.282 14.15 14.146C14.394 13.912 14.74 13.821 15.066 13.893L21.031 15.262C21.366 15.339 21.639 15.583 21.751 15.908C21.833 16.144 21.895 16.388 21.936 16.639C21.978 16.886 22 17.142 22 17.4C22 19.937 19.936 22 17.4 22Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const SectorSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="shuffle-2">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M18.709 14.2944C18.319 13.9024 17.685 13.9004 17.295 14.2914C16.903 14.6814 16.901 15.3144 17.291 15.7054L17.584 16.0004H16C13.794 16.0004 12 14.2054 12 12.0004C12 9.7944 13.794 8.0004 16 8.0004H17.588L17.295 8.2914C16.903 8.6814 16.901 9.3144 17.291 9.7054C17.486 9.9014 17.743 10.0004 18 10.0004C18.255 10.0004 18.511 9.9024 18.705 9.7084L20.705 7.7174C20.894 7.5304 20.999 7.2764 21 7.0114C21.001 6.7454 20.896 6.4914 20.709 6.3034L18.709 4.2944C18.319 3.9024 17.685 3.9004 17.295 4.2914C16.903 4.6814 16.901 5.3144 17.291 5.7054L17.584 6.0004H16C13.914 6.0004 12.075 7.0704 11 8.6904C9.925 7.0704 8.086 6.0004 6 6.0004H4C3.447 6.0004 3 6.4474 3 7.0004C3 7.5524 3.447 8.0004 4 8.0004H6C8.206 8.0004 10 9.7944 10 12.0004C10 14.2054 8.206 16.0004 6 16.0004H4C3.447 16.0004 3 16.4474 3 17.0004C3 17.5524 3.447 18.0004 4 18.0004H6C8.086 18.0004 9.925 16.9294 11 15.3094C12.075 16.9294 13.914 18.0004 16 18.0004H17.588L17.295 18.2914C16.903 18.6814 16.901 19.3144 17.291 19.7054C17.486 19.9014 17.743 20.0004 18 20.0004C18.255 20.0004 18.511 19.9024 18.705 19.7084L20.705 17.7174C20.894 17.5304 20.999 17.2764 21 17.0114C21.001 16.7454 20.896 16.4914 20.709 16.3034L18.709 14.2944Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M18.709 14.2944C18.319 13.9024 17.685 13.9004 17.295 14.2914C16.903 14.6814 16.901 15.3144 17.291 15.7054L17.584 16.0004H16C13.794 16.0004 12 14.2054 12 12.0004C12 9.7944 13.794 8.0004 16 8.0004H17.588L17.295 8.2914C16.903 8.6814 16.901 9.3144 17.291 9.7054C17.486 9.9014 17.743 10.0004 18 10.0004C18.255 10.0004 18.511 9.9024 18.705 9.7084L20.705 7.7174C20.894 7.5304 20.999 7.2764 21 7.0114C21.001 6.7454 20.896 6.4914 20.709 6.3034L18.709 4.2944C18.319 3.9024 17.685 3.9004 17.295 4.2914C16.903 4.6814 16.901 5.3144 17.291 5.7054L17.584 6.0004H16C13.914 6.0004 12.075 7.0704 11 8.6904C9.925 7.0704 8.086 6.0004 6 6.0004H4C3.447 6.0004 3 6.4474 3 7.0004C3 7.5524 3.447 8.0004 4 8.0004H6C8.206 8.0004 10 9.7944 10 12.0004C10 14.2054 8.206 16.0004 6 16.0004H4C3.447 16.0004 3 16.4474 3 17.0004C3 17.5524 3.447 18.0004 4 18.0004H6C8.086 18.0004 9.925 16.9294 11 15.3094C12.075 16.9294 13.914 18.0004 16 18.0004H17.588L17.295 18.2914C16.903 18.6814 16.901 19.3144 17.291 19.7054C17.486 19.9014 17.743 20.0004 18 20.0004C18.255 20.0004 18.511 19.9024 18.705 19.7084L20.705 17.7174C20.894 17.5304 20.999 17.2764 21 17.0114C21.001 16.7454 20.896 16.4914 20.709 16.3034L18.709 14.2944Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const ArrowRightSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-ios-right">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M9.9995 19C9.7735 19 9.5465 18.924 9.3595 18.768C8.9355 18.415 8.8785 17.784 9.2315 17.36L13.7075 11.989L9.3925 6.62695C9.0465 6.19695 9.1145 5.56695 9.5445 5.22095C9.9755 4.87495 10.6045 4.94295 10.9515 5.37295L15.7795 11.373C16.0775 11.744 16.0735 12.274 15.7685 12.64L10.7685 18.64C10.5705 18.877 10.2865 19 9.9995 19Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="9" y="5" width="8" height="14">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M9.9995 19C9.7735 19 9.5465 18.924 9.3595 18.768C8.9355 18.415 8.8785 17.784 9.2315 17.36L13.7075 11.989L9.3925 6.62695C9.0465 6.19695 9.1145 5.56695 9.5445 5.22095C9.9755 4.87495 10.6045 4.94295 10.9515 5.37295L15.7795 11.373C16.0775 11.744 16.0735 12.274 15.7685 12.64L10.7685 18.64C10.5705 18.877 10.2865 19 9.9995 19Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)"/>
            </g>
        </svg>
    )
};

export const ArrowLeftSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-ios-left">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M13.8287 19C13.5367 19 13.2467 18.873 13.0487 18.627L8.22066 12.627C7.92266 12.256 7.92666 11.726 8.23166 11.36L13.2317 5.35998C13.5847 4.93598 14.2157 4.87898 14.6407 5.23198C15.0647 5.58498 15.1217 6.21598 14.7677 6.63998L10.2927 12.011L14.6077 17.373C14.9537 17.803 14.8857 18.433 14.4547 18.779C14.2707 18.928 14.0487 19 13.8287 19Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="8" y="5" width="7" height="14">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M13.8287 19C13.5367 19 13.2467 18.873 13.0487 18.627L8.22066 12.627C7.92266 12.256 7.92666 11.726 8.23166 11.36L13.2317 5.35998C13.5847 4.93598 14.2157 4.87898 14.6407 5.23198C15.0647 5.58498 15.1217 6.21598 14.7677 6.63998L10.2927 12.011L14.6077 17.373C14.9537 17.803 14.8857 18.433 14.4547 18.779C14.2707 18.928 14.0487 19 13.8287 19Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const ArrowBottomSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-ios-down">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M11.9999 16C11.7719 16 11.5449 15.923 11.3599 15.768L5.35991 10.768C4.93591 10.415 4.87791 9.78398 5.23191 9.35998C5.58491 8.93598 6.21491 8.87898 6.63991 9.23198L12.0109 13.708L17.3729 9.39298C17.8029 9.04698 18.4329 9.11498 18.7789 9.54498C19.1249 9.97498 19.0569 10.604 18.6269 10.951L12.6269 15.779C12.4439 15.926 12.2219 16 11.9999 16Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="9" width="14" height="7">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M11.9999 16C11.7719 16 11.5449 15.923 11.3599 15.768L5.35991 10.768C4.93591 10.415 4.87791 9.78398 5.23191 9.35998C5.58491 8.93598 6.21491 8.87898 6.63991 9.23198L12.0109 13.708L17.3729 9.39298C17.8029 9.04698 18.4329 9.11498 18.7789 9.54498C19.1249 9.97498 19.0569 10.604 18.6269 10.951L12.6269 15.779C12.4439 15.926 12.2219 16 11.9999 16Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const ArrowHeadRight = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrowhead-right">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M13.9513 5.373L18.7793 11.373C19.0783 11.744 19.0733 12.274 18.7683 12.64L13.7683 18.64C13.5703 18.877 13.2863 19 12.9993 19C12.7743 19 12.5473 18.924 12.3603 18.768C11.9353 18.415 11.8783 17.784 12.2323 17.36L16.7083 11.989L12.3923 6.627C12.0463 6.197 12.1143 5.567 12.5453 5.221C12.9753 4.874 13.6043 4.943 13.9513 5.373ZM5.54523 5.221C5.97523 4.875 6.60423 4.943 6.95123 5.373L11.7792 11.373C12.0772 11.744 12.0732 12.274 11.7682 12.64L6.76823 18.64C6.57023 18.877 6.28623 19 5.99923 19C5.77423 19 5.54723 18.924 5.36023 18.768C4.93523 18.415 4.87823 17.784 5.23223 17.36L9.70723 11.989L5.39223 6.627C5.04623 6.197 5.11523 5.567 5.54523 5.221Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="4" width="15" height="15">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M13.9513 5.373L18.7793 11.373C19.0783 11.744 19.0733 12.274 18.7683 12.64L13.7683 18.64C13.5703 18.877 13.2863 19 12.9993 19C12.7743 19 12.5473 18.924 12.3603 18.768C11.9353 18.415 11.8783 17.784 12.2323 17.36L16.7083 11.989L12.3923 6.627C12.0463 6.197 12.1143 5.567 12.5453 5.221C12.9753 4.874 13.6043 4.943 13.9513 5.373ZM5.54523 5.221C5.97523 4.875 6.60423 4.943 6.95123 5.373L11.7792 11.373C12.0772 11.744 12.0732 12.274 11.7682 12.64L6.76823 18.64C6.57023 18.877 6.28623 19 5.99923 19C5.77423 19 5.54723 18.924 5.36023 18.768C4.93523 18.415 4.87823 17.784 5.23223 17.36L9.70723 11.989L5.39223 6.627C5.04623 6.197 5.11523 5.567 5.54523 5.221Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)"/>
            </g>
        </svg>
    )
};

export const ArrowHeadLeft = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrowhead-left">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M18.7684 6.64019L14.2924 12.0112L18.6074 17.3732C18.9534 17.8032 18.8854 18.4322 18.4554 18.7792C18.2704 18.9282 18.0484 19.0002 17.8284 19.0002C17.5364 19.0002 17.2464 18.8722 17.0484 18.6272L12.2204 12.6272C11.9224 12.2562 11.9264 11.7252 12.2314 11.3602L17.2314 5.36019C17.5854 4.93519 18.2154 4.87919 18.6404 5.23219C19.0644 5.58519 19.1214 6.21619 18.7684 6.64019ZM10.232 5.35989C10.585 4.93589 11.215 4.87889 11.64 5.23189C12.064 5.58589 12.121 6.21589 11.768 6.63989L7.29204 12.0109L11.607 17.3729C11.953 17.8029 11.885 18.4329 11.455 18.7789C11.27 18.9279 11.049 18.9999 10.828 18.9999C10.536 18.9999 10.246 18.8729 10.049 18.6269L5.22104 12.6269C4.92204 12.2559 4.92704 11.7259 5.23204 11.3599L10.232 5.35989Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="15">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M18.7684 6.64019L14.2924 12.0112L18.6074 17.3732C18.9534 17.8032 18.8854 18.4322 18.4554 18.7792C18.2704 18.9282 18.0484 19.0002 17.8284 19.0002C17.5364 19.0002 17.2464 18.8722 17.0484 18.6272L12.2204 12.6272C11.9224 12.2562 11.9264 11.7252 12.2314 11.3602L17.2314 5.36019C17.5854 4.93519 18.2154 4.87919 18.6404 5.23219C19.0644 5.58519 19.1214 6.21619 18.7684 6.64019ZM10.232 5.35989C10.585 4.93589 11.215 4.87889 11.64 5.23189C12.064 5.58589 12.121 6.21589 11.768 6.63989L7.29204 12.0109L11.607 17.3729C11.953 17.8029 11.885 18.4329 11.455 18.7789C11.27 18.9279 11.049 18.9999 10.828 18.9999C10.536 18.9999 10.246 18.8729 10.049 18.6269L5.22104 12.6269C4.92204 12.2559 4.92704 11.7259 5.23204 11.3599L10.232 5.35989Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)"/>
            </g>
        </svg>
    )
};

export const CameraFillSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="camera-fill">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M12 12.5C11.173 12.5 10.5 13.173 10.5 14C10.5 14.827 11.173 15.5 12 15.5C12.827 15.5 13.5 14.827 13.5 14C13.5 13.173 12.827 12.5 12 12.5ZM12 17.5C10.07 17.5 8.5 15.93 8.5 14C8.5 12.07 10.07 10.5 12 10.5C13.93 10.5 15.5 12.07 15.5 14C15.5 15.93 13.93 17.5 12 17.5ZM10 5.5C10 5.224 10.225 5 10.5 5H13.5C13.775 5 14 5.224 14 5.5V7H10V5.5ZM19 7H16V5.5C16 4.122 14.879 3 13.5 3H10.5C9.121 3 8 4.122 8 5.5V7H5C3.346 7 2 8.346 2 10V18C2 19.654 3.346 21 5 21H19C20.654 21 22 19.654 22 18V10C22 8.346 20.654 7 19 7Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="20" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M12 12.5C11.173 12.5 10.5 13.173 10.5 14C10.5 14.827 11.173 15.5 12 15.5C12.827 15.5 13.5 14.827 13.5 14C13.5 13.173 12.827 12.5 12 12.5ZM12 17.5C10.07 17.5 8.5 15.93 8.5 14C8.5 12.07 10.07 10.5 12 10.5C13.93 10.5 15.5 12.07 15.5 14C15.5 15.93 13.93 17.5 12 17.5ZM10 5.5C10 5.224 10.225 5 10.5 5H13.5C13.775 5 14 5.224 14 5.5V7H10V5.5ZM19 7H16V5.5C16 4.122 14.879 3 13.5 3H10.5C9.121 3 8 4.122 8 5.5V7H5C3.346 7 2 8.346 2 10V18C2 19.654 3.346 21 5 21H19C20.654 21 22 19.654 22 18V10C22 8.346 20.654 7 19 7Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)"/>
            </g>
        </svg>
    )
};

export const MapSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="map">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M19 17.4541L17 16.5561V6.54608L19 7.44408V17.4541ZM13 7.44408L15 6.54608V16.5561L13 17.4541V7.44408ZM9 6.54608L11 7.44408V17.4541L9 16.5561V6.54608ZM5 7.44408L7 6.54608V16.5561L5 17.4541V7.44408ZM20.41 5.88608L16.41 4.08808C16.395 4.08108 16.379 4.08008 16.364 4.07408C16.323 4.05808 16.282 4.04708 16.24 4.03608C16.201 4.02608 16.162 4.01708 16.122 4.01308C16.083 4.00808 16.044 4.00708 16.004 4.00708C15.96 4.00708 15.917 4.00808 15.873 4.01308C15.836 4.01708 15.801 4.02608 15.765 4.03508C15.721 4.04608 15.678 4.05808 15.635 4.07408C15.621 4.08008 15.604 4.08108 15.59 4.08808L12 5.70108L8.41 4.08808C8.395 4.08108 8.379 4.08008 8.364 4.07408C8.323 4.05808 8.282 4.04708 8.24 4.03608C8.201 4.02608 8.162 4.01708 8.122 4.01308C8.083 4.00808 8.044 4.00708 8.004 4.00708C7.96 4.00708 7.917 4.00808 7.873 4.01308C7.836 4.01708 7.801 4.02608 7.765 4.03508C7.721 4.04608 7.678 4.05808 7.635 4.07408C7.621 4.08008 7.604 4.08108 7.59 4.08808L3.59 5.88608C3.231 6.04708 3 6.40408 3 6.79808V19.0001C3 19.3391 3.172 19.6551 3.457 19.8401C3.621 19.9461 3.81 20.0001 4 20.0001C4.139 20.0001 4.279 19.9711 4.41 19.9121L8 18.2991L11.59 19.9121C11.605 19.9191 11.621 19.9161 11.637 19.9221C11.754 19.9691 11.875 20.0001 12 20.0001C12.125 20.0001 12.246 19.9691 12.363 19.9221C12.378 19.9161 12.396 19.9191 12.41 19.9121L16 18.2991L19.59 19.9121C19.721 19.9711 19.861 20.0001 20 20.0001C20.19 20.0001 20.379 19.9461 20.543 19.8401C20.828 19.6551 21 19.3391 21 19.0001V6.79808C21 6.40408 20.769 6.04708 20.41 5.88608Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="18" height="16">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M19 17.4541L17 16.5561V6.54608L19 7.44408V17.4541ZM13 7.44408L15 6.54608V16.5561L13 17.4541V7.44408ZM9 6.54608L11 7.44408V17.4541L9 16.5561V6.54608ZM5 7.44408L7 6.54608V16.5561L5 17.4541V7.44408ZM20.41 5.88608L16.41 4.08808C16.395 4.08108 16.379 4.08008 16.364 4.07408C16.323 4.05808 16.282 4.04708 16.24 4.03608C16.201 4.02608 16.162 4.01708 16.122 4.01308C16.083 4.00808 16.044 4.00708 16.004 4.00708C15.96 4.00708 15.917 4.00808 15.873 4.01308C15.836 4.01708 15.801 4.02608 15.765 4.03508C15.721 4.04608 15.678 4.05808 15.635 4.07408C15.621 4.08008 15.604 4.08108 15.59 4.08808L12 5.70108L8.41 4.08808C8.395 4.08108 8.379 4.08008 8.364 4.07408C8.323 4.05808 8.282 4.04708 8.24 4.03608C8.201 4.02608 8.162 4.01708 8.122 4.01308C8.083 4.00808 8.044 4.00708 8.004 4.00708C7.96 4.00708 7.917 4.00808 7.873 4.01308C7.836 4.01708 7.801 4.02608 7.765 4.03508C7.721 4.04608 7.678 4.05808 7.635 4.07408C7.621 4.08008 7.604 4.08108 7.59 4.08808L3.59 5.88608C3.231 6.04708 3 6.40408 3 6.79808V19.0001C3 19.3391 3.172 19.6551 3.457 19.8401C3.621 19.9461 3.81 20.0001 4 20.0001C4.139 20.0001 4.279 19.9711 4.41 19.9121L8 18.2991L11.59 19.9121C11.605 19.9191 11.621 19.9161 11.637 19.9221C11.754 19.9691 11.875 20.0001 12 20.0001C12.125 20.0001 12.246 19.9691 12.363 19.9221C12.378 19.9161 12.396 19.9191 12.41 19.9121L16 18.2991L19.59 19.9121C19.721 19.9711 19.861 20.0001 20 20.0001C20.19 20.0001 20.379 19.9461 20.543 19.8401C20.828 19.6551 21 19.3391 21 19.0001V6.79808C21 6.40408 20.769 6.04708 20.41 5.88608Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

// export const ListSvg = () => {
//     return (
//         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <g id="list">
//                 <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd" d="M3 7C3 6.448 3.448 6 4 6C4.552 6 5 6.448 5 7C5 7.552 4.552 8 4 8C3.448 8 3 7.552 3 7ZM3 12C3 11.448 3.448 11 4 11C4.552 11 5 11.448 5 12C5 12.552 4.552 13 4 13C3.448 13 3 12.552 3 12ZM4 16C3.448 16 3 16.448 3 17C3 17.552 3.448 18 4 18C4.552 18 5 17.552 5 17C5 16.448 4.552 16 4 16ZM7.9355 11H20.0645C20.5795 11 21.0005 11.421 21.0005 11.936V12.064C21.0005 12.579 20.5795 13 20.0645 13H7.9355C7.4205 13 7.0005 12.579 7.0005 12.064V11.936C7.0005 11.421 7.4205 11 7.9355 11ZM20.0645 16H7.9355C7.4205 16 7.0005 16.421 7.0005 16.936V17.064C7.0005 17.579 7.4205 18 7.9355 18H20.0645C20.5795 18 21.0005 17.579 21.0005 17.064V16.936C21.0005 16.421 20.5795 16 20.0645 16ZM7.9355 6H20.0645C20.5795 6 21.0005 6.421 21.0005 6.936V7.064C21.0005 7.579 20.5795 8 20.0645 8H7.9355C7.4205 8 7.0005 7.579 7.0005 7.064V6.936C7.0005 6.421 7.4205 6 7.9355 6Z" fill="#2E3A59"/>
//                 <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="6" width="19" height="12">
//                     <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd" d="M3 7C3 6.448 3.448 6 4 6C4.552 6 5 6.448 5 7C5 7.552 4.552 8 4 8C3.448 8 3 7.552 3 7ZM3 12C3 11.448 3.448 11 4 11C4.552 11 5 11.448 5 12C5 12.552 4.552 13 4 13C3.448 13 3 12.552 3 12ZM4 16C3.448 16 3 16.448 3 17C3 17.552 3.448 18 4 18C4.552 18 5 17.552 5 17C5 16.448 4.552 16 4 16ZM7.9355 11H20.0645C20.5795 11 21.0005 11.421 21.0005 11.936V12.064C21.0005 12.579 20.5795 13 20.0645 13H7.9355C7.4205 13 7.0005 12.579 7.0005 12.064V11.936C7.0005 11.421 7.4205 11 7.9355 11ZM20.0645 16H7.9355C7.4205 16 7.0005 16.421 7.0005 16.936V17.064C7.0005 17.579 7.4205 18 7.9355 18H20.0645C20.5795 18 21.0005 17.579 21.0005 17.064V16.936C21.0005 16.421 20.5795 16 20.0645 16ZM7.9355 6H20.0645C20.5795 6 21.0005 6.421 21.0005 6.936V7.064C21.0005 7.579 20.5795 8 20.0645 8H7.9355C7.4205 8 7.0005 7.579 7.0005 7.064V6.936C7.0005 6.421 7.4205 6 7.9355 6Z" fill="white"/>
//                 </mask>
//                 <g mask="url(#mask0)">
//                 </g>
//             </g>
//         </svg>
//     )
// };

export const PrinterSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="printer">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M20 16.333C20 16.701 19.715 17 19.363 17H18.5V15C18.5 13.897 17.603 13 16.5 13H7.5C6.397 13 5.5 13.897 5.5 15V17H4.637C4.285 17 4 16.701 4 16.333V9.667C4 9.299 4.285 9 4.637 9H6H8H16H18H19.363C19.715 9 20 9.299 20 9.667V16.333ZM7.5 19V15H16.5L16.502 19H7.5ZM8.014 5H16V7H8.007L8.014 5ZM19.363 7H18V5C18 3.897 17.178 3 16.167 3H7.833C6.822 3 6 3.897 6 5V7H4.637C3.183 7 2 8.196 2 9.667V16.333C2 17.804 3.183 19 4.637 19H5.5C5.5 20.103 6.397 21 7.5 21H16.5C17.603 21 18.5 20.103 18.5 19H19.363C20.817 19 22 17.804 22 16.333V9.667C22 8.196 20.817 7 19.363 7Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="20" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M20 16.333C20 16.701 19.715 17 19.363 17H18.5V15C18.5 13.897 17.603 13 16.5 13H7.5C6.397 13 5.5 13.897 5.5 15V17H4.637C4.285 17 4 16.701 4 16.333V9.667C4 9.299 4.285 9 4.637 9H6H8H16H18H19.363C19.715 9 20 9.299 20 9.667V16.333ZM7.5 19V15H16.5L16.502 19H7.5ZM8.014 5H16V7H8.007L8.014 5ZM19.363 7H18V5C18 3.897 17.178 3 16.167 3H7.833C6.822 3 6 3.897 6 5V7H4.637C3.183 7 2 8.196 2 9.667V16.333C2 17.804 3.183 19 4.637 19H5.5C5.5 20.103 6.397 21 7.5 21H16.5C17.603 21 18.5 20.103 18.5 19H19.363C20.817 19 22 17.804 22 16.333V9.667C22 8.196 20.817 7 19.363 7Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const BookmarkSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bookmark">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M11.8212 15.5059C11.9902 15.5059 12.1602 15.5489 12.3132 15.6349L17.0002 18.2859V5.33388C17.0002 5.13288 16.8792 4.99988 16.8002 4.99988H7.20024C7.12024 4.99988 7.00024 5.13288 7.00024 5.33388V18.2339L11.3062 15.6489C11.4652 15.5539 11.6432 15.5059 11.8212 15.5059ZM6.00024 20.9999C5.83024 20.9999 5.66024 20.9569 5.50724 20.8699C5.19424 20.6929 5.00024 20.3599 5.00024 19.9999V5.33388C5.00024 4.04688 5.98724 2.99988 7.20024 2.99988H16.8002C18.0132 2.99988 19.0002 4.04688 19.0002 5.33388V19.9999C19.0002 20.3559 18.8112 20.6849 18.5042 20.8639C18.1962 21.0439 17.8182 21.0469 17.5082 20.8709L11.8362 17.6629L6.51424 20.8579C6.35624 20.9519 6.17824 20.9999 6.00024 20.9999Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="15" height="20">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M11.8212 15.5059C11.9902 15.5059 12.1602 15.5489 12.3132 15.6349L17.0002 18.2859V5.33388C17.0002 5.13288 16.8792 4.99988 16.8002 4.99988H7.20024C7.12024 4.99988 7.00024 5.13288 7.00024 5.33388V18.2339L11.3062 15.6489C11.4652 15.5539 11.6432 15.5059 11.8212 15.5059ZM6.00024 20.9999C5.83024 20.9999 5.66024 20.9569 5.50724 20.8699C5.19424 20.6929 5.00024 20.3599 5.00024 19.9999V5.33388C5.00024 4.04688 5.98724 2.99988 7.20024 2.99988H16.8002C18.0132 2.99988 19.0002 4.04688 19.0002 5.33388V19.9999C19.0002 20.3559 18.8112 20.6849 18.5042 20.8639C18.1962 21.0439 17.8182 21.0469 17.5082 20.8709L11.8362 17.6629L6.51424 20.8579C6.35624 20.9519 6.17824 20.9999 6.00024 20.9999Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const LayerSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="layers">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="19">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M17.7603 15.54L12.0003 18.847L6.24033 15.54L7.93433 14.819L11.5023 16.867C11.6563 16.956 11.8283 17 12.0003 17C12.1723 17 12.3433 16.956 12.4983 16.867L16.0663 14.818L17.7603 15.54ZM11.5023 12.867C11.6563 12.956 11.8283 13 12.0003 13C12.1723 13 12.3433 12.956 12.4983 12.867L16.0663 10.818L17.7603 11.54L12.0003 14.847L6.24033 11.54L7.93433 10.818L11.5023 12.867ZM12.0003 5.08701L17.7603 7.54001L12.0003 10.847L6.24033 7.54001L12.0003 5.08701ZM20.9983 11.348C20.9753 10.969 20.7403 10.636 20.3913 10.487L18.2393 9.57101L20.4983 8.27401C20.8273 8.08601 21.0203 7.72701 20.9983 7.34801C20.9753 6.97001 20.7403 6.63601 20.3913 6.48701L12.3913 3.08001C12.1413 2.97301 11.8583 2.97301 11.6083 3.08001L3.60833 6.48701C3.25933 6.63601 3.02433 6.97001 3.00233 7.34801C2.97933 7.72701 3.17333 8.08601 3.50233 8.27401L5.76033 9.57101L3.60833 10.487C3.25933 10.636 3.02433 10.969 3.00233 11.348C2.97933 11.727 3.17333 12.086 3.50233 12.274L5.76033 13.571L3.60833 14.487C3.25933 14.636 3.02433 14.969 3.00233 15.348C2.97933 15.727 3.17333 16.086 3.50233 16.274L11.5023 20.867C11.6563 20.956 11.8283 21 12.0003 21C12.1723 21 12.3433 20.956 12.4983 20.867L20.4983 16.274C20.8273 16.086 21.0203 15.727 20.9983 15.348C20.9753 14.97 20.7403 14.636 20.3923 14.487L18.2403 13.57L20.4983 12.274C20.8273 12.086 21.0203 11.727 20.9983 11.348Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const FunnelSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="funnel">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M10.877 17.457L12.903 18.99V14.437C12.903 14.271 12.945 14.108 13.023 13.962L17.323 6H6.64298L10.765 13.978C10.839 14.12 10.877 14.278 10.877 14.437V17.457ZM13.903 22C13.69 22 13.477 21.932 13.3 21.797L9.27398 18.752C9.02398 18.563 8.87698 18.268 8.87698 17.955V14.681L4.11198 5.459C3.95098 5.149 3.96398 4.778 4.14598 4.48C4.32698 4.182 4.65098 4 4.99998 4H19C19.352 4 19.678 4.185 19.859 4.488C20.039 4.79 20.047 5.165 19.88 5.475L14.903 14.69V21C14.903 21.379 14.689 21.726 14.349 21.895C14.208 21.965 14.055 22 13.903 22Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="17" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M10.877 17.457L12.903 18.99V14.437C12.903 14.271 12.945 14.108 13.023 13.962L17.323 6H6.64298L10.765 13.978C10.839 14.12 10.877 14.278 10.877 14.437V17.457ZM13.903 22C13.69 22 13.477 21.932 13.3 21.797L9.27398 18.752C9.02398 18.563 8.87698 18.268 8.87698 17.955V14.681L4.11198 5.459C3.95098 5.149 3.96398 4.778 4.14598 4.48C4.32698 4.182 4.65098 4 4.99998 4H19C19.352 4 19.678 4.185 19.859 4.488C20.039 4.79 20.047 5.165 19.88 5.475L14.903 14.69V21C14.903 21.379 14.689 21.726 14.349 21.895C14.208 21.965 14.055 22 13.903 22Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const InboxSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="inbox">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M18 19H6C5.449 19 5 18.552 5 18V13H7V16C7 16.553 7.448 17 8 17H16C16.552 17 17 16.553 17 16V13H19V18C19 18.552 18.551 19 18 19ZM8.342 5.553C8.512 5.212 8.855 5 9.236 5H14.764C15.145 5 15.488 5.212 15.658 5.553L18.382 11H16C15.448 11 15 11.447 15 12V15H9V12C9 11.447 8.552 11 8 11H5.618L8.342 5.553ZM20.789 11.342L17.447 4.658C16.936 3.636 15.908 3 14.764 3H9.236C8.092 3 7.064 3.636 6.553 4.658L3.211 11.342C3.073 11.618 3 11.928 3 12.236V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V12.236C21 11.928 20.927 11.618 20.789 11.342Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M18 19H6C5.449 19 5 18.552 5 18V13H7V16C7 16.553 7.448 17 8 17H16C16.552 17 17 16.553 17 16V13H19V18C19 18.552 18.551 19 18 19ZM8.342 5.553C8.512 5.212 8.855 5 9.236 5H14.764C15.145 5 15.488 5.212 15.658 5.553L18.382 11H16C15.448 11 15 11.447 15 12V15H9V12C9 11.447 8.552 11 8 11H5.618L8.342 5.553ZM20.789 11.342L17.447 4.658C16.936 3.636 15.908 3 14.764 3H9.236C8.092 3 7.064 3.636 6.553 4.658L3.211 11.342C3.073 11.618 3 11.928 3 12.236V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V12.236C21 11.928 20.927 11.618 20.789 11.342Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const LogoutSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="log-out">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M8 5C8 5.55 7.55 6 7 6H6V18H7C7.55 18 8 18.45 8 19C8 19.55 7.55 20 7 20H5C4.45 20 4 19.55 4 19V5C4 4.45 4.45 4 5 4H7C7.55 4 8 4.45 8 5ZM18.0039 7.4248L20.8179 11.4248C21.0679 11.7788 21.0599 12.2538 20.7999 12.5998L17.7999 16.5998C17.6039 16.8618 17.3029 16.9998 16.9989 16.9998C16.7909 16.9998 16.5799 16.9348 16.3999 16.7998C15.9579 16.4688 15.8689 15.8418 16.1999 15.4008L18.0009 12.9998H17.9999H9.9999C9.4479 12.9998 8.9999 12.5528 8.9999 11.9998C8.9999 11.4468 9.4479 10.9998 9.9999 10.9998H17.9999C18.0164 10.9998 18.0317 11.0044 18.0472 11.0089C18.0598 11.0127 18.0724 11.0165 18.0859 11.0178L16.3679 8.5748C16.0499 8.1238 16.1589 7.4998 16.6109 7.1818C17.0619 6.8628 17.6859 6.9728 18.0039 7.4248Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="18" height="16">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M8 5C8 5.55 7.55 6 7 6H6V18H7C7.55 18 8 18.45 8 19C8 19.55 7.55 20 7 20H5C4.45 20 4 19.55 4 19V5C4 4.45 4.45 4 5 4H7C7.55 4 8 4.45 8 5ZM18.0039 7.4248L20.8179 11.4248C21.0679 11.7788 21.0599 12.2538 20.7999 12.5998L17.7999 16.5998C17.6039 16.8618 17.3029 16.9998 16.9989 16.9998C16.7909 16.9998 16.5799 16.9348 16.3999 16.7998C15.9579 16.4688 15.8689 15.8418 16.1999 15.4008L18.0009 12.9998H17.9999H9.9999C9.4479 12.9998 8.9999 12.5528 8.9999 11.9998C8.9999 11.4468 9.4479 10.9998 9.9999 10.9998H17.9999C18.0164 10.9998 18.0317 11.0044 18.0472 11.0089C18.0598 11.0127 18.0724 11.0165 18.0859 11.0178L16.3679 8.5748C16.0499 8.1238 16.1589 7.4998 16.6109 7.1818C17.0619 6.8628 17.6859 6.9728 18.0039 7.4248Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const EyeSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="eye">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.173 11.173 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.827 12.827 13.5 12 13.5ZM12 8.50002C10.0701 8.50002 8.50005 10.07 8.50005 12C8.50005 13.93 10.0701 15.5 12 15.5C13.93 15.5 15.5 13.93 15.5 12C15.5 10.07 13.93 8.50002 12 8.50002ZM12.2197 16.9976C7.91375 17.0976 5.10475 13.4146 4.17275 11.9956C5.19875 10.3906 7.78275 7.10462 11.7807 7.00262C16.0697 6.89362 18.8948 10.5856 19.8267 12.0046C18.8018 13.6096 16.2168 16.8956 12.2197 16.9976ZM21.8678 11.5026C21.2297 10.3906 17.7057 4.81662 11.7297 5.00362C6.20175 5.14362 2.98675 10.0136 2.13275 11.5026C1.95575 11.8106 1.95575 12.1896 2.13275 12.4976C2.76175 13.5946 6.16175 18.9996 12.0247 18.9996C12.1067 18.9996 12.1888 18.9986 12.2708 18.9966C17.7977 18.8556 21.0138 13.9866 21.8678 12.4976C22.0438 12.1896 22.0438 11.8106 21.8678 11.5026Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="2" y="4" width="20" height="15">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.173 11.173 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.827 12.827 13.5 12 13.5ZM12 8.50002C10.0701 8.50002 8.50005 10.07 8.50005 12C8.50005 13.93 10.0701 15.5 12 15.5C13.93 15.5 15.5 13.93 15.5 12C15.5 10.07 13.93 8.50002 12 8.50002ZM12.2197 16.9976C7.91375 17.0976 5.10475 13.4146 4.17275 11.9956C5.19875 10.3906 7.78275 7.10462 11.7807 7.00262C16.0697 6.89362 18.8948 10.5856 19.8267 12.0046C18.8018 13.6096 16.2168 16.8956 12.2197 16.9976ZM21.8678 11.5026C21.2297 10.3906 17.7057 4.81662 11.7297 5.00362C6.20175 5.14362 2.98675 10.0136 2.13275 11.5026C1.95575 11.8106 1.95575 12.1896 2.13275 12.4976C2.76175 13.5946 6.16175 18.9996 12.0247 18.9996C12.1067 18.9996 12.1888 18.9986 12.2708 18.9966C17.7977 18.8556 21.0138 13.9866 21.8678 12.4976C22.0438 12.1896 22.0438 11.8106 21.8678 11.5026Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
}

export const EyeOffSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="eye-off">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M21.8675 12.4976C21.4525 13.2206 20.4765 14.7386 18.9545 16.1266L17.5435 14.7156C18.6035 13.7646 19.3705 12.7186 19.8265 12.0046C18.8945 10.5856 16.0715 6.89362 11.7805 7.00262C11.1805 7.01762 10.6175 7.11162 10.0815 7.25362L8.50151 5.67362C9.47351 5.28962 10.5445 5.03362 11.7295 5.00362C17.7045 4.81662 21.2295 10.3906 21.8675 11.5026C22.0435 11.8106 22.0435 12.1896 21.8675 12.4976ZM13.9175 16.7466L15.4975 18.3266C14.5265 18.7106 13.4545 18.9666 12.2705 18.9966C12.1885 18.9986 12.1065 18.9996 12.0245 18.9996C6.16151 18.9996 2.76151 13.5946 2.13251 12.4976C1.95551 12.1896 1.95551 11.8106 2.13251 11.5026C2.54651 10.7796 3.52251 9.26162 5.04451 7.87262L6.45551 9.28462C5.39551 10.2356 4.62951 11.2816 4.17251 11.9956C5.10451 13.4146 7.91451 17.0976 12.2195 16.9976C12.8195 16.9826 13.3825 16.8886 13.9175 16.7466ZM11.9995 13.4996C11.1725 13.4996 10.4995 12.8276 10.4995 11.9996C10.4995 11.9746 10.5105 11.9516 10.5115 11.9256L12.0745 13.4886C12.0485 13.4896 12.0255 13.4996 11.9995 13.4996ZM4.70651 3.29262C4.31651 2.90262 3.68351 2.90262 3.29251 3.29262C2.90251 3.68362 2.90251 4.31662 3.29251 4.70662L8.92351 10.3376C8.64651 10.8456 8.49951 11.4106 8.49951 11.9996C8.49951 13.9296 10.0705 15.4996 11.9995 15.4996C12.5895 15.4996 13.1545 15.3526 13.6625 15.0766L19.2925 20.7066C19.4885 20.9026 19.7435 20.9996 19.9995 20.9996C20.2555 20.9996 20.5115 20.9026 20.7065 20.7066C21.0975 20.3166 21.0975 19.6836 20.7065 19.2926L4.70651 3.29262Z"
                      fill="currentColor"/>
            </g>
        </svg>

    )
};

export const CloseSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="close">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M13.4142 12L17.7072 7.70701C18.0982 7.31601 18.0982 6.68401 17.7072 6.29301C17.3162 5.90201 16.6842 5.90201 16.2933 6.29301L12.0002 10.586L7.70725 6.29301C7.31625 5.90201 6.68425 5.90201 6.29325 6.29301C5.90225 6.68401 5.90225 7.31601 6.29325 7.70701L10.5862 12L6.29325 16.293C5.90225 16.684 5.90225 17.316 6.29325 17.707C6.48825 17.902 6.74425 18 7.00025 18C7.25625 18 7.51225 17.902 7.70725 17.707L12.0002 13.414L16.2933 17.707C16.4882 17.902 16.7443 18 17.0002 18C17.2562 18 17.5122 17.902 17.7072 17.707C18.0982 17.316 18.0982 16.684 17.7072 16.293L13.4142 12Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="6" y="5" width="13" height="13">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M13.4142 12L17.7072 7.70701C18.0982 7.31601 18.0982 6.68401 17.7072 6.29301C17.3162 5.90201 16.6842 5.90201 16.2933 6.29301L12.0002 10.586L7.70725 6.29301C7.31625 5.90201 6.68425 5.90201 6.29325 6.29301C5.90225 6.68401 5.90225 7.31601 6.29325 7.70701L10.5862 12L6.29325 16.293C5.90225 16.684 5.90225 17.316 6.29325 17.707C6.48825 17.902 6.74425 18 7.00025 18C7.25625 18 7.51225 17.902 7.70725 17.707L12.0002 13.414L16.2933 17.707C16.4882 17.902 16.7443 18 17.0002 18C17.2562 18 17.5122 17.902 17.7072 17.707C18.0982 17.316 18.0982 16.684 17.7072 16.293L13.4142 12Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>
    )
};

export const AlertTriangleSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="alert-triangle">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M11 9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13C13 13.552 12.552 14 12 14C11.448 14 11 13.552 11 13V9ZM11 16C11 15.448 11.448 15 12 15C12.552 15 13 15.448 13 16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16ZM20.8647 18.353C20.6357 18.758 20.1897 19 19.6717 19H4.3277C3.8097 19 3.3637 18.758 3.1357 18.353C3.0277 18.163 2.8857 17.776 3.1527 17.336L10.8237 4.618C11.2907 3.844 12.7087 3.844 13.1757 4.618L20.8477 17.336C21.1137 17.776 20.9727 18.163 20.8647 18.353ZM22.5607 16.303L14.8887 3.584C14.2897 2.592 13.2097 2 11.9997 2C10.7897 2 9.7097 2.592 9.1117 3.584L1.4397 16.303C0.870699 17.246 0.853699 18.38 1.3937 19.336C1.9727 20.363 3.0977 21 4.3277 21H19.6717C20.9027 21 22.0267 20.363 22.6067 19.336C23.1467 18.38 23.1297 17.246 22.5607 16.303Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="1" y="2" width="22" height="19">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M11 9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13C13 13.552 12.552 14 12 14C11.448 14 11 13.552 11 13V9ZM11 16C11 15.448 11.448 15 12 15C12.552 15 13 15.448 13 16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16ZM20.8647 18.353C20.6357 18.758 20.1897 19 19.6717 19H4.3277C3.8097 19 3.3637 18.758 3.1357 18.353C3.0277 18.163 2.8857 17.776 3.1527 17.336L10.8237 4.618C11.2907 3.844 12.7087 3.844 13.1757 4.618L20.8477 17.336C21.1137 17.776 20.9727 18.163 20.8647 18.353ZM22.5607 16.303L14.8887 3.584C14.2897 2.592 13.2097 2 11.9997 2C10.7897 2 9.7097 2.592 9.1117 3.584L1.4397 16.303C0.870699 17.246 0.853699 18.38 1.3937 19.336C1.9727 20.363 3.0977 21 4.3277 21H19.6717C20.9027 21 22.0267 20.363 22.6067 19.336C23.1467 18.38 23.1297 17.246 22.5607 16.303Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};


export const DownloadSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="download">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M11.0028 12.0759C11.001 12.0508 11 12.0255 11 12V4C11 3.448 11.447 3 12 3C12.553 3 13 3.448 13 4V11.9998L15.4 10.2C15.842 9.867 16.469 9.958 16.8 10.4C17.132 10.842 17.042 11.469 16.6 11.8L12.6 14.8C12.423 14.933 12.211 15 12 15C11.799 15 11.598 14.939 11.425 14.818L7.425 12.004C6.973 11.686 6.864 11.062 7.182 10.611C7.5 10.159 8.123 10.05 8.575 10.368L11.0028 12.0759ZM6 17V18H18V17C18 16.45 18.45 16 19 16C19.55 16 20 16.45 20 17V19C20 19.55 19.55 20 19 20H5C4.45 20 4 19.55 4 19V17C4 16.45 4.45 16 5 16C5.55 16 6 16.45 6 17Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="4" y="3" width="16" height="17">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M11.0028 12.0759C11.001 12.0508 11 12.0255 11 12V4C11 3.448 11.447 3 12 3C12.553 3 13 3.448 13 4V11.9998L15.4 10.2C15.842 9.867 16.469 9.958 16.8 10.4C17.132 10.842 17.042 11.469 16.6 11.8L12.6 14.8C12.423 14.933 12.211 15 12 15C11.799 15 11.598 14.939 11.425 14.818L7.425 12.004C6.973 11.686 6.864 11.062 7.182 10.611C7.5 10.159 8.123 10.05 8.575 10.368L11.0028 12.0759ZM6 17V18H18V17C18 16.45 18.45 16 19 16C19.55 16 20 16.45 20 17V19C20 19.55 19.55 20 19 20H5C4.45 20 4 19.55 4 19V17C4 16.45 4.45 16 5 16C5.55 16 6 16.45 6 17Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
};

export const StationSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.08594" y="3" width="13.0006" height="13.0006" rx="3" stroke="currentColor" strokeWidth="2"/>
            <mask id="path-3-inside-1" fill="white">
                <rect x="7.72168" y="5.15161" width="7.77807" height="5.55577" rx="1"/>
            </mask>
            <rect x="7.72168" y="5.15161" width="7.77807" height="5.55577" rx="1" stroke="currentColor" strokeWidth="3"
                  mask="url(#path-3-inside-1)"/>
            <circle cx="14.7217" cy="13.2563" r="1" fill="#3366FF"/>
            <circle cx="8.72168" cy="13.2563" r="1" fill="#3366FF"/>
            <line x1="3.8335" y1="18.5967" x2="19.612" y2="18.5967" stroke="currentColor" strokeWidth="2"
                  strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.7139 15.9552L20.6563 21.0416C20.9946 21.4782 21.6228 21.5577 22.0593 21.2194C22.4958 20.8811 22.5754 20.2529 22.237 19.8164L18.2544 14.6781C18.239 14.6582 18.223 14.639 18.2064 14.6206L16.7139 15.9552Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.75391 15.925L2.79075 21.0415C2.45255 21.4781 1.82443 21.5579 1.38781 21.2197C0.951183 20.8815 0.871397 20.2534 1.2096 19.8167L5.1896 14.6785C5.21321 14.648 5.23823 14.6193 5.26451 14.5923L6.75391 15.925Z"
                  fill="currentColor"/>
        </svg>

    )
};

export const CircleSvg = () => {
    return (
        <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.5282" cy="18.894" r="11" transform="rotate(-139.628 18.5282 18.894)" fill="white"
                    stroke="#222B45" strokeWidth="4"/>
        </svg>
    )
};

export const MenuSvg = () => {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="menu">
                <path id="&#240;&#159;&#142;&#168; Icon color" fillRule="evenodd" clipRule="evenodd"
                      d="M3.94824 6H20.0512C20.5732 6 21.0002 6.427 21.0002 6.949V7.051C21.0002 7.573 20.5732 8 20.0512 8H3.94824C3.42624 8 3.00024 7.573 3.00024 7.051V6.949C3.00024 6.427 3.42624 6 3.94824 6ZM20.0512 11H3.94824C3.42624 11 3.00024 11.427 3.00024 11.949V12.051C3.00024 12.573 3.42624 13 3.94824 13H20.0512C20.5732 13 21.0002 12.573 21.0002 12.051V11.949C21.0002 11.427 20.5732 11 20.0512 11ZM20.0512 16H3.94824C3.42624 16 3.00024 16.427 3.00024 16.949V17.051C3.00024 17.573 3.42624 18 3.94824 18H20.0512C20.5732 18 21.0002 17.573 21.0002 17.051V16.949C21.0002 16.427 20.5732 16 20.0512 16Z"
                      fill="currentColor"/>
                <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="3" y="6" width="19" height="12">
                    <path id="&#240;&#159;&#142;&#168; Icon color_2" fillRule="evenodd" clipRule="evenodd"
                          d="M3.94824 6H20.0512C20.5732 6 21.0002 6.427 21.0002 6.949V7.051C21.0002 7.573 20.5732 8 20.0512 8H3.94824C3.42624 8 3.00024 7.573 3.00024 7.051V6.949C3.00024 6.427 3.42624 6 3.94824 6ZM20.0512 11H3.94824C3.42624 11 3.00024 11.427 3.00024 11.949V12.051C3.00024 12.573 3.42624 13 3.94824 13H20.0512C20.5732 13 21.0002 12.573 21.0002 12.051V11.949C21.0002 11.427 20.5732 11 20.0512 11ZM20.0512 16H3.94824C3.42624 16 3.00024 16.427 3.00024 16.949V17.051C3.00024 17.573 3.42624 18 3.94824 18H20.0512C20.5732 18 21.0002 17.573 21.0002 17.051V16.949C21.0002 16.427 20.5732 16 20.0512 16Z"
                          fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                </g>
            </g>
        </svg>

    )
}