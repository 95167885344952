import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Modal} from 'antd';
import {InputSystem} from "./input";
import {
    ModalBody,
    ModalCustom,
    ModalFooter,
    ModalHeader,
    RadioUI,
    Button,
    ButtonBlockFlex,
    ModalFooterWrapper, MiniModalCustom, LargeModalCustom, DropWrapper, CropWrapper, CropModalCustom, DatePickerSystem
} from "../atoms";
import {SelectSystem} from "./select";
import {PlusOutlined} from "@ant-design/icons";
import {createEntryAttributeController, getAttributeInfo, getConstraintsInfo} from "../../features/dashboard/api";
import {$isDataPending, attributeList, sectorInfoMount} from "../../features/dashboard/model";
import {createEntrySectorController} from "../api";
import {withRouter} from "react-router-dom";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import Dropzone from "react-dropzone";
import {
    base64StringtoFile,
    extractImageFileExtensionFromBase64,
    image64toCanvasRef
} from '../../utils/crop-utils'
import ReactCrop from "react-image-crop";
import {AlertTriangleSvg, EditSvg, TrashSvg} from "../../media";
import {CustomTable} from "../../features/dashboard/atoms";
import Moment from "react-moment";
import 'moment/locale/ru';
import {convertKilo} from "../../helpers";
import {useStore} from "effector-react";
import {useUrlParams} from "../../features/dashboard/hooks";
import {timeEvent} from "../../features/dashboard/model/events";


const {confirm} = Modal;

const ModalAttributeView = (props) => {
    const {
        statusModal,
        attributeInfo,
        setStatusModal,
        location,
    } = props;
    //status
    const [loading, setLoading] = useState(false);

    // options
    const [listOptions, setListOptions] = useState([]);

    //data
    const [status, setStatus] = useState(undefined);
    const [fromMeters, setFromMeters] = useState(0);
    const [toMeters, setToMeters] = useState(1000);
    const [listId, setListId] = useState(undefined);
    const [sectorId, setSectorId] = useState(undefined);
    const [sectorPoint, setSectorPoint] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(undefined);
    const [long, setLong] = useState(undefined);
    const [double, setDouble] = useState(undefined);
    const [text, setText] = useState(undefined);

    useEffect(() => {
        if (attributeInfo.type === 'LIST') {
            if (attributeInfo.constraints && attributeInfo.constraints.list) {
                getConstraintsInfo(attributeInfo.constraints.list)
                    .then(response => {
                        if (response.status === 200) {
                            setListOptions(response.data.values)
                        }
                    })
                    .catch(error => {
                        console.error(error.response.data)
                    })
            }
        }
    }, [attributeInfo]);

    useEffect(() => {
        if (location.search) {
            const paramsString = location.search;
            const searchParams = new URLSearchParams(paramsString);
            if (searchParams.has('subSectorId')) {
                setSectorId(searchParams.get('subSectorId'));
            } else {
                setSectorId(searchParams.get('sectorId'));
            }
            if (searchParams.has('sectorPoint')) {
                setSectorPoint(searchParams.get('sectorPoint'));
            }
        }
    }, [location.search]);

    useEffect(() => {
        if (sectorId && sectorPoint && attributeInfo.id) {
            getInfo()
        }

    }, [sectorId, sectorPoint, attributeInfo]);

    const columns = [
        {
            title: "От",
            dataIndex: "from",
            key: "from",
            render: fromCount => convertKilo(fromCount)
        },
        {
            title: "До",
            dataIndex: "to",
            key: "to",
            render: toCount => convertKilo(toCount) === 0 ? 1000 : convertKilo(toCount)
        },
        {
            title: "Значение",
            dataIndex: "value",
            key: "value",
        },
        {
            title: "",
            key: "action",
            width: '100px',
            render: record => (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div onClick={() => {
                        // setAttributeInfo(record);
                        // setStatusModal(true);
                    }} style={{textAlign: "center", marginRight: 15, color: '#8F9BB3'}}>
                        <EditSvg/>
                    </div>
                    <div onClick={() => {
                        // setAttributeInfo(record);
                        // setStatusModal(true);
                    }} style={{textAlign: "center", color: '#FF3D71'}}>
                        <TrashSvg/>
                    </div>
                </div>
            )
        }
    ];

    const getInfo = () => {
        const params = {
            ts: Date.now(),
            // sectorId: sectorId
        };
        setLoading(true);
        getAttributeInfo(
            attributeInfo.id,
            sectorId,
            sectorPoint * 1000,
            sectorPoint * 1000 + 1000,
            params)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    const data = response.data.map((item, index) => ({
                        key: index,
                        from: item.fromMeter,
                        to: item.toMeter,
                        value: item.entry.type === 'DATE' ?
                            <Moment locale='ru' format="DD/MM/YYYY"
                                    date={item.entry.value}/> : item.entry.name ? item.entry.name : item.entry.value
                    }));
                    setData(data);
                    // console.log('entryResponse', response.data, data)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('entryError', error.response)
            })
    };

    const createEntryAttribute = () => {
        const data = {
            color: status,
            fromMeter: Number(sectorPoint) * 1000 + Number(fromMeters),
            toMeter: Number(sectorPoint) * 1000 + Number(toMeters),
            // username: 'Chipxack'
        };
        // if (attributeInfo.type === 'LIST'){
        //     data.entry = listOptions.filter(item => item.value === listId)[0]
        // }
        switch (attributeInfo.type) {
            case 'LIST':
                data.entry = listOptions.filter(item => item.value === listId)[0];
                break;
            case 'DATE':
                data.entry = {
                    value: date,
                    type: 'DATE'
                };
                break;
            case 'LONG':
                data.entry = {
                    value: long,
                    type: 'LONG'
                };
                break;
            case 'DOUBLE':
                data.entry = {
                    value: double,
                    type: 'DOUBLE'
                };
                break;
            case 'TEXT':
                data.entry = {
                    value: text,
                    type: 'TEXT'
                };
                break;
            default:
                return null
        }
        if (sectorId) {
            createEntryAttributeController(attributeInfo.id, sectorId, data)
                .then(response => {
                    if (response.status === 200) {
                        // attributeList(categoryId);
                        getInfo();
                        // if (categoryId){
                        //     console.log('qqqqqq')
                        //     attributeList(categoryId);
                        // } else{
                        //     console.log('eeeeee', isDataPending.$categoriesList.data)
                        //     attributeList(isDataPending.$categoriesList.data.data[0].id);
                        // }
                        timeEvent(Date.now())
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }

    };

    const replaceDouble = (e) => {
        const data = e.replace(/\D/ig, function () {
            let dotCount = 0;
            return function ($0) {
                if ($0 === attributeInfo.constraints.double && !dotCount) {
                    dotCount += 1;
                    return $0;
                }
                return '';
            }
        }());
        setDouble(data)
        // setDouble(data);
    };

    // console.log(attributeInfo)

    return (
        <ModalCustom
            title={attributeInfo.name}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>
                    {attributeInfo.name}
                </h1>
                <div>
                    <span>Состояние</span>
                    <RadioUI.Group onChange={(e) => setStatus(e.target.value)}>
                        <RadioUI status='success' value='rgb(0, 224, 150)'/>
                        <RadioUI status='warning' value='rgba(255, 170, 0)'/>
                        <RadioUI status='error' value='rgba(255, 17, 110'/>
                    </RadioUI.Group>
                </div>

            </ModalHeader>
            <ModalBody>
                <CustomTable
                    style={{margin: '30px 0'}}
                    className='second-color popap'
                    // showHeader={false}
                    title={false}
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                />
            </ModalBody>
            <ModalFooter>
                <Row type='flex' gutter={10}>
                    <Col span={7}>
                        <InputSystem
                            disabled={loading}
                            value={fromMeters}
                            change={setFromMeters}
                            label='От'
                            placeholder='Значение в метрах'
                        />
                    </Col>
                    <Col span={7}>
                        <InputSystem
                            disabled={loading}
                            value={toMeters}
                            change={setToMeters}
                            label='До'
                            placeholder='Значение в метрах'
                        />
                    </Col>

                    <Col span={7}>
                        {
                            attributeInfo.type === 'LIST' ?
                                <SelectSystem
                                    disabled={loading}
                                    options={listOptions}
                                    change={setListId}
                                    label='Значение'
                                    placeholder='Выберите'
                                /> :
                                attributeInfo.type === 'DATE' ?
                                    <InputSystem
                                        disabled={loading}
                                        type='date'
                                        label='Значение'
                                        placeholder='Выберите'
                                        change={setDate}
                                    /> :
                                    attributeInfo.type === 'LONG' ?
                                        <InputSystem
                                            disabled={loading}
                                            value={long}
                                            type='number'
                                            label='Значение'
                                            placeholder='Выберите'
                                            change={setLong}
                                            minLength={0}
                                        /> :
                                        attributeInfo.type === 'DOUBLE' ?
                                            <InputSystem
                                                disabled={loading}
                                                value={double}
                                                type='text'
                                                label='Значение'
                                                placeholder=''
                                                change={replaceDouble}
                                            /> :
                                            attributeInfo.type === 'TEXT' ?
                                                <InputSystem
                                                    disabled={loading}
                                                    value={text}
                                                    type='text'
                                                    label='Значение'
                                                    placeholder=''
                                                    change={setText}
                                                />
                                                : null
                        }
                    </Col>
                    <ButtonBlockFlex>
                        <Button
                            loading={loading}
                            disabled={
                                !status
                                || fromMeters === undefined
                                || !toMeters
                            }
                            onClick={() => createEntryAttribute()}
                            noChildren
                            style={{height: 48, width: 48}}
                        >
                            <PlusOutlined/>
                        </Button>
                    </ButtonBlockFlex>
                </Row>
            </ModalFooter>
        </ModalCustom>
    )
};


const ModalSectorsView = (props) => {
    const {
        statusModal,
        setStatusModal,
        location
    } = props;

    const isDataPending = useStore($isDataPending);

    //data
    const [status, setStatus] = useState(undefined);
    const [fromMeters, setFromMeters] = useState(undefined);
    const [toMeters, setToMeters] = useState(undefined);
    const [velocityFrom, setVelocityFrom] = useState(undefined);
    const [velocityTo, setVelocityTo] = useState(undefined);
    const [tonnage, setTonnage] = useState(undefined);


    // console.log(attributeInfo)
    // const paramsString = location.search;
    // const searchParams = new URLSearchParams(paramsString);
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    // console.log(searchParams.get('subSectorId'))


    useEffect(() => {
        setFromMeters(statusModal);
        setToMeters(statusModal);
    }, [statusModal]);

    // useEffect(() => {
    //     if (searchParams.has('subSectorId') === isDataPending.$sectorInfo.data.startPoint) {
    //         if (fromMeters < isDataPending.$sectorInfo.data.startPoint) {
    //             setFromMeters()
    //         }
    //     }
    // }, [fromMeters]);

    const createEntryAttribute = () => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('subSectorId')) {
            // setParamOrganizationId(searchParams.get('organizationId'));
            const data = {
                color: status,
                fromMeter: Number(fromMeters) * 1000,
                toMeter: Number(toMeters) * 1000 + 999,
                tonnage: tonnage,
                velocityFrom: velocityFrom,
                velocityTo: velocityTo
            };
            // console.log(data)
            createEntrySectorController(searchParams.get('subSectorId'), data)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }
    };

    return (
        <ModalCustom
            title='Сектор'
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>
                    Сектор
                </h1>
                <div>
                    <span>Состояние</span>
                    <RadioUI.Group onChange={(e) => setStatus(e.target.value)}>
                        <RadioUI status='success' value='rgb(0, 224, 150)'/>
                        <RadioUI status='warning' value='rgba(255, 170, 0)'/>
                        <RadioUI status='error' value='rgba(255, 17, 110'/>
                    </RadioUI.Group>
                </div>
            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <Row type='flex' gutter={10}>
                    <Col span={5}>
                        <InputSystem
                            disabled
                            value={fromMeters}
                            change={setFromMeters}
                            label='От'
                            placeholder='Значение в метрах'
                        />
                    </Col>
                    <Col span={5}>
                        <InputSystem
                            value={toMeters}
                            change={setToMeters}
                            label='До'
                            placeholder='Значение в метрах'
                        />
                    </Col>
                    <Col span={7}>
                        <Row type='flex' gutter={10}>
                            <Col span={12}>
                                <InputSystem
                                    value={velocityFrom}
                                    change={setVelocityFrom}
                                    label='КМ/Ч'
                                    placeholder='КМ/Ч'
                                />
                            </Col>
                            <Col span={12}>
                                <InputSystem
                                    value={velocityTo}
                                    change={setVelocityTo}
                                    label=' '
                                    placeholder='КМ/Ч'
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <InputSystem
                            value={tonnage}
                            change={setTonnage}
                            label='Тоннаж'
                            placeholder='Значение в метрах'
                        />
                    </Col>
                    {/*<Col span={7}>*/}
                    {/*    <SelectSystem*/}
                    {/*        options={listOptions}*/}
                    {/*        change={setListId}*/}
                    {/*        label='Значение'*/}
                    {/*        placeholder='Выберите'*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    <ButtonBlockFlex>
                        <Button
                            // disabled={
                            //     !status
                            //     || !fromMeters
                            //     || !toMeters
                            // }
                            onClick={() => createEntryAttribute()}
                            noChildren
                            style={{height: 56, width: 56}}
                        >
                            <PlusOutlined/>
                        </Button>
                    </ButtonBlockFlex>
                </Row>
            </ModalFooter>
        </ModalCustom>
    )
};

export const ModalTariff = (props) => {
    const {
        statusModal,
        setStatusModal,
        title,

    } = props;

    return (
        <ModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>Выберите карту</h1>
            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <ModalFooterWrapper>
                    <Button
                        type='link'
                        onClick={() => setStatusModal(false)}
                    >
                        Отменить
                    </Button>
                    <Button>
                        Оплатить
                    </Button>
                </ModalFooterWrapper>
            </ModalFooter>
        </ModalCustom>
    )
};

export const UserProfileModal = (props) => {
    const {
        component,
        statusModal,
        setStatusModal,
        title
    } = props;

    return (
        <MiniModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalBody style={{minHeight: 'unset'}}>
                {component}
            </ModalBody>
            {/*<ModalFooter>*/}
            {/*    <ModalFooterWrapper>*/}
            {/*        <Button*/}
            {/*              type='link'*/}
            {/*              onClick={() => setStatusModal(false)}*/}
            {/*        >*/}
            {/*            Отменить*/}
            {/*        </Button>*/}
            {/*        <Button>*/}
            {/*            Сохранить*/}
            {/*        </Button>*/}
            {/*    </ModalFooterWrapper>*/}
            {/*</ModalFooter>*/}
        </MiniModalCustom>
    )
};

export const UserListModal = (props) => {
    const {
        statusModal,
        setStatusModal,
        title,
        component
    } = props


    return (
        <LargeModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalBody style={{minHeight: 'unset'}}>
                {component}
            </ModalBody>
        </LargeModalCustom>
    )
}

export const deleteConfirm = (action,) => {
    confirm({
        title: 'Дейтвительно хотите удалить?',
        icon: <ExclamationCircleOutlined/>,
        okText: 'Да',
        okType: 'danger',
        cancelText: 'Нет',
        onOk() {
            action()
        },
        onCancel() {
        },
    });
}

export const ImageUploadModal = (props) => {

    const {statusModal, setStatusModal, title, setFileValue} = props;
    const [imgSrc, setImgSrc] = useState(null)
    const [imgSrcExt, setImgSrcExt] = useState(null)
    const [crop, setCrop] = useState({unit: '%', aspect: 1 / 1, width: 50})
    const [error, setError] = useState(null)

    const imagePreviewCanvasRef = useRef(null)

    const imageMaxSize = 500000 // bytes
    const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
    const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
        return item.trim()
    });

    const verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0].file !== undefined ? files[0].file : files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;

            // console.log(currentFile);
            if (currentFileSize > imageMaxSize) {
                // alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                setError('максимум размер изображение 300x300');
                return false

            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                setError('Этот файл не разрешен. Разрешены только изображения');
                return false
            }
            return true
        }
    };


    const handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = verifyFile(files)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.onload = (e) => {
                    const myResult = e.target.result
                    setImgSrc(myResult)
                    setImgSrcExt(extractImageFileExtensionFromBase64(myResult))
                    return myResult
                }

                myFileItemReader.readAsDataURL(currentFile)
            }
        }

    }


    const handleOnCropComplete = (crop, pixelCrop) => {
        const canvasRef = imagePreviewCanvasRef.current
        image64toCanvasRef(canvasRef, imgSrc, crop)

    }

    const handleOnCropChange = (crop) => {
        setCrop(crop)
    }

    const createCroppedImage = (e) => {
        e.preventDefault()
        if (imgSrc) {
            const canvasRef = imagePreviewCanvasRef.current
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)
            const myFilename = "previewFile." + imgSrcExt
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            setFileValue(myNewCroppedFile)
            setStatusModal(false)
            handleClearToDefault()
            // downloadBase64File(imageData64, myFilename)
        }
    }

    const handleClearToDefault = () => {
        setImgSrc(null)
        setImgSrcExt(null)
        setCrop({unit: '%', aspect: 1 / 1, width: 50})
        setStatusModal(false)
        setError(null)
    }

    return (
        <CropModalCustom
            title={title}
            visible={statusModal}
            headStyle={{display: 'none'}}
            footer={null}
            onCancel={() => setStatusModal(false)}
        >
            <ModalHeader>
                <h1>{title}</h1>
            </ModalHeader>
            <ModalBody>
                {imgSrc !== null
                    ?
                    <CropWrapper>
                        <ReactCrop
                            src={imgSrc}
                            crop={crop}
                            onComplete={handleOnCropComplete}
                            onChange={handleOnCropChange}
                        />
                        <canvas style={{display: 'none'}} ref={imagePreviewCanvasRef}>
                        </canvas>
                    </CropWrapper>
                    :
                    <Dropzone onDrop={handleOnDrop} accept={acceptedFileTypes} multiple={false}
                              maxSize={imageMaxSize}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <DropWrapper {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div>Перетащите файл сюда</div>
                                    <span>или</span>
                                    <Button style={{marginBottom: 10}}>Выберите локальный файл</Button>
                                    {error !== null &&
                                    <strong>
                                        <AlertTriangleSvg/>
                                        {error}
                                    </strong>
                                    }
                                </DropWrapper>
                            </section>
                        )}
                    </Dropzone>

                }

            </ModalBody>
            <ModalFooter>
                <ModalFooterWrapper>
                    <Button
                        type='link'
                        onClick={handleClearToDefault}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={createCroppedImage}
                    >
                        Применить
                    </Button>

                </ModalFooterWrapper>
            </ModalFooter>
        </CropModalCustom>
    )
};

export const ModalAttribute = withRouter(ModalAttributeView);
export const ModalSectors = withRouter(ModalSectorsView);
