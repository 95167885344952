import React, { useEffect, useState } from 'react'
import { useStore } from "effector-react";
import { Link } from 'react-router-dom';
import { $isDataPending, sectorPageMount, positionPageSizeChange } from "../model";
import { AntTable, Container } from "../../../ui/atoms";
import { useHistory } from "react-router-dom";
import { PopoverItem } from "../moleculas";

export const CategoriesList = ({ search }) => {
    const isDataPending = useStore($isDataPending);
    const { location, push } = useHistory()
    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(isDataPending.$positionSettings.position.pageSize ? isDataPending.$positionSettings.position.pageSize : 20);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }
    }, [location.search, pageSize])


    function changePagination(page) {
        if (page !== 1) {
            push({
                pathname: '/positions',
                search: `page=${page}`
            })
        } else {
            push('/positions')
        }
    }

    function changeShowSize(current, size) {
        setPageSize(size)
        const payload = { pageSize: size }
        positionPageSizeChange(payload)
    }


    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (id, data) => (<Link to={`/positions/${data.id}`}>{id}</Link>)
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: (id, data) => (<PopoverItem updateList={updateList} id={data.id} />)
        }
    ];


    useEffect(() => {
        if (search !== null) {
            const params = {
                length: pageSize,
                start: start,
                'search.value': search,
            };
            sectorPageMount(params)
        }
    }, [pageSize, start, search]);

    const updateList = () => {
        const params = {
            length: pageSize,
            start: start,
            'search.value': search,
        };
        sectorPageMount(params)
    }

    return (
        <Container>
            <AntTable
                loading={isDataPending.$positions.loading}
                columns={columns}
                dataSource={isDataPending.$positions.filtered}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: isDataPending.$positions.data.recordsTotal,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true
                    }
                }
            />
        </Container>
    )
};
