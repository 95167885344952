import React, { Fragment } from 'react';
import {BodyHead} from "../../components/body-head";
// import {SectorsFormBlock} from "../sectors-add/templates";
import {SectorsAddSection} from "../sectors-add/atoms";
import {withRouter} from "react-router-dom";
import {SectorSubFormBlock} from "./templates";


const SectorSubAddView = (props) => {
    const {
        match
    } = props;
    return (
        <Fragment>
            <BodyHead goback={true} back={`sections/${match.params.id}/sub`} title='Добавить перегон' />
            <SectorSubFormBlock/>
        </Fragment>
    )
};

export const SectorSubAdd = withRouter(SectorSubAddView);
