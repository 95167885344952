import React, { useEffect, useState } from 'react';
import { Button, ButtonCenter, FormSection, GridBasic, GridItem, Container } from "../../../ui/atoms";
import { InputSystem } from "../../../ui/molecules";
import { setCreateCategory, getCategoryInfo } from "../api";
import { withRouter } from "react-router-dom";
import { notification } from "antd";

const CategoriesFormView = (props) => {
    const {
        match,
        history
    } = props;

    const [name, setName] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const CreateCategories = () => {
        let data = {
            name: name,
        };
        if (match.params.id) {
            data = { ...data, id: match.params.id };
        }
        setLoading(true);

        setCreateCategory(data)
            .then(response => {
                if (response.status === 200) {
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Категория добавлена!'
                    });

                    setName('');
                    setLoading(false);
                    if (match.params.id) {
                        history.push('/categories')
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.log(error.response)
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка при создание, попробуйте позже'
                });
            })
    };

    useEffect(() => {
        if (match.params.id) {
            getCategoryInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        setName(response.data.name);
                    }
                })
                .catch(error => console.log(error.response))
        }
    }, [match.params.id]);

    return (
        <Container>
            <FormSection>
                <GridBasic>
                    <GridItem>
                        <InputSystem
                            value={name}
                            disabled={loading}
                            change={setName}
                            type='text'
                            placeholder='Введите название категории'
                            label='Название'
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button type='link'>Отмена</Button>
                    <Button
                        disabled={
                            !name || loading
                        }
                        onClick={() => CreateCategories()}>Добавить</Button>
                </ButtonCenter>
            </FormSection>
        </Container>
    )
};

export const CategoriesForm = withRouter(CategoriesFormView);
