import React, {Fragment, useEffect, useState} from 'react'
import {GetRecource} from "../api";
import {DownloadSvg} from "../../../media";

export const DownloadDoc = ({fileId, fileName}) => {

    const [fileUrl, setFileUrl] = useState(undefined)

    useEffect(() => {
        if (fileId !== null) {
            GetRecource(fileId)
                .then(res => {
                    if (res.status === 200) {
                        const data = res.data;
                        const urlCreator = window.URL || window.webkitURL;
                        const url = urlCreator.createObjectURL(data);
                        setFileUrl(url)

                    }
                }).catch(error => {
                console.log(error.response)
            })
        }
    }, [fileId])

    return (
        <Fragment>
            {fileUrl && <a href={`${fileUrl}`} download={fileName}>
                <DownloadSvg/>
            </a>}
        </Fragment>
    )
}