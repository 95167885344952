import React from 'react';
import {Switch, Route} from "react-router-dom";
import {UserInfo, UsersAdd, UsersEdit, UsersPage} from "./temlpates";


export const Users = () => {
    return (
        <Switch>
            <Route
                exact
                path='/users'
                component={UsersPage}
            />
            <Route
                exact
                path='/users/add'
                component={UsersAdd}
            />
            <Route
                exact
                path='/users/:id'
                component={UserInfo}
            />
            <Route
                exact
                path='/users/:id/edit'
                component={UsersEdit}
            />
        </Switch>
    )
};
