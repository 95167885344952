import React, { Fragment } from 'react'
import { BodyHead } from "../../../components/body-head";
import { RequestSection } from "../atoms";
import { fakeData } from "../helper";
import { useFilter } from "../hook";
import { RequestContent } from "../organisms";
import { RequestFilter } from "../organisms";

export const RequestPage = () => {
    const { filter, data, sort } = useFilter(fakeData)

    return (
        <Fragment>
            <BodyHead title='Запросы'>
                <RequestFilter sort={sort} filterText={filter.text} />
            </BodyHead>
            <RequestContent data={data} />
        </Fragment>
    )
}
