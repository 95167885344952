import React, { useEffect, useState } from 'react'
import { useStore } from "effector-react";
import { Link } from 'react-router-dom';
import { $isDataPending, sectorPageMount, sectorPageSizeChange } from "../model";
import { AntTable, Container } from "../../../ui/atoms";
import { PopoverItem } from "../molecules";
import { useHistory } from "react-router-dom";
// import {columns} from "../helpers";


// import {MoreButton} from "../../users/atoms";

export const CategoriesList = (props) => {
    const { location, push } = useHistory()
    const { search, sortValue } = props;
    const isDataPending = useStore($isDataPending);
    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(isDataPending.$sectorSettings.sector.pageSize ? isDataPending.$sectorSettings.sector.pageSize : 20);
    const [page, setPage] = useState(1);


    useEffect(() => {
        if (search !== null) {
            const params = {
                length: pageSize,
                start: start,
                "search.value": search,
                sort: sortValue.id
            };
            sectorPageMount(params);
        }
    }, [pageSize, start, search, sortValue]);

    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }
    }, [location.search, pageSize])

    function changePagination(page) {
        if (page !== 1) {
            push({
                pathname: '/sections',
                search: `page=${page}`
            })
        } else {
            push('/sections')
        }
    }

    function changeShowSize(current, size) {
        setPageSize(size)

        const payload = { pageSize: size }
        sectorPageSizeChange(payload);
    }

    function updateList() {
        const params = {
            length: pageSize,
            start: start,
            "search.value": search,
            sort: sortValue.id
        };
        sectorPageMount(params);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            render: id => (<Link to={`/sections/${id}/sub`}>{id}</Link>)
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Длина',
            dataIndex: 'length',
            key: 'length',
            width: 90,
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Стыковка',
            dataIndex: 'qwerty',
            key: 'qwerty',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Начало (км)',
            dataIndex: 'startPoint',
            key: 'startPoint',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: 'Отсчёт (м)',
            dataIndex: 'countdownPoint',
            key: 'countdownPoint',
            render: props => (<Link to={`/sections/${props.id}/sub`}>{props.name}</Link>)
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: id => (<PopoverItem updateList={updateList} id={id} />)
        }
    ];

    return (
        <Container>
            <AntTable
                loading={isDataPending.$sectors.loading}
                columns={columns}
                dataSource={isDataPending.$sectors.data.data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: isDataPending.$sectors.data.recordsFiltered,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true,
                        showLessItems: true
                    }
                }
            />
        </Container>
    )
};
