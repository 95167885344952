import React from 'react';
import {Switch, withRouter, Route} from "react-router-dom";
import {SubCategories} from "./template";

const SubCategoriesPageView = () => {


    return (
       <Switch>
           <Route
               exact
               path='/categories/:id'
               component={SubCategories}
           />
           <Route
               exact
               path='/categories/:id/add'
               component={SubCategories}
           />
       </Switch>
    )
};

export const SubCategoriesPage = withRouter(SubCategoriesPageView);
