import styled from "styled-components";
import { Slider } from "antd";
import { Button } from "../../../ui/atoms";

export const TariffSection = styled.div`
    margin: 0 24px;
    padding: 40px 24px;
    background-color: ${({ theme }) => theme.main.semanticColor.basic[100]};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.main.semanticColor.basic[300]};
`

export const TariffHeading = styled.div`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 32px;
`

export const TariffRange = styled(Slider)`

    .ant-slider-rail{
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.main.semanticColor.basic[400]} !important;
        @media (max-width: 1440px) {
            height: 6px;
            border-radius: 3px;
        }
    }
    
    .ant-slider-track{
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.main.semanticColor.primary[500]};
        @media (max-width: 1440px) {
            height: 6px;
            border-radius: 3px;
        }
    }
    
    .ant-slider-dot{
        width: 8px;
        height: 32px;
        background-color: ${({ theme }) => theme.main.semanticColor.basic[400]};
        border-color: ${({ theme }) => theme.main.semanticColor.basic[400]};
        border-radius: 4px;
        top: 0;

        @media (max-width: 1440px) {
            width: 6px;
            height: 24px;
            border-radius: 3px;
        }
    }
    
    
    &.ant-slider:hover .ant-slider-track {
        background-color:  ${({ theme }) => theme.main.semanticColor.primary[500]}
    }
    
    .ant-slider-step {
        height: 8px
    }
    
    .ant-slider-handle {
        width: 24px;
        height: 24px;
        margin-top: -8px;
        border: 8px solid ${({ theme }) => theme.main.semanticColor.primary[500]} !important;
        @media (max-width: 1440px) {
            width: 18px;
            height: 18px;
            margin-top: -6px;
            border: 6px solid ${({ theme }) => theme.main.semanticColor.primary[500]} !important;
        }
    }
    
    .ant-slider-handle::before{
        content: '';
        width: 40px;
        height: 40px;
        border: 1px solid ${({ theme }) => theme.main.semanticColor.primary[500]};
        display: block;
        border-radius: 50%;
        transform: translateX(-41%);
        margin-top: -16px;
        @media (max-width: 1440px) {
            width: 27px;
            height: 27px;
            transform: translateX(-40%);
            margin-top: -11px;
        }
    }
    
    .ant-slider-dot-active{
        background-color: ${({ theme }) => theme.main.semanticColor.primary[500]};
        border-color: ${({ theme }) => theme.main.semanticColor.primary[500]};
    }
    
    .ant-slider-mark {
        top: 36px;
    }
    
    .ant-slider-mark-text {
        font-size: 14px;
        color: ${({ theme }) => theme.main.semanticColor.basic[800]} !important;
        font-weight: 600;
        line-height: 24px
    }
`

export const TariffItemWrapper = styled.div`
    margin-bottom: 100px;
    @media (max-width: 1440px) {
        margin-bottom: 65px;
    }
    
`

export const TariffItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.main.semanticColor.basic[800]};
  margin-bottom: 24px  
`

export const TariffCalculateWrapper = styled.div`
        
`

export const TariffCalculateItem = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
    padding: 15px 0
    
    span {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.main.semanticColor.basic[600]}
    }
    
    span:last-child{
        font-weight: 600;
        color: ${({ theme }) => theme.main.semanticColor.basic[800]}
    }
`

