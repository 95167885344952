import React, { Fragment } from 'react';
import {BodyHead} from "../../components/body-head";
import {SectorsFormBlock} from "../position-add/templates";
import {SectorsAddSection} from "../position-add/atoms";

export const PositionsAdd = () => {
    return (
        <Fragment>
            <BodyHead back='positions' title='Добавить должность' />
            <SectorsFormBlock/>
        </Fragment>
    )
};
