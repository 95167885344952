import { createEffect } from 'effector';
import { getSubCategoriesList } from '../api';
import {getListCategoriesApi} from "../../categories-sub-add/api";

export const getSubCategories = createEffect({
    handler: getSubCategoriesList,
});

export const getListCategories = createEffect({
    handler: getListCategoriesApi,
});
