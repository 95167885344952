import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";


import {getSectorInfo} from "../../sectors-sub/api";
import {CategoriesSection} from "../atoms";
import {BodyHead} from "../../../components/body-head";
import {Button} from "../../../ui/atoms";
import {PlusSvg} from "../../../media";
import {CategoriesSubList} from "../../sectors-sub/templates";


const SectorsSubView = (props) => {
    const {
        history,
        match
    } = props;

    //data
    const [sectorInfo, setSectorInfo] = useState(false);

    console.log(props);

    useEffect(() => {
        getSectorInfo(match.params.id)
            .then(response => {
                if (response.status === 200) {
                    setSectorInfo(response.data);
                }

            })
            .catch(error => {
                console.error(error.response);
            })
    }, [match.params.id]);

    // console.log(sectorInfo)

    return (
        <CategoriesSection>
            <BodyHead
                back='sections'
                title={
                    sectorInfo ? sectorInfo.name : match.params.id
                }
            >
                <div>
                    <Button
                        onClick={() => history.push(`/sections/${match.params.id}/sub/add`)}><PlusSvg/>Добавить</Button>
                </div>
            </BodyHead>
            <CategoriesSubList/>
        </CategoriesSection>
    )
};

export const SectorsSub = withRouter(SectorsSubView);