import {httpGet, httpPut} from "../../api";


export const createSectordApi = (data) => httpPut({
    url: '/sector', data
});
export const getSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});
export const getSectorsList = () => httpGet({
    url: '/sector/list'
});
export const getSectorListOptions = () => httpGet({
    url: '/sector/plainlist'
});
