import React, { useEffect, useState, Fragment } from 'react'
import { BodyHead } from "../../../components/body-head";
import { withRouter } from 'react-router-dom'
import { getSectorInfo } from "../api";
import { FilterSelect } from "../../users/atoms";
import { Button, Container, GridColumn } from "../../../ui/atoms";
import { PlusSvg, SectorsSvg, StationSvg } from "../../../media";
import { DropdownView } from "../../../ui/molecules";
import { StationList } from "../organisms";

const StationsView = (props) => {
    const { match, history } = props
    const [sectorInfo, setSectorInfo] = useState(false);

    useEffect(() => {
        getSectorInfo(match.params.id)
            .then(response => {
                if (response.status === 200) {
                    setSectorInfo(response.data);
                }

            })
            .catch(error => {
                console.error(error.response);
            })
    }, [match.params.id]);

    const addMenuData = [
        {
            id: 'addSub',
            label: 'Перегон',
            action: () => history.push(`/sections/${match.params.id}/sub/add`)
        },
        {
            id: 'addSection',
            label: 'Станция',
            action: () => history.push(`/sections/${match.params.id}/stations/add`)
        }
    ]

    return (
        <Fragment>
            <BodyHead goBack={false} back={`sections/${match.params.id}/sub`} title='Перегон'>
                <GridColumn column={2} gap={40}>
                    <FilterSelect column={2} gap={40}>
                        <Button type='link'
                            onClick={() => history.push(`/sections/${match.params.id}/sub`)}>
                            <SectorsSvg /> Перегон
                        </Button>
                        <Button type='link'
                            active='true'
                            onClick={() => history.push(`/sections/${match.params.id}/stations`)}>
                            <StationSvg /> Станции
                        </Button>
                    </FilterSelect>
                    <DropdownView menuData={addMenuData}>
                        <Button>
                            <PlusSvg />Добавить
                        </Button>
                    </DropdownView>
                </GridColumn>
            </BodyHead>
            <StationList />
        </Fragment>
    )
}

export const Stations = withRouter(StationsView)