import React from 'react';
import { useLogs } from "../hook";
import { fakeLogsDate } from "../helper";
import { withRouter } from 'react-router-dom'
import { AntTable, Container } from "../../../ui/atoms";

const LogsContentView = (props) => {
    const { location } = props

    const { data } = useLogs(location, fakeLogsDate)

    const columns = [
        {
            title: 'Раздел',
            dataIndex: 'section',
            key: 'section',
            width: 150,
            render: (section) => (<span>{section}</span>)
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            key: 'action',
            render: (action) => (<span>{action}</span>)
        },
        {
            title: 'Пользователь',
            dataIndex: 'user',
            key: 'user',
            width: 200,
            render: (user) => (<span>{user}</span>)
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            width: 70,
            render: (date) => (<span>{date}</span>)
        },
    ];

    return (
        <Container>
            <AntTable
                columns={columns}
                dataSource={data}
            />
        </Container>
    )
};

export const LogsContent = withRouter(LogsContentView)
