import React from 'react';
import { CatgeriesListAddSection } from "./atoms";
import { BodyHead } from "../../components/body-head";
import { AddListCategories } from "./templates";
import { withRouter } from "react-router-dom";
import { Container } from '../../ui/atoms';

const CategoriesListAddView = (props) => {
    const {
        match
    } = props;


    return (
        <Container>
            <BodyHead back={`categories/${match.params.id}/list`} title='Добавить список' />
            <AddListCategories />
        </Container>
    )
};
export const CategoriesListAdd = withRouter(CategoriesListAddView);
