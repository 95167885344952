import {Menu} from "antd";
import React, {useEffect, useState} from "react";

export const FILTER_CONSTANT = {
    ALL: -1,
    WAITING: 0,
    SUCCESS: 1,
    REJECT: 2
}

const sortData = [
    {
        label: 'все',
        id: FILTER_CONSTANT.ALL,
    },
    {
        label: 'ждет обработки',
        id: FILTER_CONSTANT.WAITING,
    },
    {
        label: 'одобрено',
        id: FILTER_CONSTANT.SUCCESS
    },
    {
        label: 'отказано',
        id: FILTER_CONSTANT.REJECT
    },
]

export const useFilter = (arr) => {

    const [filter, setFilter] = useState({type: FILTER_CONSTANT.ALL, text: 'все'})
    const [data, setData] = useState([])
    const filterData = (_arr, type) => {
        if (type === FILTER_CONSTANT.ALL) {
            return _arr
        } else {
            return _arr.filter(item => item.type === type)
        }
    }

    const sort = (
          <Menu>
              {sortData.map(item => {
                  return (
                        <Menu.Item
                              key={item.id} onClick={() => setFilter({...filter, type: item.id, text: item.label})}
                        >
                            {item.label}
                        </Menu.Item>
                  )
              })}
          </Menu>
    )

    useEffect(() => {
        setData(filterData(arr, filter.type))

    }, [arr, filter.type])

    return {sort, filter, data}
}
