import React from 'react';
import {Switch, Route} from "react-router-dom";
import {
    CategoriesMainPage,
    CategoriesAdd
} from "./templates";
import {SubCategoriesPage} from "../categories-sub";
import {SubCategoriesAdd} from "../categories-sub-add";
import {CategoriesList} from "../categories-list";
import {CategoriesListAdd} from "../categories-list-add";
import {CategoriesListEdit} from "../categories-list-edit";
import {SubCategoriesEdit} from "../category-sub-edit";

export const CategoriesPage = () => {

    return (
       <Switch>
           <Route exact path='/categories' component={CategoriesMainPage} />
           <Route exact path='/categories/add' component={CategoriesAdd} />
           <Route exact path='/categories/:id' component={ SubCategoriesPage } />
           <Route exact path='/categories/:id/edit' component={CategoriesAdd} />
           <Route exact path='/categories/:id/add' component={ SubCategoriesAdd } />
           <Route exact path='/categories/:id/list' component={CategoriesList} />
           <Route exact path='/categories/:id/list/add' component={ CategoriesListAdd } />
           <Route exact path='/categories/:id/list/:sub' component={CategoriesListEdit} />
           <Route exact path='/categories/:id/:sub/edit' component={ SubCategoriesEdit } />
       </Switch>
    )
};
