import styled from 'styled-components';
import { NavLink } from 'react-router-dom';



export const CustomNavLink = styled(NavLink)`
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #8F9BB3;
  align-items: center;
  svg{
    margin-right: 3px;
  }
  &.active{
    color: #222B45;
    svg{
      color: #3366FF;
    }
  }
`;
