import {useCallback} from 'react'
import {updateMe, updatePassword} from "../../components/header/api";
import {notification} from "antd";
import {useUserProfile} from "./user-profile-view";
import {signedUserInfoMount} from '../../models/user-model'

export const useUserProfileEdit = () => {
    const {setStatusModal} = useUserProfile();

    const updateUserInfo = useCallback((data) => {
        updateMe(data)
            .then(res => {
                if (res.status === 200) {
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Ваш запрос переден в обработку!'
                    });
                    setStatusModal(false);
                    signedUserInfoMount()
                }
            })
            .catch(err => {
                console.log(err);
                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка, попробуйте позже'
                });
            })

    }, [setStatusModal]);

    const changeUserPassword = (data) => {
        updatePassword(data)
            .then(res => {
                console.log(res);
            }).catch(err => {
            console.log(err);
        })
    };

    return {updateUserInfo, changeUserPassword}
};