import React, {useState, useRef, useEffect} from 'react';
import {UploadFileBlock, UploadFileWrap} from "../atomic";
import {Button} from "../../../ui/atoms";
import {AttachItalicSvg} from "../../../media";
import {UploadFiles} from "../api";
import {DownloadDoc} from "../moleculas";

export const UploadFile = (props) => {
    const {
        change,
        value,
        label
    } = props;

    const [filesIds, setFileIds] = useState([]);
    const [fileName] = useState(`${Date.now()}.xlsx`)
    const uploadInput = useRef(null);

    useEffect(() => {
        if (value && value.length > 0) {
            setFileIds(value)
        }
    }, [value])


    function onChange(e) {
        let data = new FormData();
        data.append('file', e.target.files[0]);

        UploadFiles(data)
            .then(response => {
                if (response.status === 200) {
                    setFileIds([response.data]);
                    change([response.data])
                }
            })
            .catch(error => console.log(error.response))
    }

    return (
        <UploadFileBlock>
            {label && <label>{label}</label>}
            <Button
                onClick={() => {
                    uploadInput.current && uploadInput.current.click()
                }}
            >
                <AttachItalicSvg/>
                Файл
            </Button>
            <UploadFileWrap>
                <div>{filesIds.length > 0 ? <strong>{fileName}</strong> : <span>Выберите файл</span>}</div>
                <DownloadDoc fileId={filesIds.length > 0 ? filesIds[0] : null} fileName={fileName}/>
            </UploadFileWrap>
            <input ref={uploadInput} onChange={(e) => onChange(e)} hidden type='file'/>
        </UploadFileBlock>
    )
};
