import styled from 'styled-components';


export const BodyHeadSection = styled.div`
  background-color: ${({theme}) =>  theme.main.semanticColor.basic[200]}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: calc(100% - 296px);
  position: fixed;
  top: 80px;
  z-index: 20;
  height: 104px;
    
  svg{
    margin-right: 8px;
    
    &:hover{
      cursor: pointer;
    }
  }
  
  @media (max-width: 1440px) {
    padding: 16px;
    width: calc(100% - 220px);
    top: 50px;
    height: 70px;

      svg {
      transform: scale(.9);
    }
  }
  
  @media (max-width: 1280px) {
    width: 100%;
  }
`;
