import React, { Fragment } from 'react';
import { BodyHead } from "../../components/body-head";
import { AddListCategories } from "./templates";
import { withRouter } from "react-router-dom";

const CategoriesListAddView = (props) => {
    const {
        match
    } = props;

    return (
        <Fragment>
            <BodyHead back={`categories/${match.params.id}/list`} title='Добавить список' />
            <AddListCategories />
        </Fragment>
    )
};
export const CategoriesListEdit = withRouter(CategoriesListAddView);
