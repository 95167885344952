import React, {useState} from 'react';
import {attributeDelete} from "../api";
import Popover from "react-tiny-popover";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {EditSvg, MoreHorizontalSvg, TrashSvg} from "../../../media";
import {withRouter} from "react-router-dom";
import {notification} from "antd";
import {deleteConfirm} from "../../../ui/molecules";


const PopoverItemView = (props) => {
    const {
        id,
        match,
        history,
        updateList
    } = props;

    // console.log('dddd', id)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const deleteAttribute = () => {
        attributeDelete(id)
              .then(response => {
                  if (response.status === 200) {
                      updateList();
                      notification['success']({
                          message: 'Успешно!',
                          description: 'Подкатегория удалена!'
                      });
                  }
              })
              .catch(error => {
                  console.error(error.response.data);
                  notification['error']({
                      message: 'Ошибка!',
                      description: 'Ошибка при удалении, попробуйте позже'
                  });
              })
    }

    return (
          <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                position={'bottom'} // preferred position
                content={(
                      <PopConfirmContent>
                    <span onClick={() => history.push(`/categories/${match.params.id}/${id}/edit`)}>
                    <EditSvg/>
                        Редактировать
                    </span>
                          <span className='delete' onClick={() => deleteConfirm(deleteAttribute)}>
                              <TrashSvg/>
                        Удалить
                    </span>
                      </PopConfirmContent>
                )}
          >
              <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  <MoreHorizontalSvg/>
              </div>
          </Popover>
    )
};

export const PopoverItem = withRouter(PopoverItemView);
