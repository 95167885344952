import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {Switch, Route} from 'react-router-dom';
import {CategoriesSection} from "../categories/atoms";
import {Button, GridColumn} from "../../ui/atoms";
import {Edit2Svg, PeopleSvg, PlusSvg, SectorsSvg} from "../../media";
import {BodyHead} from "../../components/body-head";
import {CustomNavLink} from "./atoms";
import {OrganizationSectors, OrganizationUsers} from "./template";
import {getOrganizationInfo} from "./api";


const OrganizationInfoView = (props) => {
    const {
        history,
        match
    } = props;

    const [orgName, setOrgName] = useState(false);

    useEffect(() => {
        if (match.params.id) {
            getOrganizationInfo(match.params.id)
                .then(response => {
                    if (response.status === 200) {
                        setOrgName(response.data.name);
                    }
                })
                .catch(error => {
                    console.error(error.response)
                })
        }
    }, [match]);

    return (
        <CategoriesSection>
            <BodyHead back='organization' title={orgName ? orgName : 'Организации'}>
                <GridColumn column={4} gap={24}>
                    <CustomNavLink
                        to={`/organization/${match.params.id}/sector`}><SectorsSvg/>Направления</CustomNavLink>
                    <CustomNavLink
                        to={`/organization/${match.params.id}/user`}><PeopleSvg/>Специалисты</CustomNavLink>
                    <CustomNavLink to={`/organization/${match.params.id}/log`}><Edit2Svg/>Журнал
                        действий</CustomNavLink>
                    <Button
                        onClick={() => history.push(`${history.location.pathname}/add`)}><PlusSvg/>Добавить</Button>
                </GridColumn>
            </BodyHead>
            <div>
                <Switch>
                    <Route path='/organization/:id/sector' component={OrganizationSectors}/>
                    <Route path='/organization/:id/user' component={OrganizationUsers}/>
                </Switch>
            </div>
        </CategoriesSection>
    )
};
export const OrganizationInfo = withRouter(OrganizationInfoView);
