import React, { useCallback, useEffect, useState } from 'react'
import { InputSystem } from "../../../ui/molecules";
import { GridItem, ButtonCenter, Container, FormSection, GridBasic } from "../../../ui/atoms";
import { Button } from "../../../ui/atoms";
import { withRouter } from 'react-router-dom'

export const StationFormView = (props) => {
    const { history } = props
    const [stationName, setStationName] = useState(undefined);
    const [diameter, setDiameter] = useState(undefined)
    const [stationStart, setStationStart] = useState(undefined);
    const [stationEnd, setStationEnd] = useState(undefined)
    const [disabled, setDisabled] = useState(true)

    const validate = useCallback(() => {
        return !!(stationName && stationStart && stationEnd && diameter);
    }, [stationName, diameter, stationEnd, stationStart])


    useEffect(() => {
        if (validate()) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

    }, [validate])

    const onSubmit = (e) => {
        console.log(e);
        e.preventDefault()
        if (validate) {

        }
        alert('hello')
    }

    return (
        <Container padding={24}>
            <FormSection>
                <GridBasic perColumn={2} rowGap={40} columnGap={24}>
                    <GridItem>
                        <InputSystem
                            type='text'
                            label='Названия станции'
                            placeholder='Введите названия станции'
                            value={stationName}
                            change={setStationName}
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            type='number'
                            label='Диаметр станции'
                            placeholder='Введите диаметр станции'
                            value={diameter}
                            change={setDiameter}
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            type='number'
                            label='Точка начало'
                            placeholder='Введите точку начало'
                            value={stationStart}
                            change={setStationStart}
                        />
                    </GridItem>
                    <GridItem>
                        <InputSystem
                            type='number'
                            label='Точка конца'
                            placeholder='Введите конечную точку'
                            value={stationEnd}
                            change={setStationEnd}
                        />
                    </GridItem>
                </GridBasic>
                <ButtonCenter>
                    <Button onClick={() => history.push('/categories')} type='link'>Отмена</Button>
                    <Button
                        disabled={disabled}
                    >Добавить</Button>
                </ButtonCenter>
            </FormSection>
        </Container>
    )
}

export const StationForm = withRouter(StationFormView)