import React from 'react'
import {NotificationHeading, NotificationItem, NotificationListMenu, NotificationWrapper} from "../atoms";
import Avatar from '../../../media/Avatar.jpg'

export const NotificationList = ({setShowStatus}) => {
    return (
          <NotificationWrapper>
              <NotificationHeading>
                  <h4>Уведомления</h4>
                  {/*<CloseSvg onClick={() => setShowStatus(false)}/>*/}
              </NotificationHeading>
              <NotificationListMenu>
                  <NotificationItem>
                      <img src={Avatar}/>
                      <div>
                          <p>
                              Анатолий Токов <span>изменил значение в категории</span> Путевые и сигнальные знаки
                          </p>
                      </div>
                  </NotificationItem>
                  <NotificationItem>
                      <img src={Avatar}/>
                      <div>
                          <p>
                              Анатолий Токов <span>изменил значение в категории</span> Путевые и сигнальные знаки
                          </p>
                      </div>
                  </NotificationItem>
                  <NotificationItem>
                      <img src={Avatar}/>
                      <div>
                          <p>
                              Анатолий Токов <span>изменил значение в категории</span> Путевые и сигнальные знаки
                          </p>
                      </div>
                  </NotificationItem>
                  <NotificationItem>
                      <img src={Avatar}/>
                      <div>
                          <p>
                              Анатолий Токов <span>изменил значение в категории</span> Путевые и сигнальные знаки
                          </p>
                      </div>
                  </NotificationItem>
                  <NotificationItem>
                      <img src={Avatar}/>
                      <div>
                          <p>
                              Анатолий Токов <span>изменил значение в категории</span> Путевые и сигнальные знаки
                          </p>
                      </div>
                  </NotificationItem>
              </NotificationListMenu>
          </NotificationWrapper>
    )
}
