import {combine, createStore} from 'effector';
import {fetSectors} from './effects';
import Cookies from "js-cookie";
import {positionPageSizeChange} from "./events";

let storage_name = 'settings';
let storage = Cookies.get(storage_name);


export const $positions = createStore({loading: false, data: {}, filtered: [], error: false})
      .on(
            fetSectors.pending, (params, pending) => {
                return {
                    ...params,
                    loading: pending
                }
            })
      .on(fetSectors.finally, (params, response) => {
          if (response.error) {
              return {
                  ...params,
                  error: response.error,response
              }
          }else {
              return  {
                  ...params,
                  data: response.result.data,
                  filtered:response.result.data.data.map(item => ({
                      id: item.id, name: item.title, key: item.id
                  })),
                  error: false
              }
          }
      });

const storageData = storage !== undefined && JSON.parse(storage)
const positionSettings = storageData.position !== undefined ? {...storageData} : {...storageData, position: {}}

export const $positionSettings = createStore(storage !== undefined ? {...storageData, ...positionSettings}: {position: {}})
      .on(positionPageSizeChange, (params, payload) => {
          const {position} = params
          const newSettings = {...position, ...payload}
          return {
              ...params,
              position: newSettings
          }
      })

$positionSettings.watch((state) => {
    // console.log(state);
    Cookies.set(storage_name, state)
})


export const $isDataPending = combine({
    $positions,
    $positionSettings
});
