import React, { useEffect, useState, Fragment } from 'react';
import { CategoriesSection } from "../atoms";
import { BodyHead } from "../../../components/body-head";
import { Button, GridColumn } from "../../../ui/atoms";
import { PlusSvg } from "../../../media";
import { CategoriesList } from "./categories-list";
import { withRouter } from "react-router-dom";
import { SortSystem } from "../../../ui/molecules";

const SectionPageView = (props) => {
    const {
        history
    } = props;

    const [search, setSearch] = useState(null);
    const [value, setValue] = useState({ id: 'id', label: 'по ID' })

    useEffect(() => {
        const paramsString = history.location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('search')) {
            setSearch(searchParams.get('search'))
        } else {
            setSearch('')
        }

    }, [history.location.search])

    const sortData = [

        {
            id: '',
            label: ' по ID'
        },
        {
            id: 'name',
            label: 'по названию'
        },
        {
            id: 'length',
            label: 'по длине'
        },
        {
            id: 'origin.name',
            label: 'по стыковке'
        },

    ]

    return (
        <Fragment>
            <BodyHead search={search} searchQuery={{ pathname: '/sections' }} setSearch={setSearch} title='Направления'>
                <GridColumn column={2} gap={25}>
                    <SortSystem sortData={sortData} value={value} setValue={setValue} />
                    <Button onClick={() => history.push('/sections/add')}><PlusSvg />Добавить</Button>
                </GridColumn>
            </BodyHead>
            <CategoriesList search={search} sortValue={value} />
        </Fragment>
    )
};

export const SectionPage = withRouter(SectionPageView);
