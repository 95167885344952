import styled from 'styled-components';
import {Block, Button} from "../../../ui/atoms";

export const CatgeriesListAddSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ListForm = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 24px 24px;
  padding: 32px 24px 48px;
  ${Block}{
    margin-top: 40px;
  }
`;
export const AddVariant = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #8F9BB3;
  ${Button}{
    padding: 3px 5px;
    margin-left: 10px;
    svg{
      margin: 0;
    }
  }
`;
export const VariantTitle = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: #8F9BB3;
`;
