import React from "react";
import {combine, createStore} from "effector";
import { Tooltip } from 'antd';
import {
    fetchContextInfo,
    fetchLogList,
    fetchOrganizationList,
    fetchOrganizationInfo,
    fetchOrganizationSectorList,
    fetchSubSectorList,
    fetchSectorInfo,
    fetchAttributeList,
    fetchEntrySector,
    fetchCategoriesList,
    fetchSectorList,
    fetchUserList, fetchOrganizationUserList
} from "./effects";
import {sectorInfoEvent, showOrganizationInfo, timeEvent} from "./events";
import {lengthFormatter} from "../../../helpers";

export const $attributeList = createStore({loading: false, data: [], error: false})
    .on(fetchAttributeList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchAttributeList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data.map(item => {
                    return {
                        ...item,
                        key: item.id,
                        name: <Tooltip overlayStyle={{borderRadius: 15}} placement="topLeft" title={item.description}><div>{item.name}</div></Tooltip>,
                        value: item.id
                    }
                }),
                filtered: response.result.data.map(item => ({value: item.id, label: item.name})),
                error: false
            };
        }
    });

export const $entrySection = createStore({loading: false, data: [], error: false})
    .on(fetchEntrySector.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchEntrySector.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            };
        }
    });
export const $contextInfo = createStore({loading: false, data: [], error: false})
    .on(fetchContextInfo.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchContextInfo.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            };
        }
    });

export const $organizationList = createStore({loading: false, data: [], error: false})
    .on(fetchOrganizationList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchOrganizationList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                filtered: response.result.data.data.map(item => ({
                    value: item.id,
                    label: item.name
                })),
                error: false
            }
        }
    });

export const $organizationInfo = createStore({status: false, data: {}, loading: false, error: false})
    .on(showOrganizationInfo, (params, status) => {
        return {
            ...params,
            status: status
        }
    })
    .on(fetchOrganizationInfo.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchOrganizationInfo.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: {},
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            }
        }
    });

export const $organizationSectorList = createStore({loading: false, data: [], filtered: [], error: false})
    .on(fetchOrganizationSectorList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchOrganizationSectorList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data,
                filtered: response.result.data.map(item => ({value: item.id, label: item.name})),
                error: false
            };
        }
    });

export const $subSectorList = createStore({loading: false, data: [], multiple: [], error: false})
    .on(fetchSubSectorList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSubSectorList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data,
                filtered: response.result.data.data.map(item => ({value: item.id, label: item.name})),
                error: false
            };
        }
    });

export const $sectorInfo = createStore({loading: false, data: [], error: false})
    .on(fetchSectorInfo.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetchSectorInfo.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            };
        }
    })
    .on(sectorInfoEvent, (params, response) => {
        return {
            ...params,
            data: response,
            error: false
        }
    });

export const $categoriesList = createStore({loading: false, data: [], error: false})
    .on(fetchCategoriesList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchCategoriesList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                filtered: response.result.data.data.map(item => ({value: item.id, label: item.name})),
                error: false
            }
        }
    });
export const $logList = createStore({loading: false, data: [], error: false})
    .on(fetchLogList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchLogList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            }
        }
    });

export const $sectorList = createStore({loading: false, data: [], error: false})
    .on(fetchSectorList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchSectorList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            }
        }
    })

export const $userList = createStore({loading: false, data: {}, error: false})
    .on(fetchUserList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchUserList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                error: false
            }
        }
    })
    .on(fetchOrganizationUserList.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        }
    })
    .on(fetchOrganizationUserList.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                error: response.error.response
            }
        } else {
            return {
                ...params,
                data: response.result.data,
                filtered: response.result.data.map(item =>  ({
                    key: item.id,
                    image: item.user.image,
                    online: 'В сети',
                    name: `${item.user.name} ${item.user.sname}`,
                    role: item.user.position ? item.user.position.title : '',
                    phone: item.user.phone,
                    sector:  item.permissions.length > 0 ? item.permissions.map(item =>  item.sector ? item.sector.name : '') : null,
                    subSector: item.permissions.length > 0 ? item.permissions.map(item =>  item.subSector ? item.subSector.name : '') : null,
                    division: item.permissions.length > 0 ? item.permissions.map(item =>  {
                        return item.allowedStart && item.allowedEnd ?  `${lengthFormatter(item.allowedStart)} - ${lengthFormatter(item.allowedEnd)}` : ''
                    }): null
                })),
            }
        }
    });

export const $timeStore = createStore(Date.now())
    .on(timeEvent, (params, time) => {return time});


export const $isDataPending = combine({
    $contextInfo,
    $organizationList,
    $organizationInfo,
    $organizationSectorList,
    $subSectorList,
    $sectorInfo,
    $categoriesList,
    $attributeList,
    $entrySection,
    $logList,
    $sectorList,
    $userList,
    $timeStore
});
