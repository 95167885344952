import styled from "styled-components";
import Carousel from "nuka-carousel";
import {NavLink} from "react-router-dom";

export const CarouselUI = styled(Carousel)`
  display: flex;
  outline: none;
  .slider-control-bottomcenter {
    display: none;
  }
  .slider-control-centerleft button,
  .slider-control-centerright button {
    border: none;
    box-shadow: none;
    background: transparent;
  }
`;

export const CarouselNavLink = styled(NavLink)`
  padding: 8px 12px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    width: 100%;
    background: ${({theme}) => (theme.main.semanticColor.basic[300])};
    color: ${({theme}) => (theme.main.semanticColor.basic[700])};
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 48px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    transition: .3s;
  }
  &:hover, &.active {
    span{
      color: ${({theme}) => (theme.main.themeColor[100])};
      background: ${({theme}) => (theme.main.semanticColor.primary[500])};
      box-shadow: 0 4px 8px rgba(51, 102, 255, 0.24);
    }
  }
    
`;
export const HintText = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({theme}) => theme.main.semanticColor.primary[500]}
    margin-top: 8px
`;

export const MapContainer = styled.div`
  display: flex;
  position: relative;
  height: 600px;
  width: 100%;
  flex-direction: column;
  margin: 0 0 15px;
  border-radius: 8px;
  border: 1px solid #EDF1F7;
  overflow: hidden;
`;

export const TabCustom = styled.div`
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 56px;
  }
  .tab-link{
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 24px;
  }
`;

export const GetImgStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
`;

export const SectorInfoWindow = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  div{
  margin-bottom: 16px;
    span{
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #8F9BB3;
      &:nth-child(2){
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #222B45;
        text-transform: initial;
        margin-left: 5px;
      }
    }
  }
`;