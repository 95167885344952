import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { useStore } from "effector-react";
import { $isDataPending, orgSectorsMounted } from "../model";
import { AntTable } from "../../../ui/atoms";
import { CategoriesListBlock } from "../../categories/atoms";
import { Link } from "react-router-dom";
import { orgUsersMounted } from "../model/events";
import { DeleteSector } from "../organisms";
import { DeleteUser } from "../organisms/delete-user-organization";
import {RemoveDuplicate} from "../../../helpers";



const OrganizationUsersView = (props) => {
    const {
        match
    } = props;
    const isDataPending = useStore($isDataPending);

    //config
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(0);
    const [start, setStart] = useState(0);

    //data
    const [data, setData] = useState([]);

    function changePagination(page) {
        setPage(page);
        setStart(((page - 1) * pageSize) - (page === 1 ? 0 : 1))
    }
    function changeShowSize(current, size) {
        setPageSize(size)
    }


    const [size, setSize] = useState(undefined);

    useEffect(() => {
        const params = {
            size: size
        };

        orgUsersMounted(match.params.id, params);
    }, [match.params.id, size]);

    const UpdateList = () => {
        const params = {
            size: size
        };

        orgUsersMounted(match.params.id, params);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (name, data) => (<Link to={`/organization/${data.organizationId}/user/${data.id}/edit`}>{name}</Link>)
        },
        {
            title: 'Имя, фамилия',
            dataIndex: 'name',
            key: 'name',
            render: (name, data) => (<Link to={`/organization/${data.organizationId}/user/${data.id}/edit`}>{name}</Link>)
        },
        {
            title: 'Направление',
            dataIndex: 'sector',
            key: 'sector',
            render: (id, data) => (<Link to={`/organization/${data.organizationId}/user/${data.id}/edit`}>{data.sector !== null ? RemoveDuplicate(data.sector, 'id').map((item, idx) => (
                <div key={idx}>{item.name}</div>)) : 'отсуствует'}</Link>)
        },
        {
            title: 'Перегон',
            dataIndex: 'subSector',
            key: 'subSector',
            render: (id, data) => (<Link to={`/organization/${data.organizationId}/user/${data.id}/edit`}>{data.subSector !== null ? data.subSector.map((item, idx) => (
                <div key={idx}>{item}</div>)) : 'отсуствует'}</Link>)
        },
        {
            title: 'Начало границы',
            dataIndex: 'allowedStart',
            key: 'allowedStart',
            render: (id, data) => (<Link to={`/organization/${data.organizationId}/user/${data.id}/edit`}>{data.allowedStart !== null ? data.allowedStart.map((item, idx) => (
                <div key={idx}>{item}</div>)) : 'отсуствует'}</Link>)
        },
        {
            title: 'Конец границы',
            dataIndex: 'allowedEnd',
            key: 'allowedEnd',
            render:  (id, data) => (<Link to={`/organization/${data.organizationId}/user/${data.id}/edit`}>{data.allowedEnd !== null ? data.allowedEnd.map((item, idx) => (
                <div key={idx}>{item}</div>)) : 'отсуствует'}</Link>)
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: id => (<DeleteUser updateList={UpdateList} id={id} />),
            width: 50
        }
    ];



    // console.log('users', isDataPending.$orgUsersList.data)

    return (
        <CategoriesListBlock>
            <AntTable
                loading={isDataPending.$orgUsersList.loading}
                columns={columns}
                dataSource={isDataPending.$orgUsersList.data}
            // pagination={
            //     {
            //         pageSize: pageSize,
            //         pageSizeOptions : ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
            //         // total: isDataPending.$orgUsersList.recordsTotal,
            //         onChange: changePagination,
            //         onShowSizeChange: changeShowSize,
            //         current: page,
            //         showSizeChanger: true
            //     }
            // }
            />
        </CategoriesListBlock>
    )
};

export const OrganizationUsers = withRouter(OrganizationUsersView);
