import React from 'react'
import { PopoverItem } from "../../organization/molecules";
import { AntTable, Container } from "../../../ui/atoms";
import { TableItem } from "../atoms";

export const RequestContent = ({ data }) => {
    const columns = [
        {
            title: 'Изменить',
            dataIndex: 'changed',
            key: 'changed',
            width: 70,
            render: (changed) => (<TableItem>{changed}</TableItem>)
        },
        {
            title: 'Детали',
            dataIndex: 'detail',
            key: 'detail',
            width: 70,
            render: (detail) => (<TableItem>{detail}</TableItem>)
        },
        {
            title: 'Имя, Фамилия',
            dataIndex: 'user',
            key: 'user',
            width: 70,
            render: (user) => (<TableItem>{user}</TableItem>)
        },
        {
            title: 'Статус',
            dataIndex: 'statusText',
            key: 'statusText',
            width: 70,
            render: (statusText, data) => (<TableItem type={data.type}>{statusText}</TableItem>)
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 30,
            render: id => (<PopoverItem /*updateList={updateList}*/ id={id} />)
        }
    ];

    return (
        <Container>
            <AntTable
                columns={columns}
                dataSource={data}
            />
        </Container>
    )
}
