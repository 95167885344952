import React, {useState} from 'react';
import {MoreHorizontalSvg, TrashSvg} from "../../../media";
import {removeSectorForOrg} from "../api";
import Popover from "react-tiny-popover";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {deleteConfirm} from "../../../ui/molecules";
import {notification} from "antd";


export const DeleteSector = (props) => {
    const {
        id,
        updateList
    } = props;

    //status
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const deleteSector = () => {
        removeSectorForOrg(id)
              .then(response => {
                  if (response.status === 200) {
                      updateList();
                      notification['success']({
                          message: 'Успешно!',
                          description: 'Участка удалена!'
                      });
                  }
              })
              .catch(error => {
                  console.error(error.response.data);
                  notification['error']({
                      message: 'Ошибка!',
                      description: 'Ошибка при удалении, попробуйте позже'
                  });
              })
    };


    return (
          <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                position={'bottom'} // preferred position
                content={(
                      <PopConfirmContent>
                          <span className='delete' onClick={() => deleteConfirm(deleteSector)}>
                              <TrashSvg/>
                              Удалить
                          </span>
                      </PopConfirmContent>
                )}
          >
              <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  <MoreHorizontalSvg/>
              </div>
          </Popover>
          // <Button onClick={changeButton} loading={loading} type='error' icon={<TrashSvg/>} noChildren/>
    )
};
