import styled from 'styled-components';
import {Button, Block} from "../../../ui/atoms";
import {Link} from "react-router-dom";
import {Row} from "antd";

export const AddUserSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px 24px 48px;
    
  ${Block}{
    margin-top: 40px;
  }
`;
export const AvatarUploadLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  background: #FAFAFB;
  border: 1px solid #E4E9F2;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  img{
    width: 48px;
    height: 48px;
    object-fit: contain;
    border-radius: 50%;
  }
  svg{
    width: 48px;
    height: 48px;
    &:nth-child(2){
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 32px;
      height: 32px;
    }
  }
`;

export const UsersSection = styled(Row)`
  display: flex;
  flex-direction: column !important;
`;

export const UserListSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const UserImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #f4f4f4;
  margin: ${props => props.active ? '32px 0 16px' : '0'};
  width: ${props => props.active ? '64px' : '40px'};
  height: ${props => props.active ? '64px' : '40px'};
  img{
    display: flex;
    min-width: 100%;
    object-fit: contain;
  }
`;

export const UserInfoImage = styled.div`
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.main.semanticColor.basic[200]}
    border: 1px solid ${({theme}) => theme.main.semanticColor.basic[400]}
    border-radius: 50%;
    overflow: hidden;
    
    img {
        min-width: 100%;
        object-fit: contain;
        
    }
`

export const UserText = styled(Link)`
  display: inline-flex;
  margin: ${props => props.active ? '0 0 16px' : '0 0 0 24px'};
  align-items: ${props => props.active ? 'center' : 'flex-start'};
  flex-direction: ${props => props.active ? 'row' : 'column'};
  width: ${props => props.active ? '100%' : 'initial'};
  flex: 1;
  @media (max-width: 1440px) {
    margin: ${props => props.active ? '0 0 12px' : '0 0 0 18px'}; 
  }
  
  svg{
    width: 25px;
    margin-right: 10px;
    color: #222B45;
  }
  
  div{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #222B45;
    width: ${props => props.active ? '100%' : 'initial'};
    flex: 1;
    text-align: ${props => props.active && props.name ? 'center' : 'initial'};
    flex-direction: ${props => props.active ? props.name ? 'column' : 'column-reverse' : 'column'};
    justify-content: ${props => props.active && props.name ? 'center' : 'flex-start'};
    div{
        max-width: ${props => props.active ? '100% !important' : 'initial'};
    }
    
    @media (max-width: 1280px){
      font-size: 13px;
    }
  }
  span{
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #8F9BB3;
    text-align: ${props => props.active && props.name ? 'center' : 'initial'};
    @media (max-width: 1280px) {
      font-size: 12px;
    }
  }
`;

export const UserSection = styled.div`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 24px;
  transition: .3s;
  //overflow-x: scroll;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: ${props => props.active ? 'column' : 'row'};
  &:hover, &:focus{
    background: #EDF1F7;
  }
  
  @media (max-width: 1440px) {
    padding: 10px 14px;
  }
  @media (max-width: 1280px){
  padding: 6px 14px;
  }
`;
export const FilterSelect = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.column}, auto);
  grid-gap: ${props => props.gap}px;
  color: #8F9BB3;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  
  & > div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 1440px) {
      font-size: 13px;
    }
  }
  
 ${Button}{
  background: transparent;
  color: #8F9BB3;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 0 !important;
 }
 
 @media (max-width: 1280px) {
  grid-gap: 15px;
 }
`;

export const UserInfoBlock = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 40px;

  ${UserText}{
    margin: 10px 0 0;
    border-bottom: 1px solid #EDF1F7;
    padding: 0 15px 15px;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  @media (max-width: 1440px) {
    padding: 24px;
  }
`;

export const UserInfoChild = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  justify-content: space-between;
  ${UserText}{
    margin-left: 32px;
    border: none;
    padding: 0;
  }
`;
export const JournalDate = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #8F9BB3;
  display: flex;
  justify-content: flex-end;
`;

export const JournalItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  span{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;    
    text-transform: uppercase;
    color: #8F9BB3;
  }
  div{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;    
    text-transform: uppercase;
    margin-top: 5px;
    //color: #8F9BB3;
  }
`;
