export const fakeData = [
    {   id: new Date().getTime(),
        key: new Date().getTime(),
        changed: 'Запрос на изменение организации',
        detail: 'ПЧ-2 Карши - ПЧ-3 Нукус',
        user: 'Анатолий Токов',
        statusText: 'Ждет обработки',
        type: 0
    },
    {   id: new Date().getTime(),
        key: new Date().getTime(),
        changed: 'Запрос на изменение должности',
        detail: 'Техник - Управляющий ',
        user: 'Мухаммадазиз Мухаммедов',
        statusText: 'Одобрено',
        type: 1
    },
    {   id: new Date().getTime(),
        key: new Date().getTime(),
        changed: 'Запрос на изменение должности',
        detail: 'Техник - Управляющий ',
        user: 'Анатолий Токов ',
        statusText: 'Отказано',
        type: 2
    }

]
