import React, { useEffect, useState, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { FilterSelect, UsersSection } from "../atoms";
import { BodyHead } from "../../../components/body-head";
import { Button, GridColumn, InlineFlex } from "../../../ui/atoms";
import { UserList } from "../organisms";
import { PlusSvg } from "../../../media";
import { GridSvg, InfoSvg, ListSvg } from "../../../media";
import { SortSystem } from "../../../ui/molecules";
import { useStore } from "effector-react";
import { $isDataPending, userListSettingsChange } from "../model";

const UsersPageView = (props) => {
    const { $userSettings } = useStore($isDataPending)

    const {
        history
    } = props;

    const [active, setActive] = useState($userSettings.user.list ? $userSettings.user.list : {
        status: false,
        value: 'LIST'
    });
    //search
    const [search, setSearch] = useState(null);
    const [value, setValue] = useState({ id: 'username', label: 'по логину' })

    const buttons = [
        {
            value: 'LIST',
            label: 'список',
            icon: <ListSvg />
        },
        {
            value: 'GRID',
            label: 'сетка',
            icon: <GridSvg />
        }
    ]
    const handleClick = (value) => {
        const settings = {
            value: value,
            status: null
        }

        settings.status = value !== 'LIST';

        setActive(settings)
        const payload = {
            list: settings
        }
        userListSettingsChange(payload)
    }


    useEffect(() => {
        const paramsString = history.location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('search')) {
            setSearch(searchParams.get('search'))
        } else {
            setSearch('')
        }

    }, [history.location.search])


    const sortData = [
        {
            id: '',
            label: 'по ID'
        },
        {
            id: 'name',
            label: 'по имени'
        },
        {
            id: 'sname',
            label: 'по фамилии'
        },
        {
            id: 'username',
            label: 'по логину'
        }
    ]

    return (
        <Fragment>
            <BodyHead search={search} searchQuery={{ pathname: '/users' }} setSearch={setSearch} title='Пользователи'>
                <GridColumn column={2} gap={25}>
                    <FilterSelect column={4} gap={25}>
                        <SortSystem responsive={{ text: true }} sortData={sortData} value={value} setValue={setValue} />
                        <div>
                            <InfoSvg />
                            Статус:
                        </div>
                        {buttons.map(item => {
                            return (
                                <Button
                                    type='link'
                                    key={item.value}
                                    active={item.value === active.value}
                                    onClick={() => handleClick(item.value)}
                                >
                                    {item.icon}
                                    {item.label}
                                </Button>
                            )
                        })}
                    </FilterSelect>
                    <Button onClick={() => history.push('/users/add')}><PlusSvg />Добавить</Button>
                </GridColumn>
            </BodyHead>
            <UserList search={search} active={active.status} sortValue={value} />
        </Fragment>
    )
};

export const UsersPage = withRouter(UsersPageView);
