import React from 'react';

import { DatePicker } from 'antd';
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {DatePickerInput} from "../atoms";
import {theme} from "../../theme";


export const DatePickerBlock = (props) => {
    const {
        status,
        value,
        setValue
    } = props;

    const { MonthPicker } = DatePicker;

    return (
        <DatePickerInput status={status ? status : 'default'} theme={theme.main}>
            <label>Выберите дату</label>
            <MonthPicker value={value} onChange={(e) => setValue(e)}/>
        </DatePickerInput>
    )
};
