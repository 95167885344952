import React, { useEffect, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { subCategoriesPageMounted } from './model';
import { BodyHeadButtons } from "./atoms";
import { BodyHead } from "../../components/body-head";
import { Button, GridColumn } from "../../ui/atoms";
import { GridSvg, ListSvg, PlusSvg } from "../../media";
import { CategoriesSubList } from "./template";
import { Row } from 'antd';
import { FilterSelect } from "../users/atoms";
import { DropdownView } from "../../ui/molecules";

const SubCategoriesPageView = (props) => {
    const {
        history,
        match
    } = props;

    const addMenuData = [
        {
            id: 'addSub',
            label: 'Подкатегорию',
            action: () => history.push(`/categories/${match.params.id}/add`)
        },
        {
            id: 'addList',
            label: 'Список',
            action: () => history.push(`/categories/${match.params.id}/list/add`)
        }
    ]


    return (
        <Fragment>
            <BodyHead back={`categories/${match.params.id}`} title='Подкатегории'>
                <GridColumn column={2} gap={40}>
                    <FilterSelect column={2} gap={40}>
                        <Button
                            onClick={() => history.push(`/categories/${match.params.id}`)}
                            type='link'
                        >
                            <GridSvg />
                            подкатегории
                        </Button>
                        <Button
                            onClick={() => history.push(`/categories/${match.params.id}/list`)}
                            type='link'
                            active='true'
                        >
                            <ListSvg /> Список
                        </Button>
                    </FilterSelect>
                    <DropdownView menuData={addMenuData}>
                        <Button>
                            <PlusSvg />Добавить
                        </Button>
                    </DropdownView>
                </GridColumn>
            </BodyHead>
            <CategoriesSubList />
        </Fragment>
    )
};

export const CategoriesList = withRouter(SubCategoriesPageView);
