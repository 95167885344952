import {httpDelete, httpGet, httpPut} from "../../api";

export const getSubCategoriesList = (id, params) => httpGet({
    url: `/attribute/list/${id}`, params
});
export const getCategoriesInfo = (id) => httpGet({
    url: `/category/${id}`
});

//add
export const createApi = (id, data) => httpPut({
    url: `/attribute/${id}`, data
});

export const getListCategoriesApi = (id) => httpGet({
    url: `/list/list/${id}`
});

export const attributeDelete = (id) =>  httpDelete({
    url: `/attribute/${id}`
})
