import React, { Fragment, useState } from 'react'
import { BodyHead } from "../../../components/body-head";
import { TariffHeading } from "../atoms";
import { withTheme } from "styled-components";
import { TariffCalculate, TariffRangeItem } from "../organisms";
import { Container, FormSection } from '../../../ui/atoms';

const TariffPageView = () => {
    const [monthValue, setMonthValue] = useState(24)
    const [employerValue, setEmployerValue] = useState(48)
    const [sectorValue, setSectorValue] = useState(24)
    const [categoryValue, setCategoryValue] = useState(36)

    const generateMarks = (start, end, step) => {
        let result = {}
        for (let i = start; i <= end; i += step) {
            result[i] = i
        }
        return result
    }

    const tariffFields = [
        {
            label: 'Период',
            value: monthValue,
            changeValue: setMonthValue,
            title: 'Период в месяцах',
            step: 1,
            max: 84,
        },
        {
            label: 'Количество сотрудников',
            value: employerValue,
            changeValue: setEmployerValue,
            title: 'Количество сотрудников',
            max: 2000,
            step: 1,
            mark: generateMarks(0, 2000, 100)
        },
        {
            label: 'Количество направлении',
            value: sectorValue,
            changeValue: setSectorValue,
            title: 'Количество направлении',
            max: 2000,
            step: 1,
            mark: generateMarks(0, 2000, 100)
        },
        {
            label: 'Количество категории',
            value: categoryValue,
            changeValue: setCategoryValue,
            title: 'Количество категории',
            step: 1,
            max: 200,
            mark: generateMarks(0, 200, 40)
        },
    ]

    return (
        <Fragment>
            <BodyHead title='Категории' />
            <Container>
                <FormSection>
                    <TariffHeading>
                        Укажите параметры желаемого тарифа
                  </TariffHeading>
                    {tariffFields.map((item, idx) => {
                        return (
                            <TariffRangeItem
                                key={idx}
                                value={item.value}
                                changeValue={item.changeValue}
                                title={item.title}
                                step={item.step}
                                max={item.max}
                                customMark={item.mark}
                            />
                        )
                    })}
                    <TariffCalculate tariffFields={tariffFields} />
                </FormSection>
            </Container>
        </Fragment>
    )
}

export const TariffPage = withTheme(TariffPageView)
