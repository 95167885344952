import React from 'react';
import {SectionPage, SectorsAdd} from "./templates";
import {Switch, Route} from "react-router-dom";
import {SectorsSub} from "../sectors-sub";
import {SectorSubAdd} from "../sectors-sub-add";
import {StationAdd, Stations} from "../stations";

export const SectionsPage = () => {


    return (
        <Switch>
            <Route exact path='/sections' component={SectionPage}/>
            <Route exact path='/sections/add' component={SectorsAdd}/>
            <Route exact path='/sections/:id/edit' component={SectorsAdd}/>
            <Route exact path='/sections/:id/sub' component={SectorsSub} />
            <Route exact path='/sections/:id/sub/add' component={SectorSubAdd} />
            <Route exact path='/sections/:id/sub/:subId' component={SectorSubAdd} />
            <Route exact path='/sections/:id/stations' component={Stations} />
            <Route exact path='/sections/:id/stations/add' component={StationAdd} />
        </Switch>
    )
};
