import React from 'react';
import {Dropdown} from "antd";
import {DropdownMenu, DropdownMenuItem} from "../atoms";

export const DropdownView = (props) => {
const {menuData, children} = props
    const menu = (
        <DropdownMenu>
            {menuData.length > 0 && menuData.map(item => (
                <DropdownMenuItem
                    key={item.id} onClick={item.action}>
                    {item.label}
                </DropdownMenuItem>
            ))}
        </DropdownMenu>
    );

    return(
        <Dropdown overlay={menu} trigger={['click']}>
            {children}
        </Dropdown>
    )
}