import React, {useEffect, useState} from 'react';
import {useStore} from "effector-react";
import {$isDataPending, orgSectorsMounted} from "../model";
import {Link, withRouter} from "react-router-dom";
import {AntTable} from "../../../ui/atoms";
import {CategoriesListBlock} from "../../categories/atoms";
import {DeleteSector} from "../organisms";


const OrganizationSectorsView = (props) => {
    const {
        match
    } = props;
    const isDataPending = useStore($isDataPending);

    //config
    const [pageSize, setPageSize] = useState(20);
    // const [ page, setPage ] = useState(0);
    const [start, setStart] = useState(0);


    useEffect(() => {
        const params = {
            length: pageSize,
            start: start
        };
        orgSectorsMounted(match.params.id, params);
    }, [match.params.id, pageSize, start]);

    // console.log(isDataPending.$orgSectorsList);

    // function changePagination(page) {
    //     setPage(page);
    //     setStart(((page - 1) * pageSize) - (page === 1 ? 0 : 1))
    // }
    // function changeShowSize(current, size) {
    //     setPageSize(size)
    // }
    function updateList() {
        const params = {
            length: pageSize,
            start: start
        };
        orgSectorsMounted(match.params.id, params);
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (name, data) => (<Link to={`/sections/${data.id}/edit`}>{name}</Link>)
        },
        {
            title: 'Длина',
            dataIndex: 'length',
            key: 'length',
            render: id => (<Link to={`/sections/${id}/edit`}>{id}</Link>)
        },
        {
            title: 'Точка начала',
            dataIndex: 'point',
            key: 'point',
            render: id => (<Link to={`/sections/${id}/edit`}>{id}</Link>)
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (id, data) => (<DeleteSector updateList={updateList} id={data.key}/>),
            width: 50
        }
    ];


    return (
        <CategoriesListBlock>
            <AntTable
                loading={isDataPending.$orgSectorsList.loading}
                columns={columns}
                dataSource={isDataPending.$orgSectorsList.data}
                // pagination={
                //     {
                //         pageSize: pageSize,
                //         pageSizeOptions : ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                //         // total: isDataPending.$orgSectorsList.recordsTotal,
                //         onChange: changePagination,
                //         onShowSizeChange: changeShowSize,
                //         current: page,
                //         showSizeChanger: true
                //     }
                // }
            />
        </CategoriesListBlock>
    )
};

export const OrganizationSectors = withRouter(OrganizationSectorsView);
