import {httpGet} from "../../api";


export const getParentSector = ({id, params}) => httpGet({
    url: `/sector/list/${id}`, params
});

export const getSectorInfo = (id) => httpGet({
    url: `/sector/${id}`
});
