import React, { useEffect, useState } from 'react'
import { CategoriesListBlock } from "../../categories/atoms";
import { AntTable, Container } from "../../../ui/atoms";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";
import { $isDataPending, organizationPageMount, organizationPageSizeChange } from "../model";
import { PopoverItem } from "../molecules";
import { useHistory } from 'react-router-dom'


export const OrganizationList = (props) => {
    const { search } = props
    const { $organization, $organizationSettings } = useStore($isDataPending);
    const { location, push } = useHistory()
    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState($organizationSettings.organization.pageSize ? $organizationSettings.organization.pageSize : 20);
    const [page, setPage] = useState(1);


    useEffect(() => {
        const paramsString = location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('page')) {
            const _page = Number(searchParams.get('page'))
            setPage(_page)
            setStart(((_page - 1) * pageSize))

        } else {
            setStart(0)
            setPage(1)
        }
    }, [location.search, pageSize])


    useEffect(() => {
        if (search !== null) {
            const params = {
                length: pageSize,
                start: start,
                "search.value": search
            };
            organizationPageMount(params);
        }
    }, [pageSize, start, search]);

    function updateList() {
        const params = {
            length: pageSize,
            start: start,
            "search.value": search
        };
        organizationPageMount(params);
    }

    function changePagination(page) {
        if (page !== 1) {
            push({
                pathname: '/organization',
                search: `page=${page}`
            })
        } else {
            push('/organization')
        }
    }

    function changeShowSize(current, size) {
        setPageSize(size)
        const payload = { pageSize: size }
        organizationPageSizeChange(payload);
    }


    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            width: 70,
            render: (name, data) => (<Link to={`/organization/${data.id}/sector`}>{name}</Link>)
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            width: 70,
            render: (phone, data) => (<Link to={`/organization/${data.id}/sector`}>{phone}</Link>)
        },
        {
            title: 'Ответственное лицо',
            dataIndex: 'superior',
            key: 'superior',
            width: 70,
            render: (user, data) => (<Link to={`/organization/${data.id}/sector`}>{user}</Link>)
        },
        {
            title: 'РЖУ',
            dataIndex: 'rju',
            key: 'rju',
            width: 70,
            render: (rju, data) => (<Link to={`/organization/${data.id}/sector`}>{rju}</Link>)
        },
        {
            title: 'Город',
            dataIndex: 'city',
            key: 'city',
            width: 70,
            render: (city, data) => (<Link to={`/organization/${data.id}/sector`}>{city}</Link>)
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 30,
            render: id => (<PopoverItem updateList={updateList} id={id} />)
        }
    ];

    return (
        <Container>
            <AntTable
                loading={$organization.loading}
                columns={columns}
                dataSource={$organization.data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: $organization.recordsTotal,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                        showLessItems: true
                    }
                }
            />
        </Container>
    )
};
