import {combine, createStore} from "effector";
import {fetchAllOrgUserList, fetchSignedUserInfo} from "./effect";

const $signedUserInfo = createStore({loading: false, data: {}, error: false})
    .on(fetchSignedUserInfo.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchSignedUserInfo.finally, (state, response) => {
        if (response.error) {
            return {
                ...state,
                error: response.error.response
            }
        } else {
            return {
                ...state,
                data: response.result.data
            }
        }
    })

const $userRole = createStore(null)
    .on(fetchSignedUserInfo.finally, (state, response) => {
        return response.result.data.roles ? response.result.data.roles.join('') : "ADMIN"
    })

const $allOrgUserList = createStore({loading: false, data: {}, error: false})
    .on(fetchAllOrgUserList.pending, (state, pending) => {
        return {
            ...state,
            loading: pending
        }
    })
    .on(fetchAllOrgUserList.finally, (state, response) => {
        if (response.error){
            return{
                ...state,
                error: response.error.response
            }
        }else {
            return {
                ...state,
                error: false,
                data: response.result.data
            }
        }
    })

export const $userModel = combine({
    $signedUserInfo,
    $userRole,
    $allOrgUserList
})