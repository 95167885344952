import React, {useEffect, useState, Fragment} from 'react';
import {BodyHead} from "../../components/body-head";
import {Button, GridColumn} from "../../ui/atoms";
import {PlusSvg} from "../../media";
import {CategoriesList} from "./templates";
import {withRouter} from "react-router-dom";

const PositionsPageView = (props) => {
    const {
        history
    } = props;

    const [search, setSearch] = useState(null);

    useEffect(() => {
        const paramsString = history.location.search;
        const searchParams = new URLSearchParams(paramsString);
        if (searchParams.has('search')) {
            setSearch(searchParams.get('search'))
        } else {
            setSearch('')
        }

    }, [history.location.search])

   
    return (
        <Fragment>
            <BodyHead search={search} setSearch={setSearch} searchQuery={{pathname: '/positions'}} title='Должность'>
                <GridColumn column={1} gap={0}>
                    <Button onClick={() => history.push('/positions/add')}><PlusSvg/>Добавить</Button>
                </GridColumn>
            </BodyHead>
            <CategoriesList search={search}/>
        </Fragment>
    )
};
export const PositionsPage = withRouter(PositionsPageView);
