import styled from 'styled-components';
import { Block } from "../../../ui/atoms";

export const CategoriesSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CategoriesListBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 0;
  
  @media (max-width: 1440px) {
    margin: 0 16px 0;
  }
`;
export const AddCategoriesSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 24px 24px;
  padding: 32px 24px 48px;
  /* ${Block}{
    margin-top: 40px;
  } */
  @media (max-width: 1440px) {
    padding: 32px 24px
  }
`;
