import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Dropdown} from "antd";

export const LogSection = styled.div`
    margin: 0 24px;
    
    table span {
        padding: 16px;
        display: block
    }
`

export const LogFilterWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 24px
`

export const LogFilterLink = styled(NavLink)`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: ${({theme}) => theme.main.semanticColor.basic[600]}
    text-transform: uppercase;
    
    &.active {
        color: ${({theme}) =>  theme.main.semanticColor.primary[500]}
    }
    
    svg {
        margin-right: 10px
    }
`

