import React, { useState, useEffect } from 'react';
import { ListForm } from "../../categories-list-add/atoms";
import { Block, Button, ButtonCenter, Group } from "../../../ui/atoms";
import { DatePickerBlock, InputSystem } from "../../../ui/molecules";
import { SelectSystem } from "../../../ui/molecules/select";
import { withRouter } from "react-router-dom";
import { createApi } from "../api";
import { listCategoriesMounted } from "../model";
import { useStore } from "effector-react";
import { $isDataPending } from "../model";
import { notification } from "antd";


const AddSubCategoryView = (props) => {
    const isDataPending = useStore($isDataPending);
    const {
        match
    } = props;

    const [name, setName] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [attribute, setAttribute] = useState('LIST');
    const [list, setList] = useState(undefined);
    const [date, setDate] = useState(undefined);
    const [long] = useState(false);
    const [double, setDouble] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [listOptions, setListOptions] = useState([]);

    //status
    // const [ error, setError ] = useState(false);
    // const [ success, setSuccess ] = useState(false);

    function setConstraints() {
        if (attribute === 'LIST') {
            return { list: list }
        } else if (attribute === 'DATE') {
            return { date: date }
        } else if (attribute === 'LONG') {
            return { long: long }
        } else if (attribute === 'DOUBLE') {
            return { double: double }
        } else if (attribute === 'FILE') {
            return { file: file }
        }
    }
    const CreateCategories = () => {
        const data = {
            name: name,
            type: attribute,
            description: description,
            // id: match.params.id,
            constraints: setConstraints()
        };
        createApi(match.params.id, data)
            .then(response => {
                if (response.status === 200) {
                    // setSuccess(true);
                    setName('');
                    setDescription('');
                    setAttribute('');

                    // setTimeout(() => {
                    //     setSuccess(false);
                    // }, 3000);
                    notification['success']({
                        message: 'Успешно!',
                        description: 'Подкатегория создано!'
                    });
                }
            })
            .catch(error => {
                console.error(error.response.data);
                // setError(true);
                // setTimeout(() => {
                //     setError(false);
                // }, 3000);

                notification['error']({
                    message: 'Ошибка!',
                    description: 'Ошибка при создание, попробуйте позже'
                });
            })

    };

    const data = [
        {
            value: 'TEXT',
            label: 'Текст'
        },
        {
            value: 'LONG',
            label: 'Цифры'
        },
        {
            value: 'DOUBLE',
            label: 'Дробные'
        },
        {
            value: 'LIST',
            label: 'Список'
        },
        {
            value: 'DATE',
            label: 'Дата'
        },
        {
            value: 'FILE',
            label: 'Файл'
        }
    ];

    // console.log(isDataPending)

    useEffect(() => {
        if (isDataPending.length > 0) {
            let data = [];
            for (let i = 0; i < isDataPending.length; i++) {
                data.push({ value: isDataPending[i].id, label: isDataPending[i].name })
            }
            setListOptions(data);
        }
    }, [isDataPending]);

    useEffect(() => {
        listCategoriesMounted(match.params.id)
    }, [match.params.id]);

    return (
        <ListForm>
            <Group gutter={12}>
                <Block span='50%'>
                    <InputSystem
                        value={name}
                        change={setName}
                        type='text'
                        placeholder='Введите название'
                        label='Название'
                    />
                </Block>
                <Block span='50%'>
                    <SelectSystem
                        value={attribute}
                        change={(e) => setAttribute(e)}
                        options={data}
                        label='Тип аттрибута'
                    />
                </Block>
                {
                    attribute !== 'TEXT' && attribute !== 'LONG' &&
                    <Block span='50%'>
                        {
                            {
                                'LIST':
                                    <SelectSystem
                                        change={(e) => setList(e)}
                                        options={listOptions}
                                        label='Список'
                                    />,
                                'DATE': <DatePickerBlock
                                    value={date}
                                    setValue={setDate}
                                />,
                                'FILE':
                                    <SelectSystem
                                        change={(e) => setFile(e)}
                                        options={[{ value: 'jpg', label: 'JPG' }, { value: 'png', label: 'PNG' }]}
                                        label='Список'
                                    />,
                                'DOUBLE':
                                    <SelectSystem
                                        change={(e) => setDouble(e)}
                                        options={[
                                            { value: '+', label: '+' },
                                            { value: '-', label: '-' },
                                            { value: '/', label: '/' },
                                            { value: ',', label: ',' },
                                            { value: '.', label: '.' }
                                        ]}
                                        label='Список'
                                    />,
                            }[attribute]
                        }

                    </Block>
                }

                <Block span='50%'>
                    <InputSystem
                        value={description}
                        change={setDescription}
                        type='text'
                        placeholder='Введите описание'
                        label='Описание'
                    />
                </Block>
            </Group>
            <ButtonCenter>
                <Button type='link'>Отмена</Button>
                <Button
                    disabled={
                        !name || !description || !attribute
                    }
                    onClick={() => CreateCategories()}>Добавить</Button>
            </ButtonCenter>
            {/*<Notification status={success} title='Успешно' description='Подкатегория успешно создано'/>*/}
            {/*<Notification status={error} title='Ошибка' description='Ошибка при создании, попробуйте еще.'/>*/}
        </ListForm>
    )
};
export const AddSubCategory = withRouter(AddSubCategoryView);
