import styled from 'styled-components';
import { Block } from "../../../ui/atoms";

export const SectorsAddSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SectorsForm = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EDF1F7;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 24px 24px;
  padding: 32px 24px 48px;

  @media (max-width: 1440px) {
    padding: 32px 24px;
  }
  
  ${Block}{
    margin-top: 40px;
      @media (max-width: 1440px) {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  
  @media (max-width: 1440px) {
    margin: 0 16px 24px 16px;
  }
`;
