import React, { useEffect, useState } from 'react';
import { CategoriesSubListSection } from "../atoms";
import { useStore } from "effector-react";
import { $isDataPending, subCategoriesPageMounted } from "../model";
import { Link, withRouter } from "react-router-dom";
// import {PopoverItem} from "../../categories/molecules";
import { AntTable, Container } from "../../../ui/atoms";
import { PopoverItem } from "../moleculas";

const CategoriesSubListView = (props) => {
    const {
        match
    } = props;
    const isDataPending = useStore($isDataPending);
    const [data, setData] = useState([]);

    const [start, setStart] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);

    function changePagination(page) {
        setPage(page);
        setStart(((page - 1) * pageSize) - (page === 1 ? 0 : 1))
    }
    function changeShowSize(current, size) {
        setPageSize(size)
    }



    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: id => (<Link to={`/categories/${match.params.id}/list/${id}`}>{id}</Link>)
        },
        {
            title: 'Название',
            dataIndex: 'name',
            render: props => (<Link to={`/categories/${match.params.id}/list/${props.id}`}>{props.name}</Link>)
        },
        {
            title: '',
            dataIndex: 'popover',
            key: 'popover',
            width: 70,
            render: id => (<PopoverItem updateList={updateList} id={id} />)
        }
    ];

    useEffect(() => {
        let data = [];
        if (isDataPending && isDataPending) {
            for (let i = 0; i < isDataPending.length; i++) {
                data.push({
                    id: isDataPending[i].id,
                    key: isDataPending[i].id,
                    popover: isDataPending[i].id,
                    name: { name: isDataPending[i].name, id: isDataPending[i].id },
                    description: { name: isDataPending[i].description, id: isDataPending[i].id }
                })
            }
        }
        setData(data);
        // console.log(data)
    }, [isDataPending]);

    useEffect(() => {
        const params = {
            length: pageSize,
            start: start
        };
        subCategoriesPageMounted(match.params.id, params);
    }, [match.params.id, pageSize, start]);

    const updateList = () => {
        const params = {
            length: pageSize,
            start: start
        };
        subCategoriesPageMounted(match.params.id, params);
    }

    return (
        <Container>
            <AntTable
                columns={columns}
                dataSource={data}
                pagination={
                    {
                        pageSize: pageSize,
                        pageSizeOptions: ['20', '30', '40', '50', '60', '70', '80', '90', '100'],
                        total: isDataPending.recordsTotal,
                        onChange: changePagination,
                        onShowSizeChange: changeShowSize,
                        current: page,
                        showSizeChanger: true
                    }
                }
            />

        </Container>
    )
};
export const CategoriesSubList = withRouter(CategoriesSubListView);
