import React, {Fragment} from 'react'
import {BodyHead} from "../../../components/body-head";
import {LogsFilter} from "../organisms";
import {LogsContent} from "../organisms";

export const LogsPage = () => {

    return (
          <Fragment>
              <BodyHead title='Журнал действий'>
                  <LogsFilter />
              </BodyHead>
                 <LogsContent />
          </Fragment>
    )
}
