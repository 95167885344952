import React, {useState} from 'react';
import {MoreHorizontalSvg, TrashSvg} from "../../../media";
import {removeUserForOrg} from "../api";
import Popover from "react-tiny-popover";
import {PopConfirmContent} from "../../../components/pop-confirm/atoms";
import {deleteConfirm} from "../../../ui/molecules";
import {notification} from "antd";


export const DeleteUser = (props) => {
    const {
        id,
        updateList
    } = props;

    //status
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const deleteUser = () => {
        removeUserForOrg(id)
              .then(response => {
                  if (response.status === 200) {
                      updateList();
                      notification['success']({
                          message: 'Успешно!',
                          description: 'Спициалист удален!'
                      });
                  }
              })
              .catch(error => {
                  console.error(error.response)
                  notification['error']({
                      message: 'Ошибка!',
                      description: 'Ошибка при удалении, попробуйте позже'
                  });
              })
    };

    return (
          <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                position={'bottom'} // preferred position
                content={(
                      <PopConfirmContent>
                          <span className='delete' onClick={() => deleteConfirm(deleteUser)}>
                              <TrashSvg/>
                              Удалить
                          </span>
                      </PopConfirmContent>
                )}
          >
              <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  <MoreHorizontalSvg/>
              </div>
          </Popover>
          // <Button onClick={changeButton} loading={loading} type='error' icon={<TrashSvg/>} noChildren/>
    )
};
