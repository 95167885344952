export const LogsConstant = {
    ORGANIZATION: 'organization',
    SECTOR: 'sector',
    CATEGORY: 'category',
    USER: 'user'
}

export const fakeLogsDate = [
    {
        type: LogsConstant.SECTOR,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Участки',
        action: 'Удален участок - Камчик-Андижон',
        user: 'Анатолий Токов',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.SECTOR,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Участки',
        action: 'Удален участок - Камчик-Андижон',
        user: 'Степан Токов',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.USER,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Пользователи',
        action: 'Парел изменен',
        user: 'Степан Волков',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.USER,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Пользователи',
        action: 'номер изменен',
        user: 'Зафар Ёдгоров',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.ORGANIZATION,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Организации',
        action: 'Парел изменен',
        user: 'Степан Волков',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.ORGANIZATION,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Организации',
        action: 'номер изменен',
        user: 'Зафар Ёдгоров',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.CATEGORY,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Категории',
        action: 'Категория изменен',
        user: 'Степан Волков',
        date: new Date().toLocaleDateString()
    },
    {
        type: LogsConstant.CATEGORY,
        key: new Date().getTime(),
        id: new Date().getTime(),
        section: 'Категории',
        action: 'Подкатегория изменен',
        user: 'Зафар Ёдгоров',
        date: new Date().toLocaleDateString()
    }
]

