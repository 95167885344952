export const USER_PROFILE = {
    EDIT: 'EDIT',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_ORGANIZATION: 'CHANGE_ORGANIZATION',
    CHANGE_POSITION: 'CHANGE_POSITION',
    LOGOUT: 'LOGOUT'
};

export const USER_ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    MANAGER: 'MANAGER',
    ADMIN: 'ADMIN',
    EMPLOYEE: 'EMPLOYEE',
    USER: 'USER',
    BOSS: 'BOSS'
};