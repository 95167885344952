import {httpDelete, httpGet, httpPost, httpPut} from "../../api";

function encode(val) {
    return encodeURIComponent(val).
    replace(/%5B/gi, '[').
    replace(/%5D/gi, ']');
}



//user list
export const getUsersApi = (params) => httpGet({
    // url: encode(`/user/list/${params ? `?${params.length ? ('length=' + params.length) : ''}${params.start ? ('&start=' + params.start) : ''}${params.searchable ? ('&columns[0].searchable=' + params.searchable) : ''}${params.data ? ('&columns[0].data=' + params.data) : ''}${params.search ? ('&search.value=' + params.search) : ''}` : null}`)
    url: '/user/list', params
});


//user add
export const UploadFile = (data) => httpPost({
    headers: { "Content-Type": "multipart/form-data" }, url: '/resource', data
});

export const CreateUserApi = (data) => httpPut({
    url: '/user/', data
});

export const EditUserApi = (data) => httpPost({
    url: '/user/', data
});


export const getPositionsList = () => httpGet({
    url: '/position/plainlist'
});

export const getUserInfo = (login) => httpGet({
    url: `/user/${login}`
});

//edit
export const getEditUserInfo = (id) => httpGet({
    url: `/user/${id}`
});

//delete user
export const deleteUser = (id) => httpDelete({
    url: `/user/${id}`
});

//blocked user
export const blockedUser = (data) => httpPost({
    url: '/user/', data
});
