import React, {useEffect, useState} from 'react';
import {DatePickerSystem, InputBlock} from "../atoms";
import {withTheme} from "styled-components";
import InputMask from 'react-input-mask';
import {DebounceInput} from 'react-debounce-input';
import {Input, Form} from "antd";
import {useHistory} from "react-router-dom";


const InputSystemView = (props) => {
    const {
        label,
        placeholder,
        type,
        disabled,
        status,
        icon,
        change,
        value,
        noUpperCase,
        max,
        searchQuery,
        minLength,
        defaultValue,
    } = props;

    const {push} = useHistory();

    const [statusError, setErrorStatus] = useState(false);

    const coordinate = (e) => {
        let data = {
            latitude: e.split(',')[0].split('lat: ')[1],
            longitude: e.split(',')[1].split(' lon: ')[1]
        };
        change(data);
    };

    function toUpperCase(str) {
        if (!str) return str;

        return str[0].toUpperCase() + str.slice(1);
    }

    function filterCoordinate(data) {
        let lat = '';
        let long = '';
        if (data) {
            if (data.latitude.toString().length < 7) {
                if (data.latitude.toString().length < 4) {
                    lat = data.latitude;
                    for (let i = 0; i < (3 - data.latitude.toString().length); i++) {
                        lat = lat + '_';
                    }
                    lat = lat + '.';
                    for (let i = 0; i < 3; i++) {
                        lat = lat + '_'
                    }
                } else {
                    lat = data.latitude;
                    for (let i = 0; i < (7 - data.latitude.toString().length); i++) {
                        lat = lat + '_'
                    }
                }
            } else {
                lat = data.latitude;
            }
            if (data.longitude.toString().length < 7) {
                if (data.longitude.toString().length < 4) {
                    long = data.longitude;
                    for (let i = 0; i < (3 - data.longitude.toString().length); i++) {
                        long = long + '_';
                    }
                    long = long + '.';
                    for (let i = 0; i < (7 - data.longitude.toString().length); i++) {
                        long = long + '_'
                    }
                } else {
                    long = data.longitude;
                    for (let i = 0; i < (7 - data.longitude.toString().length); i++) {
                        long = long + '_'
                    }
                }
            } else {
                long = data.longitude;
            }
            return `lat: ${lat}, long: ${long}`
        } else {
            return undefined
        }
    }

    function filterMax(e) {
        // console.log(e);
        if (max) {
            if (Number(e) <= Number(max)) {
                change(Number(e))
            } else {
                setErrorStatus(true);
                setTimeout(() => {
                    setErrorStatus(false);
                }, 2000)
            }
        }
    }

    const searchChange = (e) => {
        const _value = e.target.value;
        change(_value);

        if (searchQuery) {
            if (_value && _value.length > 0) {
                push({
                    pathname: searchQuery.pathname,
                    search: `search=${_value}`
                })
            } else {
                push(searchQuery.pathname)
            }
        }
    };

    // useEffect(() => {
    //     if (value === 0) {
    //         change(1);
    //     }
    // }, [value]);

    return (
        <InputBlock icon={icon} status={status ? status : 'default'} theme={props.theme.main}>
            {
                label &&
                <label>{label}</label>
            }
            <div>
                {
                    type === 'coordinate' ?
                        <InputMask
                            value={filterCoordinate(value)}
                            mask="l\at: 999.999, lon: 999.999"
                            maskChar="_"
                            placeholder={placeholder}
                            disabled={disabled ? disabled : false}
                            onChange={(e) => coordinate(e.target.value)}
                        /> :
                        type === 'phone' ?
                            <InputMask
                                value={value}
                                mask="+\9\98 (99) 999 99 99"
                                maskChar="-"
                                placeholder={placeholder}
                                disabled={disabled ? disabled : false}
                                onChange={(e) => change(e.target.value)}
                            /> :
                            type === 'search' ?
                                <DebounceInput
                                    disabled={disabled ? disabled : false}
                                    value={value}
                                    placeholder={placeholder}
                                    minLength={3}
                                    debounceTimeout={300}
                                    onChange={searchChange}/> :
                                type === 'sector' ?
                                    <Form.Item
                                        style={{margin: 0}}
                                        validateStatus={statusError ? 'error' : false}
                                        help={statusError ? `Превышен значение ${max}` : ''}
                                    >
                                        <Input
                                            value={value}
                                            onChange={(e) => max ? filterMax(e.target.value) : change(e.target.value)}
                                            placeholder={placeholder}
                                            disabled={disabled ? disabled : false}
                                            type='number'
                                        />
                                    </Form.Item> :
                                    type === 'password' ?
                                        <Input.Password
                                            placeholder={placeholder}
                                            value={value}
                                            onChange={(e) => change(e.target.value)}
                                            disabled={disabled ? disabled : false}
                                        /> : type === 'permissionRange' ?
                                        <DebounceInput

                                            disabled={disabled ? disabled : false}
                                            value={value}
                                            type='number'
                                            placeholder={placeholder}
                                            onChange={(e) => change(e.target.value)}/> :
                                        type === 'sector' ?
                                            <Form.Item
                                                style={{margin: 0}}
                                                validateStatus={statusError ? 'error' : false}
                                                help={statusError ? `Превышен значение ${max}` : ''}
                                            >
                                                <Input
                                                    value={value}
                                                    onChange={(e) => max ? filterMax(e.target.value) : change(e.target.value)}
                                                    placeholder={placeholder}
                                                    disabled={disabled ? disabled : false}
                                                    type='number'
                                                />
                                            </Form.Item> :
                                            type === 'password' ?
                                                <Input.Password
                                                    placeholder={placeholder}
                                                    value={value}
                                                    onChange={(e) => change(e.target.value)}
                                                    disabled={disabled ? disabled : false}
                                                />
                                                :
                                                <input
                                                    value={value}
                                                    onChange={(event) => {
                                                        event.persist();
                                                        change(
                                                            noUpperCase ?
                                                                event.target.value :
                                                                event.target.value.length > 0 ?
                                                                    toUpperCase(event.target.value.trimLeft()) :
                                                                    event.target.value
                                                        )
                                                    }
                                                    }
                                                    placeholder={placeholder}
                                                    disabled={disabled ? disabled : false}
                                                    type={type ? type : 'text'}
                                                />
                }
            </div>
        </InputBlock>
    )
};

export const InputSystem = withTheme(InputSystemView);
