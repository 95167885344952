import React, {Fragment, useEffect, useState} from 'react'
import {InputSystem, SelectSys} from "../../../ui/molecules";
import {$isDataPending, headerMount, positionListMount} from "../model";
import {useStore} from "effector-react";
import {Form, notification} from "antd";
import {AvatarUpload} from "../../avatar-upload";
import {$isDataPending as dashboardData} from '../../../features/dashboard/model'
import {ModalViewTitle} from "../atoms";
import {Button, ModalFooterWrapper} from "../../../ui/atoms";
import {updateMe, updatePassword} from "../api";
import {$userModel, signedUserInfoMount} from "../../../models/user-model";
import {useUserProfileEdit} from "../../../hooks/user/user-profile-edit";


export const EditProfile = (props) => {
    const {setStatusModal} = props
    const {$signedUserInfo} = useStore($userModel)
    const {updateUserInfo} = useUserProfileEdit()
    const [name, setName] = useState('')
    const [sname, setSname] = useState('')
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('')
    const [fileId, setFileId] = useState(null)

    const {data} = $signedUserInfo


    useEffect(() => {
        setName(data.name)
        setSname(data.sname)
        setUsername(data.username)
        setPhone(data.phone)
        setFileId(data.image)
    }, [data])

    const handleSave = () => {
        const $data = {
            ...data,
            image: fileId,
            name: name,
            sname: sname,
            phone: phone,
            username: username
        }
        updateUserInfo($data)
    }

    return (
        <Fragment>
            <Form.Item style={{textAlign: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <AvatarUpload file={fileId} setFileId={setFileId} imageSize='96px'/>
                </div>

            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={sname}
                    change={setSname}
                    type='text'
                    placeholder='Введите Фамилия'
                    label='Фамилия'
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={name}
                    change={setName}
                    type='text'
                    placeholder='Введите Имя'
                    label='Имя'
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={phone}
                    change={setPhone}
                    type='text'
                    placeholder='Номер телефона'
                    label='Введите номер телефона'
                />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={
                        !name
                        || !sname
                        || !username
                        || !phone
                        || !fileId
                    }
                >
                    Сохранить
                </Button>
            </ModalFooterWrapper>
        </Fragment>
    )
}

export const ChangePassword = (props) => {
    const {setStatusModal} = props
    const {changeUserPassword} = useUserProfileEdit()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState(undefined)


    const changePassword = () => {
        if (confirmPassword !== newPassword) {
            setErrors('Пароли не совпадают')
        } else {
            const data = {
                newPassword: newPassword,
                password: oldPassword
            }
            changeUserPassword(data)
        }
    }

    return (
        <div>
            <ModalViewTitle>
                Сменить пароль
            </ModalViewTitle>
            <Form.Item>
                <InputSystem
                    value={oldPassword}
                    change={setOldPassword}
                    type='password'
                    label='Старый пароль'
                    placeholder='Введите старый пароль'
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={newPassword}
                    change={setNewPassword}
                    type='password'
                    placeholder='Введите новый пароль'
                    label='Новый пароль'
                />
            </Form.Item>
            <Form.Item>
                <InputSystem
                    value={confirmPassword}
                    change={setConfirmPassword}
                    type='password'
                    placeholder='Подтвердите пароль'
                    label='подтвердить пароль'
                />
                {errors && <span>{errors}</span>}
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    Отменить
                </Button>
                <Button
                    onClick={changePassword}
                    htmlType="submit"
                    disabled={
                        !oldPassword
                        || !newPassword
                        || !confirmPassword
                    }
                >
                    Сохранить
                </Button>
            </ModalFooterWrapper>
        </div>
    )
}

export const ChangeOrganization = ({setStatusModal}) => {
    const isDataPending = useStore(dashboardData)
    const {updateUserInfo} = useUserProfileEdit()
    const {$signedUserInfo} = useStore($userModel)
    const [organizationId, setOrganizationId] = useState({value: undefined, label: undefined, key: undefined})
    const {data} = $signedUserInfo

    const changeOrganization = () => {
        const $data = {
            ...data,
            organizations: [{
                id: organizationId.value,
                name: organizationId.label
            }]
        }
        updateUserInfo($data)
    }


    useEffect(() => {
        if (isDataPending.$organizationList.data && isDataPending.$organizationList.data.data.length > 0) {
            setOrganizationId({
                value: Number(data.organizations[0].id),
                label: data.organizations[0].name,
                key: Number(data.organizations[0].id),
            })
        }

    }, [data, isDataPending.$organizationList.data])


    return (
        <Form>
            <ModalViewTitle>
                Изменить организацию
            </ModalViewTitle>
            <Form.Item>
                <SelectSys
                    loading={isDataPending.$organizationList.loading}
                    value={organizationId}
                    change={setOrganizationId}
                    options={isDataPending.$organizationList.filtered}
                    placeholder='Организации'
                    labelInValue={true}
                />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    Отменить
                </Button>
                <Button
                    disabled={!organizationId}
                    onClick={changeOrganization}
                >
                    Далее
                </Button>
            </ModalFooterWrapper>
        </Form>
    )
}

export const ChangePosition = ({setStatusModal}) => {
    const {updateUserInfo} = useUserProfileEdit()
    const {$positionList} = useStore($isDataPending)
    const {$signedUserInfo} = useStore($userModel)
    const [positionId, setPositionId] = useState({value: undefined, label: undefined, key: undefined})

    const {data} = $signedUserInfo

    const changePosition = () => {
        const $data = {
            ...data,
            position: {
                id: positionId.value,
                title: positionId.label
            }
        }

        updateUserInfo($data)
    }

    useEffect(() => {
        setPositionId({
            value: data.position.id,
            label: data.position.title,
            key: data.position.id
        })
    }, [data])

    useEffect(() => {
        if ($positionList.data.length === 0) {
            positionListMount()
        }
    }, [$positionList.data])

    return (
        <Form>
            <ModalViewTitle>
                Изменить должность
            </ModalViewTitle>
            <Form.Item>
                <SelectSys
                    loading={$positionList.loading}
                    value={positionId}
                    change={setPositionId}
                    options={$positionList.filtered}
                    placeholder='Должности'
                    labelInValue={true}
                />
            </Form.Item>
            <ModalFooterWrapper>
                <Button
                    type='link'
                    onClick={() => setStatusModal(false)}
                >
                    Отменить
                </Button>
                <Button
                    disabled={!positionId}
                    onClick={changePosition}
                >
                    Далее
                </Button>
            </ModalFooterWrapper>
        </Form>
    )
}

