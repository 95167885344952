import {combine, createStore} from 'effector';
import {fetSectors} from './effects';
import Cookies from "js-cookie";
import {sectorPageSizeChange} from './events'
import {numberFormat} from '../../../helpers'


let storage_name = 'settings';
let storage = Cookies.get(storage_name);

export const $sectors = createStore({loading: false, data: [], error: false})
    .on(fetSectors.pending, (params, pending) => {
        return {
            ...params,
            loading: pending
        };
    })
    .on(fetSectors.finally, (params, response) => {
        if (response.error) {
            return {
                ...params,
                data: [],
                error: response.error.response
            };
        } else {
            return {
                ...params,
                data: {
                    ...response.result.data,
                    data: response.result.data.data.map(item => ({
                        key: item.id,
                        id: item.id,
                        name: {name: item.name, id: item.id},
                        length: {name: numberFormat(item.length), id: item.id},
                        qwerty: {name: item.origin && item.origin.name ? item.origin.name : 'Не указан', id: item.id},
                        startPoint: {name: item.startPoint, id:item.id} ,
                        countdownPoint: {name: item.countdownPoint, id:item.id},
                        popover: item.id,
                    }))
                },
                error: false
            };
        }
    });

let storageData = storage !== undefined && JSON.parse(storage)
let sectorSettings = storageData.sector !== undefined ? {...storageData} : {
    ...storageData,
    sector: {}
}

export const $sectorSettings = createStore(storage !== undefined ? {
    ...storageData,
    ...sectorSettings
} : {sector: {}})
      .on(sectorPageSizeChange, (params, payload) => {
          const {sector} = params
          const newSettings = {...sector, ...payload}
          return {
              ...params,
              sector: newSettings
          }
      })

$sectorSettings.watch((state) => {
    Cookies.set(storage_name, JSON.stringify(state))
})

export const $isDataPending = combine({
    $sectors,
    $sectorSettings
});
