import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {SignIn} from "./features/sign-in";
import {ThemeProvider} from "styled-components";
import {theme} from './theme';
import {HightBlock} from "./ui/templates/templates";
import 'antd/dist/antd.css';
import './App.scss';
import {ConfigProvider} from "antd";
import {LastLocationProvider} from 'react-router-last-location';
import ruRU from 'antd/es/locale/ru_RU'


export const App = () => {


    return (
        <ThemeProvider theme={theme}>
            <ConfigProvider locale={ruRU}>
                <Router basename='/'>
                    <LastLocationProvider>
                        <Switch>
                            <Route path="/sign-in" component={SignIn}/>
                            <Route component={HightBlock}/>
                        </Switch>
                    </LastLocationProvider>
                </Router>
            </ConfigProvider>
        </ThemeProvider>


    );
};
