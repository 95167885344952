import styled from 'styled-components';

export const CategoriesSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CategoriesListBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 0;
  @media (max-width: 1440px) {
    margin: 0 16px 0;
  }
`;
