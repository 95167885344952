import {combine, createStore} from 'effector';
import {fetCategories} from './effects';
import Cookies from 'js-cookie'
import {categoryPageSizeChange} from "./events";

let storage_name = 'settings';
let storage = Cookies.get(storage_name);

export const $categories = createStore([])
      .on(fetCategories.done, (_, response) => response.result.data);


const storageData = storage !== undefined && JSON.parse(storage)
const categorySettings = storageData.category !== undefined ? {...storageData} : {...storageData, category: {}}

export const $categorySettings = createStore(storage !== undefined ? {...storageData, ...categorySettings} : {category: {}})
      .on(categoryPageSizeChange, (params, payload) => {
          const {category} = params
          const newSettings = {...category, ...payload}
          return {
              ...params,
              category: newSettings
          }
      })

$categorySettings.watch((state) => {
    Cookies.set(storage_name, state)
})

export const $isDataPending = combine({
    $categories,
    $categorySettings
});
