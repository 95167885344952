import React from 'react';
import {
    TariffItemWrapper,
    TariffRange,
    TariffItemTitle
} from "../atoms";
import {withTheme} from "styled-components";


const TariffItemView = (props) => {
    const {value, changeValue, title, step, customMark, max} = props

    const marks = {
        0: 2,
        12: 12,
        24: 24,
        36: 36,
        48: 48,
        60: 60,
        72: 72,
        84: 84
    };

    return (
          <TariffItemWrapper>
              <TariffItemTitle>
                  {title}
              </TariffItemTitle>
              <TariffRange
                    onChange={e => changeValue(e)}
                    marks={customMark ? customMark : marks}
                    step={step}
                    defaultValue={value}
                    max={max}
              />
          </TariffItemWrapper>
    )
}

export const TariffRangeItem = withTheme(TariffItemView)
