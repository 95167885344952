import React from 'react';
import {SubCategoriesAddSection} from "../atoms";
import {BodyHead} from "../../../components/body-head";
import {withRouter} from "react-router-dom";
import {AddSubCategory} from "../organisms";

const SubCategoriesAddView = (props) => {
    const {
        match
    } = props;


    return (
        <SubCategoriesAddSection>
            <BodyHead back={`categories/${match.params.id}`} title='Добавить подкатегорию' />
            <AddSubCategory/>
        </SubCategoriesAddSection>
    )
};

export const SubCategoriesAdd = withRouter(SubCategoriesAddView);
