import styled from 'styled-components';

export const MoreButton = styled.div`
  display: flex;
  height: ${props => props.active ? 'initial' : '100$'};
  justify-content: center;
  align-items: center;
  width: 50px;
  position: ${props => props.active ? 'absolute' : 'static'};
  top: 34px;
  right: 20px;
 
  &:hover{
    cursor: pointer;
  }
`;
