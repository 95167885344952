import styled from 'styled-components';

export const SubCategoriesBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 0;
`;
export const BodyHeadButtons = styled.div`
  display: flex;
  align-items: center;
  button{
    &:nth-child(1){
    margin-right: 10px;
    }
  }
`;
export const CategoriesSubListSection = styled.div`
  display: flex;
  margin: 0 24px 0;
  flex-direction: column;
  width: 100%;
`;
